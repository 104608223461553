import { useAppDispatch, useAppSelector } from 'hooks'
import { useEffect } from 'react'

import { updateLastSyncOn } from 'slices/db'
import { removeSyncedItems } from 'slices/queued'

import followupService from 'services/followup-service'
import meetingService from 'services/meeting-service'
import registrationService from 'services/registration-service'
import saleService from 'services/sales-service'
import stocksService from 'services/stocks-service'
import requisitionService from 'services/save-requisition'

type QueuedItems = keyof QueuedState

type TrackSyncedItems = {
	[item in QueuedItems]: Array<any>
}

export const useSyncWithServer = () => {
	const dispatch = useAppDispatch()
	const auth = useAppSelector(state => state.auth)
	const queued = useAppSelector(state => state.queued)

	const connected = useAppSelector(state => state.db.connected)


	useEffect(() => {
		const syncPendingItems = async () => {
			let trackSyncedItems = Object.keys(queued).reduce(
				(agg, curr) => ({ ...agg, [curr]: [] }),
				{} as TrackSyncedItems
			)

			for (const meeting of Object.values(queued['meetings'])) {
				const res = await meetingService.createMeeting(meeting)
				if (res.data) {
					trackSyncedItems = {
						...trackSyncedItems,
						meetings: [...trackSyncedItems.meetings, meeting]
					}
				}
			}
			// console.log(trackSyncedItems,'trackSyncedItems')


			for (const sale of Object.values(queued['sales'])) {
				const res = await saleService.createSale(sale)
				if (res.data) {
					trackSyncedItems = {
						...trackSyncedItems,
						sales: [...trackSyncedItems.sales, sale]
					}
				}
			}

			for (const followup of Object.values(queued['followups'])) {
				const res = await followupService.createFollowup(followup)
				if (res.data) {
					trackSyncedItems = {
						...trackSyncedItems,
						followups: [...trackSyncedItems.followups, followup]
					}
				}
			}

			for (const mwra of Object.values(queued['mwras'])) {
				const res = await registrationService.createMWRA(mwra)
				if (res.data) {
					trackSyncedItems = {
						...trackSyncedItems,
						mwras: [...trackSyncedItems.mwras, mwra]
					}
				}
			}
			for (const distribution of Object.values(queued['distributions'] ?? {})) {
				const res = await stocksService.createDistribution(distribution)
				if (res) {
					trackSyncedItems = {
						...trackSyncedItems,
						distributions: [...trackSyncedItems.distributions, distribution]
					}
				}
			}
			for (const requisitions of Object.values(queued['requisitions'])) {


				const res = await requisitionService.saveRequisition(requisitions)
				if (res) {
					
					trackSyncedItems = {
						...trackSyncedItems,
						requisitions: [...trackSyncedItems.requisitions, requisitions]
					}
					console.log(trackSyncedItems,' trackSyncedItems trackSyncedItems requisitions')
				}
			}
			for (const requisitionsRequestSeniorCrp of Object.values(queued['requisitionsRequestSeniorCrp'])) {
		


				const res = await requisitionService.saveRequisitionRequestApproval(requisitionsRequestSeniorCrp)
				if (res) {
					
					trackSyncedItems = {
						...trackSyncedItems,
						requisitionsRequestSeniorCrp: [...trackSyncedItems.requisitionsRequestSeniorCrp, requisitionsRequestSeniorCrp]
					}
					console.log(trackSyncedItems,' trackSyncedItems trackSyncedItems requisitions')
				}
			}


			for (const recievedRequisitions of Object.values(queued['recievedRequisitions'])) {
				const res = await requisitionService.saveRequisitionRecievedNote(recievedRequisitions)
				if (res) {
					trackSyncedItems = {
						...trackSyncedItems,
						recievedRequisitions: [...trackSyncedItems.recievedRequisitions, recievedRequisitions]
					}
				}
			}

			

			let updateLastSyncedOn = false
			if (
				trackSyncedItems.meetings?.length > 0 ||
				trackSyncedItems.sales?.length > 0 ||
				trackSyncedItems.followups?.length > 0 ||
				trackSyncedItems.distributions?.length > 0 ||
				trackSyncedItems.mwras?.length > 0 ||
				trackSyncedItems.recievedRequisitions?.length > 0 ||
				trackSyncedItems.requisitions?.length > 0 ||
				trackSyncedItems.requisitionsRequestSeniorCrp?.length > 0

				

			) {
				updateLastSyncedOn = true
				dispatch(removeSyncedItems({ syncedItems: trackSyncedItems }))
			}

			if (updateLastSyncedOn) {
				dispatch(updateLastSyncOn({ time: new Date().getTime() }))
			}
		}

		// sync pending items
		if (auth?.jwt && connected) {
			syncPendingItems()
		}
	}, [auth, queued, connected])
}
