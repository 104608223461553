import { InputFieldError } from 'components/error/field-error'
import { InputHTMLAttributes } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import clsx from 'utils/clsx'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText?: string
	name: string
	index?: string
	placeholder?: string
	register?: UseFormRegister<any>
	error?: FieldErrors<FieldValues>
	renderLabel?: boolean
	type?: string
	rounded?: boolean
	className?: string
}

export const Checkbox = ({
	labelText,
	name,
	index,
	placeholder,
	value,
	register,
	onChange,
	error,
	renderLabel = true,
	rounded = false,
	className,
	...props
}: CheckboxProps) => {
	const errorText = error?.[name]?.message as string

	return (
		<div className="space-x-3 flex items-center">
			{renderLabel && (
				<label htmlFor={name} className="mt-4 flex text-md font-medium text-black justify-start">
					{labelText}
				</label>
			)}
			<input
				{...props}
				{...(register?.(name) ?? {})}
				id={index}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				className={clsx(
					'block w-6 appearance-none py-3 rounded-full text-gray-600 outline-none placeholder-gray-400 focus:outline-none border-gray-500 sm:text-sm',
					className,
					{
						'rounded-lg': rounded
					}
				)}
			/>
			<InputFieldError message={errorText} className="my-2" />
		</div>
	)
}
