import arrowDown from 'assets/pngs/arrowdown.png'
import check from 'assets/pngs/check.png'
import editProfile from 'assets/pngs/edit-profile.png'
import sales from 'assets/pngs/sales.png'
import babyBoy from 'assets/svgs/baby-boy.svg'
import babyGirl from 'assets/svgs/baby-girl.svg'
import basket from 'assets/svgs/basket.svg'
import checkmark from 'assets/svgs/checkmark.svg'
import circle from 'assets/svgs/circle.svg'
import condom from 'assets/svgs/condom.svg'
import cross from 'assets/svgs/cross.svg'
import deceased from 'assets/svgs/deceased.svg'
import emptyBag from 'assets/svgs/empty-bag.svg'
import fillFormMobile from 'assets/svgs/fill-form-mobile.svg'
import fingerprintIcon from 'assets/svgs/fingerprint.svg'
import formPlus from 'assets/svgs/form-plus.svg'
import graduated from 'assets/svgs/graduated.svg'
import highIncome from 'assets/svgs/high-income.svg'
import injection from 'assets/svgs/injection.svg'
import iucd from 'assets/svgs/iucd.svg'
import lactating from 'assets/svgs/lactating.svg'
import lowIncome from 'assets/svgs/low-income.svg'
import man from 'assets/svgs/man.svg'
import midIncome from 'assets/svgs/mid-income.svg'
import ocp from 'assets/svgs/ocp.svg'
import orderHistory from 'assets/svgs/order-history.svg'
import plwPlus from 'assets/svgs/plw-plus.svg'
import pregnant from 'assets/svgs/pregnant.svg'
import qrCode from 'assets/svgs/qr-code.svg'
import shrug from 'assets/svgs/shrug.svg'
import sterilization from 'assets/svgs/sterilization.svg'
import stock from 'assets/svgs/stock.svg'
import visitPlw from 'assets/svgs/visit-plw.svg'
import woman from 'assets/svgs/woman.svg'
import women from 'assets/svgs/women.svg'

export const FingerprintIcon = fingerprintIcon
export const VisitPlwIcon = visitPlw
export const FormPlusIcon = formPlus
export const PlwPlusIcon = plwPlus
export const FillFormMobile = fillFormMobile
export const QrCodeIcon = qrCode
export const WomenIcon = women
export const BasketIcon = basket
export const OrderHistoryIcon = orderHistory
export const EmptyBagIcon = emptyBag
export const IUCDIcon = iucd
export const InjectionIcon = injection
export const GraduatedWoman = graduated
export const ManIcon = man
export const WomanIcon = woman
export const LactatingIcon = lactating
export const MidIncomeIcom = midIncome
export const HighIncomeIcom = highIncome
export const LowIncomeIcom = lowIncome
export const ShrugIcon = shrug
export const OCPIcon = ocp
export const PregnantIcon = pregnant
export const SterilizationIcon = sterilization
export const BabyboyIcon = babyBoy
export const BabygirlIcon = babyGirl
export const CrossIcon = cross
export const CheckmarkIcon = checkmark
export const CondomIcon = condom
export const CheckIcon = check
export const ArrowDownIcon = arrowDown
export const EditProfileIcon = editProfile
export const SalesIcon = sales
export const StockIcon = stock
export const DeceasedIcon = deceased
export const CircleIcon = circle
