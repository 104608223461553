import clsx from 'clsx'
import { InputFieldError } from 'components/error/field-error'
import { Language } from 'constants/constants'

import { FieldError, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import { getAppLang } from 'utils/language'

interface SelectProps
	extends React.DetailedHTMLProps<
		React.SelectHTMLAttributes<HTMLSelectElement>,
		HTMLSelectElement
	> {
	children: React.ReactNode
	label?: string
	name: string
	register?: UseFormRegister<any>
	error?: FieldErrors<FieldValues>
	controllerError?: FieldError | undefined
}

export const Select: React.FC<SelectProps> = ({
	label,
	children,
	register,
	onChange,
	value,
	error,
	controllerError,
	name,
	className,
	...restProp
}) => {
	const isLanguageEnglish = getAppLang() === Language.EN
	const errorText = (error?.[name]?.message as string) ?? (controllerError?.message as string)
	return (
		<div>
			<label htmlFor="location" className="mt-4 block text-sm font-medium leading-6 text-gray-900">
				{label}
			</label>
			<select
				{...restProp}
				{...(register?.(name) ?? {})}
				value={value}
				onChange={onChange}
				className={clsx(
					'mt-1 block sm:w-90 rounded-md border-0 py-3 bg-white text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 sm:text-sm sm:leading-6 outline-none appearance-none',
					className
				)}>
				{children}
			</select>
			<span
				className={clsx(
					'absolute inset-y-0 left-2 flex items-center pr-2 pointer-events-none ',
					isLanguageEnglish ? 'right-2' : 'left-2'
				)}></span>
			<InputFieldError message={errorText} className="my-2" />
		</div>
	)
}
