import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormResetField,
	UseFormSetValue,
	useFieldArray,
	useForm,
	useWatch
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { EmptyBagIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { CounterInput } from 'components/inputs/counter'
import { useAppDispatch, useAppSelector } from 'hooks'
import { addDistribution } from 'slices/queued'
import { getLocalizedName, getTKey, t } from 'utils/language'
import { getDirection } from 'utils/string'

const tKey = getTKey('stocks.distributions')

enum FormSteps {
	LIST = 1,
	DETAILS,
	CART,
	COMPLETE
}

type StateType = {
	step: FormSteps
	displayExitModal: boolean
	selectedProduct?: ID
}

export const CreateStockEntry = () => {
	const [state, setState] = useState<StateType>({
		step: FormSteps.LIST,
		displayExitModal: false
	})

	const user = useAppSelector(state => state.auth.profile)
	const products = useAppSelector(state => state.db.products)

	const dispatch = useAppDispatch()

	// CRP id
	const { id } = useParams()

	const getPageTitle = () => {
		let title
		switch (state.step) {
			case FormSteps.CART:
				title = t(tKey('title.cart'))
				break
			case FormSteps.LIST:
				title = t(tKey('title.productCatalog'))
				break
			case FormSteps.COMPLETE:
				title = ''
				break
			default:
				title = ''
				break
		}

		return title
	}

	const pageTitle = getPageTitle()

	const schema = yup.object({
		distributionItems: yup.array().required(t('errors.required', { field: 'Products' })),
		total: yup.number().required(t('errors.required', { field: 'Total' })),
		seniorCrpId: yup.number().required(t('errors.required', { field: 'Senior CRP ID' })),
		crpId: yup.number().required(t('errors.required', { field: 'CRP ID' })),
		distributionDate: yup.string().required(t('errors.required', { field: 'Date' }))
	})

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
		setValue,
		control
	} = useForm<Distribution>({
		resolver: yupResolver(schema as any),
		context: { step: state.step },
		defaultValues: {
			seniorCrpId: user.id,
			crpId: Number(id),
			total: 0,
			distributionItems: [],
			distributionDate: DateTime.now().toISO()
		},
		mode: 'all'
	})

	const total = useWatch({
		control,
		name: 'total',
		defaultValue: 0
	})

	const productsInCart = useWatch({
		control,
		name: 'distributionItems'
	})

	useEffect(() => {
		const totalBill = productsInCart.reduce((agg, curr) => {
			return agg + products[curr.productId].price * (curr.quantity ?? 0)
		}, 0)
		setValue('total', totalBill)
	}, [productsInCart])

	const productIndex = getValues().distributionItems.findIndex(
		distributionItem => distributionItem.productId === state.selectedProduct
	)

	const renderComponent = cond([
		[
			state.step === FormSteps.LIST,
			() => (
				<ProductList
					errors={errors}
					register={register}
					control={control}
					products={products}
					onProductClicked={productId =>
						setState({ ...state, selectedProduct: productId, step: FormSteps.DETAILS })
					}
				/>
			)
		],
		[
			state.step === FormSteps.DETAILS,
			() => (
				<ProductDetailsForm
					products={products}
					productIndex={productIndex}
					goBack={() => setState({ ...state, step: FormSteps.LIST })}
					errors={errors}
					register={register}
					control={control}
				/>
			)
		],
		[
			state.step === FormSteps.CART,
			() => (
				<CartForm
					products={products}
					onProductClicked={productId =>
						setState({ ...state, selectedProduct: productId, step: FormSteps.DETAILS })
					}
					goBack={() => setState({ ...state, step: FormSteps.LIST })}
					errors={errors}
					register={register}
					control={control}
				/>
			)
		]
	])

	const onSubmit = handleSubmit(data => {
		dispatch(addDistribution(data))
		setState({ ...state, step: FormSteps.COMPLETE })
	})

	const dir = getDirection()

	return (
		<AppLayout showHeader>
			<div dir={dir} className="space-y-4 pb-40">
				<div className="space-y-4">
					<div className="flex items-center">
						<h1 className="text-xl font-semibold">{pageTitle}</h1>
					</div>
					<form onSubmit={onSubmit} className="space-y-2 w-full font-bold">
						<>{renderComponent}</>
						{state.step === FormSteps.CART && productsInCart.length > 0 && (
							<div className="flex flex-col p-8 w-full items-center justify-center border border-gray-200 text-lg font-semibold absolute bg-white bottom-4 left-0 right-0">
								<button
									type="submit"
									className="p-4 max-w-max  flex flex-row justify-between bg-green-500 text-white rounded-full ">
									<CheckIcon className="h-10" />
								</button>
								<p className="mt-2">
									{total} <span>{t(tKey('products.label.rupees'))}</span>
								</p>
							</div>
						)}
					</form>
				</div>

				{state.step < FormSteps.CART && (
					<div className="absolute w-full mx-auto flex flex-row items-center justify-center border border-gray-200 bg-white bottom-4 left-0 p-6 right-0">
						<button
							onClick={() => setState({ ...state, step: FormSteps.CART })}
							className="px-8 py-6 flex flex-row justify-between items-center w-full bg-blue-800 text-white text-lg rounded-lg font-semibold">
							<p>{t(tKey('products.buttons.viewCart'))}</p>
							<p>
								{total} <span>{t(tKey('products.label.rupees'))}</span>
							</p>
						</button>
					</div>
				)}
				{state.step === FormSteps.COMPLETE && (
					<div className="flex flex-col p-8 w-full items-center justify-center space-y-2 absolute text-center left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<div className="flex items-center">
							<h1 className="text-center">{t(tKey('products.label.success'))}</h1>
						</div>
						<button
										/* eslint-disable no-restricted-globals */

							onClick={() => history.back()
							}
											/* eslint-disable no-restricted-globals */

							className="p-4 max-w-max flex flex-row justify-between bg-green-500 text-white rounded-full ">
							<CheckIcon className="h-10" />
						</button>
					</div>
				)}
			</div>
		</AppLayout>
	)
}

interface FormProps {
	register?: UseFormRegister<Distribution>
	errors?: FieldErrors<Distribution>
	control?: Control<Distribution, any>
	setValue?: UseFormSetValue<Distribution>
	resetField?: UseFormResetField<Distribution>
	products: Products
}

const ProductList = ({
	products,
	onProductClicked,
	control
}: FormProps & {
	onProductClicked: (productId: ID) => void
}) => {
	const { fields, append } = useFieldArray({
		name: 'distributionItems',
		control,
		keyName: 'customId'
	})

	return (
		<div className="space-y-4">
			{Object.values(products).map(product => {
				return (
					<div
						key={product.id}
						onClick={() => {
							const isAlreadyAdded =
								fields.findIndex(addedProduct => addedProduct.productId === product.id) !== -1

							if (!isAlreadyAdded) {
								append({
									quantity: 1,
									productId: product.id,
									amount: product.price
								} as DistributionItem)
							}
							onProductClicked(product.id)
						}}
						className={`flex flex-row items-center shadow-sm border border-gray-200 bg-white rounded-lg `}>
						<img className="h-24 w-32 object-contain" src={product.attachments[0].url} />
						<div className="space-y-1 mx-4">
							<p className="font-semibold">{getLocalizedName(product)}</p>
							<p className="text-md font-medium">
								{product.price} {t(tKey('products.label.rupees'))}
							</p>
						</div>
					</div>
				)
			})}
		</div>
	)
}

const ProductDetailsForm = ({
	errors,
	control,
	goBack,
	productIndex,
	products
}: FormProps & {
	productIndex: number
	goBack: () => void
}) => {
	const { fields } = useFieldArray({
		name: 'distributionItems',
		control,
		keyName: 'customId'
	})
	const distributionItem = fields[productIndex]
	const product = products[distributionItem.productId]

	return (
		<div className="space-y-5">
			<div className="flex items-center">
				<h2 className="text-lg pt-2">{t(tKey('products.label.sellQuantity'))}</h2>
			</div>
			<div className="flex flex-col justify-center items-center space-y-2">
				<img
					className="w-56 h-44 object-contain rounded"
					src={product.attachments?.[0].url ?? ''}
				/>
				<h2 className="text-lg">{getLocalizedName(product)}</h2>
				<h2 className="text-lg font-light">
					{product.price} <span>{t(tKey('products.label.rupees'))}</span>
				</h2>
			</div>

			<div>
				<Controller
					control={control}
					name={`distributionItems.${productIndex}.quantity`}
					render={({ field: { onChange, value } }) => (
						<div>
							<CounterInput
								onChange={event => {
									onChange(event.target.valueAsNumber)
								}}
								onNumberChange={number => onChange(number)}
								value={value ?? 0}
								minimumValue={1}
							/>
						</div>
					)}
				/>
				<p className="text-sm text-red-500">
					{errors?.['distributionItems']?.[productIndex]?.['quantity']?.message}
				</p>
			</div>
			<div className="flex flex-col justify-center items-center space-y-2 mt-4">
				<button
					type="button"
					onClick={goBack}
					className="p-4 max-w-max flex flex-row justify-between bg-green-500 text-white rounded-full ">
					<CheckIcon className="h-8 w-8" />
				</button>
				<span className="block text-center"> {t(tKey('products.buttons.addToCart'))}</span>
			</div>
		</div>
	)
}

const CartForm = ({
	errors,
	control,
	goBack,
	onProductClicked,

	products
}: FormProps & {
	onProductClicked: (productId: ID) => void
	goBack: () => void
}) => {
	const { fields, append, remove } = useFieldArray({
		name: 'distributionItems',
		control,
		keyName: 'customId'
	})

	return (
		<div className="space-y-2">
			{fields.length > 0 ? (
				fields.map((saleItem, index) => {
					const productsLeft = (saleItem.quantity ?? 0) - 4
					const product = products[saleItem.productId]

					return (
						<div
							key={index}
							className={`flex flex-row items-center shadow-sm border p-4 border-gray-200 bg-white rounded-lg space-x-4`}>
							<div className="space-y-1 w-full">
								<div className="flex flex-row">
									<div
										className={clsx(
											'flex justify-center border border-gray-200 bg-white rounded-full h-14 w-14 p-2'
										)}>
										<img src={product.attachments?.[0].url} className="h-full object-contain" />
									</div>

									{productsLeft > 0 && (
										<div
											key={'additional'}
											className="border border-gray-200 bg-white -ml-6 aspect-square h-14 rounded-full p-4">
											+{productsLeft}
										</div>
									)}
								</div>
								<p className="font-semibold">
									<span className="mx-1">{saleItem.quantity}</span>
									{getLocalizedName(product)}
								</p>
								<p className="font-semibold">
									{(saleItem.quantity ?? 0) * saleItem.amount} {t(tKey('products.label.rupees'))}
								</p>
							</div>
							<div className="flex flex-col h-full space-y-4">
								<XMarkIcon
									className="text-red-500 w-6 cursor-pointer"
									onClick={event => {
										event.preventDefault()
										remove(index)
									}}
								/>
								<PencilIcon
									className="text-blue-500 w-6 cursor-pointer"
									onClick={event => {
										event.preventDefault()
										onProductClicked(saleItem.productId)
									}}
								/>
							</div>
						</div>
					)
				})
			) : (
				<div className="space-y-2 flex flex-col items-center justify-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<div className="p-6 bg-blue-100 rounded-full">
						<img src={EmptyBagIcon} className="h-1/2 aspect-square" />
					</div>
					<p className="text-lg font-semibold">{t(tKey('products.label.cartEmpty'))}</p>
					<button
						className="px-8 py-4  flex flex-row justify-between bg-blue-800 text-white rounded-lg text-lg font-semibold"
						onClick={goBack}>
						{t('buttons.goBack')}
					</button>
				</div>
			)}
		</div>
	)
}
