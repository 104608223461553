import {
	CheckIcon,
	DocumentTextIcon,
	ExclamationCircleIcon,
	TrashIcon
} from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { useRef, useState } from 'react'

import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { useAppSelector } from 'hooks'
import { apiHost } from 'utils/host'

type State = {
	files: AugmentedFile[]
	batchUploading: boolean
}

type AugmentedFile = {
	fileObject: File
	previewLink: string
	status?: FileStatus
}

enum FileStatus {
	FAILED = 'failed',
	UPLOADED = 'uploaded',
	UPLOADING = 'uploading'
}

export const UploadForm = () => {
	const connected = useAppSelector(state => state.db.connected)
	const auth = useAppSelector(state => state.auth)

	const inputRef = useRef<HTMLInputElement>(null)
	const [state, setState] = useState<State>({
		files: [],
		batchUploading: false
	})

	const handleImagesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files

		if (files) {
			// Filter out files from selection which are
			// already exist in current state
			const selectedFiles = Array.from(files)
				.filter(f1 => !state.files.some(f2 => f1.name === f2.fileObject.name))
				.map(file => ({
					fileObject: file,
					previewLink: URL.createObjectURL(file)
				}))

			// Append new selected files and update state
			setState(prevState => ({ ...prevState, files: [...prevState.files, ...selectedFiles] }))
		}
	}

	const handleRemoveFile = (file: AugmentedFile) => {
		const files = state.files.filter(f => f.fileObject.name !== file.fileObject.name)

		setState(prevState => ({ ...prevState, files }))
	}

	const uploadFiles = () => {
		const files = state.files.map(f => {
			if (f.status === undefined || f.status === FileStatus.FAILED) {
				return {
					...f,
					status: FileStatus.UPLOADING
				} as unknown as AugmentedFile
			}

			return f
		})

		setState(prevState => ({ ...prevState, batchUploading: true, files }))

		const uploadFile = (file: AugmentedFile) => {
			const formData = new FormData()
			formData.append('attachment', file.fileObject)
			formData.append('crp_id', auth.profile.id.toString())

			if (file.status === FileStatus.UPLOADED) {
				return Promise.resolve(file)
			}

			return fetch(`${apiHost}/registrations/forms`, {
				headers: {
					Authorization: 'Bearer ' + auth.jwt
				},
				body: formData,
				method: 'POST'
			})
				.then(response => {
					return response.json().then(data => {
						if (data.status === 'ok') {
							return Promise.resolve({ ...file, status: FileStatus.UPLOADED })
						}
						return Promise.resolve({ ...file, status: FileStatus.FAILED })
					})
				})
				.catch(error => {
					return Promise.resolve({ ...file, status: FileStatus.FAILED })
				})
		}

		// Upload all file files
		const promises = files.map(uploadFile)

		Promise.allSettled(promises)
			.then(data => {
				const resolvedFiles = data.map(file => {
					return file.status === 'fulfilled' ? file.value : undefined
				}) as unknown as AugmentedFile[]

				setState({ ...state, batchUploading: false, files: resolvedFiles })
			})
			.catch(err => setState({ ...state, batchUploading: false }))
	}

	const deleteFiles = () => {
		setState({ files: [], batchUploading: false }) //reset state
	}
	const filesToBeUploaded = state.files.reduce(
		(agg, f) => (f.status !== FileStatus.UPLOADED ? agg + 1 : agg),
		0
	)
	const disabledDeleteButton = state.files.length === 0 || !connected

	const disabledUploadButton =
		state.files.length === 0 || state.batchUploading || filesToBeUploaded === 0 || !connected

	return (
		<AppLayout showHeader title="Upload Form Images">
			<div className="flex flex-wrap content-between mt-20">
				<div className="w-full h-screen sm:px-8 md:px-16 sm:py-8">
					<div className="container mx-auto h-full">
						<div className="relative h-full flex flex-col rounded-md">
							<div className="p-8 w-full  flex flex-col space-y-6">
								<div className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
									<p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
										<span>Upload Form Images</span>
									</p>
									<input
										ref={inputRef}
										type="file"
										id="file"
										accept="image/*"
										className="hidden"
										multiple={true}
										onChange={handleImagesChange}
									/>
									<button
										id="button"
										onClick={() => inputRef?.current?.click()}
										className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
										Select File(s) from Gallery
									</button>
									<p className="text-xs italic text-gray-tip-brand mt-1">(only images, Max: 8MB)</p>
								</div>

								{state.files.length > 0 && (
									<h1 className="font-semibold sm:text-lg text-gray-900">Selected File(s):</h1>
								)}
								{state.files.length === 0 ? (
									<div className="flex flex-1 flex-wrap -m-1">
										<div className="h-full w-full text-center flex flex-col justify-center items-center">
											<span className="text-small text-gray-500">No images selected</span>
										</div>
									</div>
								) : (
									<div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
										{state.files.map(file => (
											<PreviewFile
												key={file.fileObject.name}
												file={file}
												onRemove={handleRemoveFile}
											/>
										))}
									</div>
								)}
							</div>
							<footer className="sm:w-full flex flex-col justify-center px-8 pb-8 pt-4 space-y-2">
								<div className="flex flex-row space-x-4 justify-center">
									<button
										disabled={disabledUploadButton}
										className={clsx(
											'rounded-md px-3 py-2 bg-blue-500 text-white focus:shadow-outline focus:outline-none w-full',
											disabledUploadButton
												? 'bg-gray-tip-brand pointer-events-none'
												: 'bg-light-blue-tip-brand'
										)}
										onClick={() => uploadFiles()}>
										{state.files.length > 1 ? `Upload Files (${filesToBeUploaded})` : 'Upload File'}
									</button>
									<button
										disabled={disabledDeleteButton}
										className={clsx(
											'rounded-md px-3 py-2 bg-blue-500 text-white focus:shadows-outline focus:outline-none w-full',
											disabledDeleteButton
												? 'bg-gray-tip-brand pointer-events-none'
												: 'bg-light-blue-tip-brand'
										)}
										onClick={() => deleteFiles()}>
										Clear All
									</button>
								</div>
							</footer>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

type PreviewFileProps = {
	file: AugmentedFile
	onRemove: (file: AugmentedFile) => void
}

export const PreviewFile = ({ file, onRemove }: PreviewFileProps) => {
	const fileStatus = file.status

	const caption = () => {
		if (!fileStatus) {
			return <p className="mt-1 text-sm text-gray-700">{''}</p>
		}

		if (fileStatus === FileStatus.UPLOADING) {
			return <p className="mt-1 text-sm text-gray-700">Uploading...</p>
		}

		if (fileStatus === FileStatus.UPLOADED) {
			return <p className="mt-1 text-sm text-green-600">Uploading done!</p>
		}

		return <p className="mt-1 text-sm text-red-tip-brand">Uploading failed!</p>
	}

	return (
		<div className="group relative">
			<div className="relative w-full h-40 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 lg:h-48 xl:h-60">
				{file.fileObject.type.startsWith('image') ? (
					<img
						src={file.previewLink}
						alt={file.fileObject.name}
						className="w-full h-full object-center object-contain"
					/>
				) : (
					<div className="flex flex-col justify-center items-center h-full">
						<DocumentTextIcon className="w-20 h-20 text-red-tip-brand" />
						<p className="mt-px text-xs text-gray-700">{file.fileObject.name}</p>
					</div>
				)}

				<div className="flex justify-center items-center absolute inset-1 left-auto w-10 h-10 bg-gray-50 border border-gray-100 rounded-full">
					{!fileStatus && (
						<TrashIcon
							onClick={() => onRemove(file)}
							className="cursor-pointer w-8 h-8 text-red-500"
						/>
					)}
					{fileStatus === FileStatus.UPLOADED && <CheckIcon className="w-8 h-8 text-green-600" />}
					{fileStatus === FileStatus.FAILED && (
						<ExclamationCircleIcon className="w-8 h-8 text-red-tip-brand" />
					)}
					{fileStatus === FileStatus.UPLOADING && <Spinner className="w-8 h-8 text-green-600" />}
				</div>
			</div>
			{caption()}
		</div>
	)
}
