import { Language } from 'constants/constants'
import { default as i18n, default as i18next, StringMap } from 'i18next'

export const getAppLang = () => {
	const lang = (window.localStorage.getItem('i18nLng') || i18n.language) as AppLang
	return lang.includes(Language.UR)
		? Language.UR
		: lang.includes(Language.SD)
		? Language.SD
		: Language.EN
}

export const getTKey = (namespace: string) => {
	return (key: string): string => `${namespace || ''}.${key}`
}

export const t = (
	key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
	options?: StringMap
) => {
	return i18next.t(key as string, options ?? {}) || ''
}

export const getLocalizedName = (obj: any) => {
	if (!obj) return ''
	const appLang = getAppLang()
	const key = appLang === Language.EN ? 'nameEn' : appLang === Language.UR ? 'nameUr' : 'nameSd'
	return obj[key] ?? '-'
}
