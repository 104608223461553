import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'

import { FormPlusIcon, PlwPlusIcon, SalesIcon, StockIcon, VisitPlwIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { LinkCard } from 'components/cards/link-cards'
import { useAppSelector } from 'hooks'
import { useGeoLocation } from 'hooks/useGeoLocation'
import { useLoadResources } from 'hooks/useLoadResources'
import { useLongPress } from 'hooks/useLongPress'
import { useQueuedItemsCounter } from 'hooks/useQueuedChanges'
import clsx from 'utils/clsx'
import { t } from 'utils/language'
import { getDirection } from 'utils/string'
import { useState } from 'react'

export const InventoryManagement = () => {
	// Track user location
	useGeoLocation()

	// Try loading resources again
	useLoadResources()

	const isSeniorCRP = useAppSelector(state => state.auth.profile.isSenior);
	const { connected: isConnectedToInternet } = useAppSelector(state => state.db)

	console.log("isConnectedToInternet",isConnectedToInternet);
	const [isSenior,setIsSenior]= useState<any>(isSeniorCRP)
	const canSubmitPaperForm = useAppSelector(
		state => state.auth.profile.settings?.canSubmitPaperForm
	)
	const queuedItems = useQueuedItemsCounter()

	let routes = [
		{
		  title: t('requisitions.options.requisitionTTitle'),
		  icon: SalesIcon,
		  to: '/view-order-summary',
		  slug: 'view-order-summary',
		  speakerFileName: 'salesPerformance.mp3'
		},
		// {
		//   title: 'Goods received note',
		//   icon: SalesIcon,
		//   to: '/view-received-note',
		//   slug: 'view-received-note',
		//   speakerFileName: 'salesPerformance.mp3'
		// },
		{
		  title: t('home.label.approve-delivery-challan'),
		  icon: SalesIcon,
		  to: '/approve-delivery-challan',
		  slug: 'approve-delivery-challan',
		  speakerFileName: 'salesPerformance.mp3'
		},
		
		{
			title:  t('home.label.StockReports'),
			icon: SalesIcon,
			to: '/stock-reports',
			slug: 'stock-reports',
			speakerFileName: 'salesPerformance.mp3'
		  }
	  ];
	  
	  if (isSenior !== "f") {
		// Insert 'Approve Requisitions' route after 'view-order-summary'
		const index = routes.findIndex(route => route.slug === 'view-order-summary');
		routes.splice(index + 1, 0, {
			title:  t('home.label.approve-requisitions'),
		  icon: FormPlusIcon,
		  to: '/sampleFormTwo',
		  slug: 'sample-form-two',
		  speakerFileName: 'sampleFormTwo.mp3'
		});
	  }

	

	const dir = getDirection()
	const navigate = useNavigate()
	const onLongPress = () => {
		navigate('/biometric', { replace: true })
	}

	const onClick = () => {
		return
	}

	const defaultLongPressOptions = {
		shouldPreventDefault: true,
		delay: 2000
	}

	const longPressEvent = useLongPress(onLongPress, onClick, defaultLongPressOptions)

	const pendingForms =
		queuedItems.distributions +
		queuedItems.followups +
		queuedItems.meetings +
		queuedItems.mwras +
		queuedItems.sales +
		queuedItems?.requisitions +
		queuedItems?.requisitionsRequestSeniorCrp

	return (
		<AppLayout showHeader>
			<div dir={dir}>
				<div className="space-y-4 w-full">
					<div className="flex items-center">
						<Speaker filename="whatWouldYouLikeToDo.mp3" />
						<h1 className="font-semibold text-lg pt-2">{t('meeting.labels.main')}</h1>
					</div>
					{routes.map(route => {
						return (
							<LinkCard
								key={route.title}
								imageSrc={route.icon}
								to={route.to}
								title={route.title}
								className={clsx({
									
									'bg-[#e97bf3]': route.slug === 'stocks-crps',
									'bg-[#b0d6fd]': route.slug === 'sample-form-one',
									'bg-[#b0d7fd]': route.slug === 'approve-delivery-challan',
									'bg-[#b0d9fd]': route.slug === 'sample-form-two',
									'bg-[#b0a8fd]': route.slug === 'view-order-summary',
									'bg-[#b0c6fd]': route.slug === 'view-received-note',
									'bg-[#b0c7fd]': route.slug === 'view-current-stock',
									'bg-[#b0c7fa]': route.slug === 'stock-reports',
								})}
								speakerFileName={route.speakerFileName}
							/>
						)
					
					})}
				</div>

			</div>
		</AppLayout>
	)
}
