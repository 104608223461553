import { CheckIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import { useState } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	useFieldArray,
	useForm,
	UseFormRegister,
	UseFormResetField,
	UseFormSetValue
} from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { TrashIcon } from '@heroicons/react/24/outline'
import {
	BabyboyIcon,
	BabygirlIcon,
	CheckmarkIcon,
	CrossIcon,
	DeceasedIcon,
	LactatingIcon,
	ManIcon,
	PregnantIcon,
	WomanIcon
} from 'assets/icons'
import { Label } from 'components/app/label'
import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { MWRAInfoCard } from 'components/cards/mwra-info-card'
import { InputFieldError } from 'components/error/field-error'
import { CounterDecimalInput, CounterInput } from 'components/inputs/counter'
import { Input } from 'components/inputs/input'
import { Modal } from 'components/modals/modal'
import { contraceptives, WomanConditionTypes } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import { updateMWRA } from 'slices/db'
import { addFollowup } from 'slices/queued'
import clsx from 'utils/clsx'
import { getTKey, t } from 'utils/language'
import { getDirection } from 'utils/string'

enum FormSteps {
	STATUS_CHANGE = 1,
	STATUS,
	DELIVERY_DATE,
	ANC,
	AGE_OF_CHILD_BEING_BREASTFED,
	WOMAN_MUAC,
	FAMILY,
	CHILDREN_COUNT,
	TWO_YEAR_CHILDREN,
	MUAC_CHILDREN,
	CONTRACEPTIVE,
	CONTRACEPTIVE_TYPE,
	COMPLETE
}

type StateType = {
	step: FormSteps
	displayExitModal: boolean
	stepsTaken: number[]
}

const tKey = getTKey('plw')
const errorForRequired = t('errors.required')

export const CreateFollowup = () => {
	const dispatch = useAppDispatch()
	const mwras = useAppSelector(state => state.db.mwras)
	const crp = useAppSelector(state => state.auth.profile)
	const { id } = useParams()

	const [state, setState] = useState<StateType>({
		step: FormSteps.STATUS_CHANGE,
		displayExitModal: false,
		stepsTaken: []
	})

	const mwra = (id ? mwras[parseInt(id)] : {}) as MWRA

	const schema = yup.object({
		isWomanConditionChanged: yup.boolean().required(errorForRequired),
		condition: yup.string().when('$step', {
			is: FormSteps.STATUS,
			then: schema => schema.required(errorForRequired)
		}),
		expectedDeliveryDate: yup.string().when('$step', {
			is: FormSteps.DELIVERY_DATE,
			then: schema =>
				schema.required(t('errors.required', { field: t(tKey('labels.expectedMonth')) }))
		}),
		isMeetingWithAnc: yup.boolean().when('$step', {
			is: FormSteps.ANC,
			then: schema => schema.required(errorForRequired)
		}),
		breastfeedingChildAge: yup
			.number()
			.transform(val => (isNaN(val) ? undefined : val))
			.when('$step', {
				is: FormSteps.AGE_OF_CHILD_BEING_BREASTFED,
				then: schema =>
					schema
						.typeError(t('errors.invalid', { field: t(tKey('labels.breastfedRequired')) }))
						.required(t('errors.required', { field: t(tKey('labels.breastfedRequired')) }))
						.max(6, t('errors.ageMoreThan', { age: '6 months' })),
				otherwise: schema => schema.nullable()
			}),
		totalFamilyMembers: yup
			.number()
			.transform(val => (isNaN(val) ? undefined : val))
			.when('$step', {
				is: FormSteps.FAMILY,
				then: schema =>
					schema
						.typeError(t('errors.invalid', { field: t(tKey('labels.familyMembers')) }))
						.required(t('errors.required', { field: t(tKey('labels.familyMemberRequired')) }))
						.min(2, t('errors.leastFamilyLength', { value: '2' }))
						.max(20, t('errors.familyMoreThan', { value: '20' })),
				otherwise: schema => schema.nullable()
			}),
		totalChildren: yup
			.number()
			.transform(val => (isNaN(val) ? undefined : val))
			.when('$step', {
				is: FormSteps.CHILDREN_COUNT,
				then: schema =>
					schema
						.typeError(t('errors.invalid', { field: t(tKey('labels.children')) }))
						.required(t('errors.required', { field: t(tKey('labels.children')) }))
						.max(20, t('errors.moreThan', { value: 20 })),
				otherwise: schema => schema.nullable()
			}),
		malesUnderTwo: yup
			.number()
			.transform(val => (isNaN(val) ? undefined : val))
			.when(['$step', 'totalChildren'], ([step, totalChildren, femalesUnderTwo], schema) => {
				return step === FormSteps.TWO_YEAR_CHILDREN
					? schema
							.typeError(
								t('errors.invalid', { field: t(tKey('labels.maleChildrenUnderTwoRequired')) })
							)
							.required(
								t('errors.required', { field: t(tKey('labels.maleChildrenUnderTwoRequired')) })
							)
							.max(
								totalChildren - (femalesUnderTwo ?? 0),
								t('errors.moreThan', { value: t(tKey('labels.totalChildren')) })
							)
					: schema.nullable()
			}),
		femalesUnderTwo: yup
			.number()
			.transform(val => (isNaN(val) ? undefined : val))
			.when(['$step', 'totalChildren'], ([step, totalChildren, malesUnderTwo], schema) => {
				return step === FormSteps.TWO_YEAR_CHILDREN
					? schema
							.typeError(
								t('errors.invalid', { field: t(tKey('labels.femaleChildrenUnderTwoRequired')) })
							)
							.required(
								t('errors.required', { field: t(tKey('labels.femaleChildrenUnderTwoRequired')) })
							)
							.max(
								totalChildren - (malesUnderTwo ?? 0),
								t('errors.moreThan', { value: t(tKey('labels.totalChildren')) })
							)
					: schema.nullable()
			}),
		muac: yup
			.number()
			.transform(val => (isNaN(val) ? undefined : val))
			.when('$step', {
				is: FormSteps.WOMAN_MUAC,
				then: schema =>
					schema
						.nullable()
						.typeError(t('errors.invalid', { field: t(tKey('labels.MUACWomanRequired')) }))
						.required(t('errors.required', { field: t(tKey('labels.MUACWomanRequired')) }))
						.min(15, t('errors.lessThan', { value: 15 }))
						.max(50, t('errors.moreThan', { value: 50 })),
				otherwise: schema => schema.nullable()
			}),
		isFamilyPlanning: yup.boolean().when('$step', {
			is: FormSteps.CONTRACEPTIVE,
			then: schema =>
				schema.required(t('errors.required', { field: t(tKey('labels.familyPlanningRequired')) }))
		}),
		muacChildren: yup
			.array()

			.when(['$step', 'totalChildren'], ([step, totalChildren], schema) => {
				if (step === FormSteps.MUAC_CHILDREN) {
					return schema
						.of(
							yup.object().shape({
								name: yup
									.string()
									.required(t('errors.required', { field: t(tKey('labels.childName')) })),
								muac: yup
									.number()
									.typeError(t('errors.invalid', { field: t(tKey('labels.lowMUACChildren')) }))
									.max(11.5, t('errors.moreThan', { value: '11.5' }))
							})
						)
						.max(totalChildren, t('errors.exceedsSum'))
				}
				return schema
			}),
		contraceptiveMethods: yup.array().when('$step', {
			is: FormSteps.CONTRACEPTIVE_TYPE,
			then: schema =>
				schema
					.required(
						t('errors.required', { field: t(tKey('labels.familyPlanningMethodsRequired')) })
					)
					.min(1, t('errors.lessThan', { value: '1' }))
		})
	})
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		resetField,
		control
	} = useForm<FollowupForm>({
		resolver: yupResolver(schema as any),
		context: { step: state.step },
		defaultValues: {
			isWomanConditionChanged: undefined,
			condition: undefined,
			isMeetingWithAnc: undefined,
			breastfeedingChildAge: undefined,
			crpId: crp.id,
			mwraId: mwra.id,
			followupDate: DateTime.now().toISO(),
			totalChildren: undefined
		},
		mode: 'all'
	})

	const renderComponent = cond([
		[
			state.step === FormSteps.STATUS_CHANGE,
			() => <StatusChangeForm {...{ errors, register, control }} />
		],
		[
			state.step === FormSteps.STATUS,
			() => <WomanConditionSelectionForm {...{ errors, register, control }} />
		],
		[
			state.step === FormSteps.DELIVERY_DATE,
			() => (
				<DeliveryDateForm
					errors={errors}
					register={register}
					control={control}
					resetField={resetField}
				/>
			)
		],
		[state.step === FormSteps.ANC, () => <ANCForm {...{ errors, register, control }} />],
		[
			state.step === FormSteps.AGE_OF_CHILD_BEING_BREASTFED,
			() => <BreastfeedingChildForm {...{ errors, register, control }} />
		],
		[
			state.step === FormSteps.WOMAN_MUAC,
			() => <MUACForm {...{ errors, register, control }} previousMuac={mwra?.muac ?? 0} />
		],
		[
			state.step === FormSteps.FAMILY,
			() => <FamilyMembersForm {...{ errors, register, control }} />
		],
		[
			state.step === FormSteps.CHILDREN_COUNT,
			() => <TotalChildrenForm {...{ errors, register, control }} />
		],
		[
			state.step === FormSteps.TWO_YEAR_CHILDREN,
			() => <ChildrenUnderTwoForm {...{ errors, register, control }} />
		],
		[
			state.step === FormSteps.MUAC_CHILDREN,
			() => (
				<MUACChildrenForm
					{...{ errors, register, control }}
					totalChildren={getValues().totalChildren}
				/>
			)
		],
		[
			state.step === FormSteps.CONTRACEPTIVE,
			() => <ContraceptiveUseForm {...{ errors, register, control }} />
		],
		[
			state.step === FormSteps.CONTRACEPTIVE_TYPE,
			() => <ContraceptivesForm {...{ errors, register, control }} />
		]
	])

	const nextStep = handleSubmit(data => {
		let numberOfSteps = 1

		switch (state.step) {
			case FormSteps.STATUS_CHANGE:
				numberOfSteps = data.isWomanConditionChanged ? 1 : 5
				break
			case FormSteps.ANC:
				numberOfSteps = data.condition === WomanConditionTypes.LACTATING ? 1 : 2
				break
			case FormSteps.STATUS:
				numberOfSteps =
					data.condition === WomanConditionTypes.PREGNANT
						? 1
						: data.condition === WomanConditionTypes.LACTATING
						? 3
						: 4
				break
			case FormSteps.CHILDREN_COUNT:
				numberOfSteps = data.totalChildren && data.totalChildren > 0 ? 1 : 2
				if (data.totalChildren === 0 && data.condition === WomanConditionTypes.PREGNANT) {
					onSubmit()
					return
				}
				break
			case FormSteps.WOMAN_MUAC:
				if (!data.isWomanConditionChanged || data.condition === WomanConditionTypes.PREGNANT) {
					onSubmit()
					return
				}
				break

			case FormSteps.CONTRACEPTIVE:
				numberOfSteps = data.isFamilyPlanning ? 1 : 2
				if (!data.isFamilyPlanning) {
					onSubmit()
					return
				}
				break

			default:
				break
		}

		setState(prevState => ({
			...prevState,
			step: prevState.step + numberOfSteps,
			stepsTaken: [...state.stepsTaken, numberOfSteps]
		}))
	})

	const previousStep = () => {
		if (state.step === 1) {
			return
		}

		setState(prevState => ({
			...prevState,
			step: prevState.step - state.stepsTaken[state.stepsTaken.length - 1],
			stepsTaken: state.stepsTaken.filter((number, index) => {
				return index !== state.stepsTaken.length - 1
			})
		}))
	}

	const onSubmit = handleSubmit(data => {
		const form = {
			...data,
			childrenInfo: {
				totalChildren: data?.totalChildren,
				malesUnderTwo: data.malesUnderTwo,
				muacChildren: data.muacChildren,
				breastfeedingChildAge: data.breastfeedingChildAge,
				femalesUnderTwo: data.femalesUnderTwo
			}
		} as FollowupForm

		console.log(form)

		dispatch(addFollowup(form))
		dispatch(updateMWRA(form))
		setState({ ...state, step: FormSteps.COMPLETE })
	})

	const dir = getDirection()

	return (
		<AppLayout
			showHeader
			showCrossIcon
			onBackArrowPress={() => {
				/* eslint-disable no-restricted-globals */
				if (state.step !== FormSteps.COMPLETE && state.step !== FormSteps.STATUS_CHANGE) {
				  previousStep();
				} else {
				  history.back();
				}
				/* eslint-enable no-restricted-globals */
			  }}
			  
			onCrossPress={() => setState({ ...state, displayExitModal: true })}>
			<div dir={dir} className="space-y-2 w-full">
				<MWRAInfoCard mwra={mwra} cluster={crp.cluster} />
				<form
					onSubmit={event => {
						state.step === FormSteps.CONTRACEPTIVE_TYPE ||
						getValues().condition === WomanConditionTypes.DECEASED
							? onSubmit(event)
							: nextStep(event)
					}}
					className="space-y-2 w-full font-bold">
					<>{renderComponent}</>
					{state.step < FormSteps.COMPLETE && (
						<div className="space-y-2 pt-4">
							<button
								type="submit"
								className="p-4 max-w-max mx-auto  flex flex-row justify-between bg-green-500 text-white rounded-full">
								<CheckIcon className="h-10" />
							</button>
							<h1 className="text-center">{t('buttons.next')}</h1>
						</div>
					)}
				</form>
			</div>
			{state.step === FormSteps.COMPLETE && (
				<div className="mt-4 space-y-4">
					<div className="flex items-center justify-center">
						<h1 dir={dir} className="text-center">
							{t('followUp.labels.success')}
						</h1>
						<Speaker filename="followupFormHasBeenSubmittedSuccessfull.mp3" />
					</div>
					<button
						onClick={event => {
								/* eslint-disable no-restricted-globals */
							event.preventDefault()
							history.back()
															/* eslint-disable no-restricted-globals */

						}}
						className="p-4 max-w-max mx-auto  flex flex-row justify-between bg-green-500 text-white rounded-full">
						<CheckIcon className="h-10" />
					</button>
				</div>
			)}
			{state.displayExitModal && (
				<Modal>
					<div className="bg-white px-6 space-y-4 py-4">
						<h1 className="text-center font-semibold">{t('common.labels.backWithoutSaving')}</h1>
						<div className="flex flex-row justify-center items-center">
							<button
								onClick={event => {
																	/* eslint-disable no-restricted-globals */

									event.preventDefault()
									history.back()
																	/* eslint-disable no-restricted-globals */

								}}
								type="button"
								className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-red-500 font-bold px-8 py-2 text-white">
								{t('buttons.yes')}
							</button>
							<button
								onClick={event => {
									event.preventDefault()
									setState({ ...state, displayExitModal: false })
								}}
								type="button"
								className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-green-500 font-bold px-8 py-2 text-white">
								{t('buttons.no')}
							</button>
						</div>
					</div>
				</Modal>
			)}
		</AppLayout>
	)
}

interface FormProps {
	register?: UseFormRegister<FollowupForm>
	errors?: FieldErrors<FollowupForm>
	control?: Control<FollowupForm, any>
	setValue?: UseFormSetValue<FollowupForm>
	resetField?: UseFormResetField<FollowupForm>
}

const StatusChangeForm = ({ control, register, errors }: FormProps) => {
	const statusChangedError = errors?.['isWomanConditionChanged']?.message as string

	return (
		<div className="space-y-4">
			<div className="flex items-center">
				<Speaker filename="hasMwraStatusChanged.mp3" />
				<h2 className="text-lg pt-2">{t('followUp.labels.conditionChanged')}</h2>
			</div>
			<Controller
				control={control}
				name={'isWomanConditionChanged'}
				render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
					<>
						<div className="space-y-2">
							<div className="space-y-1">
								<label>
									<div
										className={clsx(
											'flex w-full flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
											{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value !== undefined && value }
										)}>
										<input
											type="radio"
											onBlur={onBlur} // notify when input is touched
											onChange={() => onChange(true)} // send value to hook form
											checked={value !== undefined && value}
										/>
										<img className="h-10 aspect-square" src={CheckmarkIcon} />
										<p>{t('buttons.yes')}</p>
									</div>
								</label>
								<label>
									<div
										className={clsx(
											'flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
											{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value !== undefined && !value }
										)}>
										<input
											type="radio"
											onBlur={onBlur} // notify when input is touched
											onChange={() => onChange(false)} // send value to hook form
											checked={value !== undefined && !value}
										/>
										<img className="h-10 aspect-square" src={CrossIcon} />
										<p>{t('buttons.no')}</p>
									</div>
								</label>
							</div>
						</div>
						<InputFieldError message={statusChangedError} />
					</>
				)}
			/>
		</div>
	)
}

const WomanConditionSelectionForm = ({ control }: FormProps) => {
	return (
		<div className="space-y-2">
			<div className="flex items-center">
				<Speaker filename="condition.mp3" />
				<h2 className="text-lg pt-2">{t(tKey('labels.condition'))}</h2>
			</div>
			<Controller
				control={control}
				name={'condition'}
				render={({ field: { onChange, onBlur, value } }) => (
					<div className="font-normal space-y-2">
						<label>
							<div
								className={clsx(
									'flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
									{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value === WomanConditionTypes.PREGNANT }
								)}>
								<input
									value={WomanConditionTypes.PREGNANT}
									type="radio"
									onBlur={onBlur} // notify when input is touched
									onChange={event => onChange(event.target.value)} // send value to hook form
									checked={value === WomanConditionTypes.PREGNANT}
								/>
								<img className="h-10 w-10" src={PregnantIcon} />
								<div className="flex items-center">
									<p>{t(tKey('options.pregnant'))}</p>
									<Speaker filename="Pregnant.mp3" />
								</div>
							</div>
						</label>
						<label>
							<div
								className={clsx(
									'flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
									{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value === WomanConditionTypes.LACTATING }
								)}>
								<input
									value={WomanConditionTypes.LACTATING}
									type="radio"
									onBlur={onBlur} // notify when input is touched
									onChange={event => onChange(event.target.value)} // send value to hook form
									checked={value === WomanConditionTypes.LACTATING}
								/>
								<img className="h-10 w-10" src={LactatingIcon} />
								<div className="flex items-center">
									<p>{t(tKey('options.lactating'))}</p>
									<Speaker filename="Lactating.mp3" />
								</div>
							</div>
						</label>
						<label>
							<div
								className={clsx(
									'flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
									{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value === WomanConditionTypes.MWRA }
								)}>
								<input
									value={WomanConditionTypes.MWRA}
									type="radio"
									onBlur={onBlur} // notify when input is touched
									onChange={event => onChange(event.target.value)} // send value to hook form
									checked={value === WomanConditionTypes.MWRA}
								/>
								<img className="h-10 w-10" src={WomanIcon} />

								<p>{t(tKey('options.mwra'))}</p>
								<Speaker filename="woman.mp3" />
							</div>
						</label>
						<label>
							<div
								className={clsx(
									'flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
									{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value === WomanConditionTypes.DECEASED }
								)}>
								<input
									value={WomanConditionTypes.DECEASED}
									type="radio"
									onBlur={onBlur} // notify when input is touched
									onChange={event => onChange(event.target.value)} // send value to hook form
									checked={value === WomanConditionTypes.DECEASED}
								/>
								<img className="h-10 w-10" src={DeceasedIcon} />
								<div className="flex items-center">
									<p>{t(tKey('options.deceased'))}</p>
									<Speaker filename="" />
								</div>
							</div>
						</label>
					</div>
				)}
			/>
		</div>
	)
}

// TODO: move this to constants file
const months: { [monthName: string]: number } = {
	Jan: 1,
	Feb: 2,
	Mar: 3,
	Apr: 4,
	May: 5,
	Jun: 6,
	Jul: 7,
	Aug: 8,
	Sep: 9,
	Oct: 10,
	Nov: 11,
	Dec: 12
}

const DeliveryDateForm = ({ control, errors, resetField }: FormProps) => {
	const currYear = DateTime.now().year
	const [selectedYear, setSelectedYear] = useState(currYear)
	const deliveryDateError = errors?.['expectedDeliveryDate']?.message as string
	const [customError, setCustomError] = useState<string>()
	const yearsArray = [currYear, currYear + 1]

	const validateDate = (month: number, year: number) => {
		const selectedDate = DateTime.fromObject({ day: 1, month, year })

		const currentDate = DateTime.fromObject({
			day: 1,
			month: DateTime.now().month,
			year: currYear
		})

		const isPreviousDate = selectedDate.diff(currentDate, 'month').months < 0

		// Not using this now but can be a later requirement
		const differenceGreaterThanTenMonths = selectedDate.diff(currentDate, 'month').months > 10
		if (isPreviousDate) {
			setCustomError(t(tKey('errors.deliveryMonth')))
			return false
		}
		setCustomError(undefined)
		return true
	}

	return (
		<div className="space-y-4">
			<Label labelText={t(tKey('labels.expectedMonth'))} filename={'dueDeliveryMonth.mp3'} />
			<div dir="ltr" className="flex flex-row items-center justify-center">
				{yearsArray.map((year, index) => (
					<div
						key={year}
						onClick={event => {
							event.preventDefault()
							resetField?.('expectedDeliveryDate')
							setSelectedYear(year)
						}}
						className={clsx(
							'px-8 border border-gray-400 py-2 ',
							index === 0 ? 'rounded-l-md' : 'rounded-r-md',
							{ 'text-blue-500 border-blue-500 bg-blue-100': selectedYear == year }
						)}>
						{year}
					</div>
				))}
			</div>
			<Controller
				control={control}
				name={'expectedDeliveryDate'}
				render={({ field: { onChange, onBlur, value } }) => (
					<div className="grid grid-cols-3 gap-2 pt-4">
						{Object.entries(months).map(([monthName, monthNumber]) => {
							// Check if the current date is previous to the selected date
							const currentDate = DateTime.now()

							const selectedDate = DateTime.fromObject({
								day: 1,
								month: monthNumber,
								year: selectedYear
							})

							const isPreviousDate =
								selectedDate.year > currentDate.year
									? false
									: selectedDate.month <= currentDate.month - 1

							return (
								<label key={monthName}>
									<div
										className={clsx(
											'flex w-full flex-row items-center space-x-2 rounded-xl border px-2 py-3',
											{
												'bg-gray-300 border-gray-300 cursor-not-allowed': isPreviousDate, // Apply disable cursor for previous months
												'bg-[#E9ECFF] border-[#E9ECFF]': value === `${monthNumber}-${selectedYear}`
											}
										)}>
										<input
											value={monthName}
											type="radio"
											onBlur={onBlur}
											onChange={event => {
												if (validateDate(monthNumber, selectedYear)) {
													onChange(`${monthNumber}-${selectedYear}`)
												}
											}}
											checked={value === `${monthNumber}-${selectedYear}`}
											disabled={isPreviousDate}
										/>
										<p>{t(`months.${monthName.toLocaleLowerCase()}`)}</p>
									</div>
								</label>
							)
						})}
					</div>
				)}
			/>
			<InputFieldError message={deliveryDateError} className="ml-1" />

			{customError && <InputFieldError message={customError} />}
		</div>
	)
}

const ANCForm = ({ control, register, errors }: FormProps) => {
	const ancError = errors?.['isMeetingWithAnc']?.message as string

	return (
		<div className="space-y-2">
			<div className="flex items-center">
				<Speaker filename="meetingANC.mp3" />
				<h2 className="text-lg">{t(tKey('labels.ANC'))}</h2>
			</div>

			<Controller
				control={control}
				name={'isMeetingWithAnc'}
				render={({ field: { onChange, onBlur, value } }) => (
					<div className="space-y-2">
						<div className="space-y-1">
							<label>
								<div
									className={clsx(
										'flex w-full flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
										{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value !== undefined && value }
									)}>
									<input
										type="radio"
										onBlur={onBlur} // notify when input is touched
										onChange={() => onChange(true)} // send value to hook form
										checked={value !== undefined && value}
									/>
									<img className="h-10 aspect-square" src={CheckmarkIcon} />
									<p>{t('buttons.yes')}</p>
								</div>
							</label>
							<label>
								<div
									className={clsx(
										'flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
										{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value !== undefined && !value }
									)}>
									<input
										type="radio"
										onBlur={onBlur} // notify when input is touched
										onChange={() => onChange(false)} // send value to hook form
										checked={value !== undefined && !value}
									/>
									<img className="h-10 aspect-square" src={CrossIcon} />
									<p>{t('buttons.no')}</p>
								</div>
							</label>
						</div>
						<InputFieldError message={ancError} />
					</div>
				)}
			/>
		</div>
	)
}
const MUACForm = ({ control, previousMuac }: FormProps & { previousMuac: number }) => {
	return (
		<div className="p-4 space-y-2">
			<div className="flex items-center">
				{' '}
				<Speaker filename="muacOfMWRA.mp3" />
				<h2 className="text-lg">{t(tKey('labels.MUACWoman'))}</h2>
			</div>

			<h2 className="text-sm text-slate-600">
				{t('followUp.labels.previousMuac', { previousMuac })}
			</h2>

			<Controller
				control={control}
				name={'muac'}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<div>
						<CounterDecimalInput
							maxLength={2}
							step={0.1}
							controllerError={error}
							onChange={event => {
								onChange(event.target.valueAsNumber)
							}}
							onNumberChange={number => {
								onChange(number)
							}}
							value={value}
						/>
					</div>
				)}
			/>
		</div>
	)
}

const BreastfeedingChildForm = ({ control }: FormProps) => {
	return (
		<div className="p-4 space-y-2">
			<div className="flex items-center">
				<Speaker filename="ageOfLactatingChild.mp3" />
				<h2 className="text-lg">{t(tKey('labels.breastfed'))}</h2>
			</div>
			<Controller
				control={control}
				name={'breastfeedingChildAge'}
				render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
					<div>
						<CounterInput
							onChange={onChange}
							onBlur={onBlur}
							controllerError={error}
							maxLength={1}
							maxValue={6}
							onNumberChange={number => onChange(number == 0 ? '' : number)}
							value={value}
						/>
					</div>
				)}
			/>
		</div>
	)
}

const FamilyMembersForm = ({ control }: FormProps) => {
	return (
		<div className="space-y-4">
			<Label labelText={t(tKey('labels.familyMembers'))} filename={'countOfFamilyMembers.mp3'} />
			<img className="h-10 w-10 mt-2 mx-auto" src={ManIcon} />
			<Controller
				control={control}
				name={'totalFamilyMembers'}
				render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
					<div>
						<CounterInput
							maxLength={2}
							controllerError={error}
							onBlur={onBlur}
							onChange={onChange}
							onNumberChange={number => onChange(number)}
							value={value}
						/>
					</div>
				)}
			/>
		</div>
	)
}

const TotalChildrenForm = ({ control }: FormProps) => {
	return (
		<div className="space-y-4">
			<Label labelText={t(tKey('labels.children'))} filename={'totalNumberOfKids.mp3'} />
			<img className="h-10 aspect-square mx-auto my-2" src={BabyboyIcon} />

			<Controller
				control={control}
				name={'totalChildren'}
				render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
					<div>
						<CounterInput
							maxLength={2}
							onBlur={onBlur}
							controllerError={error}
							onChange={onChange}
							onNumberChange={number => onChange(number)}
							value={value}
						/>
					</div>
				)}
			/>
		</div>
	)
}

const ChildrenUnderTwoForm = ({ control }: FormProps) => {
	return (
		<div className="space-y-4">
			<Label
				labelText={t(tKey('labels.maleChildrenUnderTwo'))}
				filename={'boysUnderTheAgeOfTwo.mp3'}
			/>
			<img className="h-10 aspect-square block mx-auto" src={BabyboyIcon} />
			<Controller
				control={control}
				name={'malesUnderTwo'}
				render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
					<div>
						<CounterInput
							maxLength={2}
							controllerError={error}
							onBlur={onBlur}
							onChange={onChange}
							onNumberChange={number => onChange(number)}
							value={value}
						/>
					</div>
				)}
			/>

			<Label
				labelText={t(tKey('labels.femaleChildrenUnderTwo'))}
				filename={'girlsUnderTheAgeOfTwo.mp3'}
			/>
			<img className="h-10 aspect-square block mx-auto" src={BabygirlIcon} />
			<Controller
				control={control}
				name={'femalesUnderTwo'}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<div>
						<CounterInput
							maxLength={2}
							controllerError={error}
							onChange={onChange}
							onNumberChange={number => onChange(number)}
							value={value}
						/>
					</div>
				)}
			/>
		</div>
	)
}
const MUACChildrenForm = ({
	errors,
	control,
	totalChildren,
	register
}: FormProps & { totalChildren?: number }) => {
	const { fields, append, remove } = useFieldArray({ name: 'muacChildren', control })

	return (
		<div className="space-y-4">
			<Label labelText={t(tKey('labels.lowMUACChildren'))} filename={'childrenInformation.mp3'} />

			{fields.map((child, index) => {
				return (
					<div key={index} className="space-y-4">
						<div>
							<Input
								placeholder="Name"
								type="text"
								labelText={t(tKey('labels.childName'))}
								name={`muacChildren.${index}.name`}
								register={register}
								rounded={true}
								error={errors}
							/>
							<InputFieldError
								message={errors?.['muacChildren']?.[index]?.['name']?.message ?? ''}
								className="my-2"
							/>
						</div>
						<h1 className="font-medium text-black">{t(tKey('labels.muac'))}</h1>
						<Controller
							control={control}
							name={`muacChildren.${index}.muac`}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<div>
									<CounterDecimalInput
										onChange={onChange}
										onBlur={onBlur}
										controllerError={error}
										maxLength={2}
										onNumberChange={number => onChange(number === 0 ? '' : number)}
										value={value}
									/>
								</div>
							)}
						/>

						<div
							className="flex w-full justify-end max-w-max pb-2 hover:cursor-pointer"
							onClick={() => remove(index)}>
							<TrashIcon className="w-5 h-5 text-red-500" />
							<p className="text-red-500 cursor-pointer max-w-max">{t('buttons.remove')}</p>
						</div>
					</div>
				)
			})}
			<div>
				<p
					className="text-blue-600 px-2 cursor-pointer max-w-max hover:underline"
					onClick={event => {
						event.preventDefault()
						if (totalChildren && fields.length >= totalChildren) {
							return toast.error('Cannot add more children')
						}
						append({ name: '',age:0, muac: '' as any })
					}}>
					{t(tKey('buttons.addChild'))}
				</p>
			</div>
		</div>
	)
}
const ContraceptiveUseForm = ({ control, errors }: FormProps) => {
	const contraceptiveUseError = errors?.['isFamilyPlanning']?.message as string

	return (
		<div className="space-y-4">
			<Label labelText={t(tKey('labels.familyPlanning'))} filename={'familyPlanning.mp3'} />
			<Controller
				control={control}
				name={'isFamilyPlanning'}
				render={({ field: { onChange, onBlur, value } }) => (
					<div className="space-y-2 px-2">
						<div className="space-y-1">
							<label>
								<div
									className={clsx(
										'flex w-full flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
										{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value !== undefined && value }
									)}>
									<input
										type="radio"
										onBlur={onBlur} // notify when input is touched
										onChange={() => onChange(true)} // send value to hook form
										checked={value !== undefined && value}
									/>
									<img className="h-10 aspect-square" src={CheckmarkIcon} />

									<p>{t('buttons.yes')}</p>
								</div>
							</label>
							<label>
								<div
									className={clsx(
										'flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
										{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value !== undefined && !value }
									)}>
									<input
										type="radio"
										onBlur={onBlur} // notify when input is touched
										onChange={() => onChange(false)} // send value to hook form
										checked={value !== undefined && !value}
									/>
									<img className="h-10 aspect-square" src={CrossIcon} />

									<p>{t('buttons.no')}</p>
								</div>
							</label>
						</div>
						<InputFieldError message={contraceptiveUseError} />
					</div>
				)}
			/>
		</div>
	)
}

const ContraceptivesForm = ({ control, errors }: FormProps) => {
	const contraceptiveMethods = errors?.['contraceptiveMethods']?.message as string

	return (
		<div className="space-y-4">
			<Label
				labelText={t(tKey('labels.familyPlanningMethods'))}
				filename={'methodForFamilyPlanning.mp3'}
			/>
			<Controller
				control={control}
				name={'contraceptiveMethods'}
				render={({ field: { onChange, onBlur, value } }) => (
					<div className="py-2">
						{contraceptives.map(({ title: contraceptiveMethod, image }) => {
							return (
								<label key={contraceptiveMethod}>
									<div
										className={clsx(
											'flex w-full flex-row my-2 items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3',
											{ 'bg-[#E9ECFF] border-[#E9ECFF] ': value?.includes(contraceptiveMethod) }
										)}>
										<input
											value={contraceptiveMethod}
											type="checkbox"
											onBlur={onBlur} // notify when input is touched
											onChange={event => {
												if (value?.includes(event.target.value)) {
													const newValue = value.filter(
														contraceptive => contraceptive !== contraceptiveMethod
													)
													return onChange(newValue)
												}
												onChange([...(value ?? []), contraceptiveMethod])
											}} // send value to hook form
											checked={value?.includes(contraceptiveMethod)}
										/>
										{image && <img src={image} className="h-8 w-8" />}

										<p>{t(tKey(`options.${contraceptiveMethod}`))}</p>
									</div>
								</label>
							)
						})}
						<InputFieldError message={contraceptiveMethods} />
					</div>
				)}
			/>
		</div>
	)
}
