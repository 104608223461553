import { CheckIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import cond from 'cond-construct'
import { useState } from 'react'
import { FieldErrors, FieldValues, UseFormRegister, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { unwrapResult } from '@reduxjs/toolkit'
import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { CheckButton } from 'components/buttons/check-button'
import { Input } from 'components/inputs/input'
import { Language } from 'constants/constants'
import { useAppDispatch } from 'hooks'
import { login } from 'slices/auth'
import clsx from 'utils/clsx'
import { getTKey, t } from 'utils/language'
import { getDirection } from 'utils/string'
import { AppVersion } from 'utils/version'

type RegistrationForm = {
	username: string
	password: string
}

enum FormSteps {
	PERSONAL = 1,
	COMPLETE
}
const tKey = getTKey('login')
export const Login = () => {
	const dispatch = useAppDispatch()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const schema = yup.object({
		username: yup.string().required(t('errors.required', { field: 'Username' })),
		password: yup.string().required(t('errors.required', { field: 'Password' }))
	})

	const [state, setState] = useState({
		step: FormSteps.PERSONAL
	})
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues
	} = useForm<RegistrationForm>({
		resolver: yupResolver(schema as any),
		defaultValues: { username: '', password: '' },
		mode: 'all'
	})

	const nextStep = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		const [stepAllowed, errorMessage] = validateBeforeMoving()
		if (!stepAllowed) {
			return toast.error(errorMessage || 'Error Occurred')
		}

		setState(prevState => ({ ...prevState, step: prevState.step + 1 }))
	}

	const validateBeforeMoving = (): [stepAllowed: boolean, errorMessage: string | undefined] => {
		const formValues = getValues()
		let stepAllowed = true
		switch (state.step) {
			case FormSteps.PERSONAL:
				stepAllowed =
					!!(formValues.password && formValues.username) && !(errors.username || errors.password)
				const errorMessage = errors.username ? errors.username.message : errors.password?.message
				return [stepAllowed, errorMessage || t(tKey('errors.enterCredentials'))]

			default:
				return [stepAllowed, undefined]
		}
	}

	const renderComponent = cond([
		[
			state.step === FormSteps.PERSONAL,
			() => <UsernameAndPasswordForm errors={errors} register={register} />
		],
		[state.step === FormSteps.COMPLETE, () => <RegistrationComplete />]
	])

	const onSubmit = handleSubmit(data => {
		setIsSubmitting(true)
		dispatch(login({ username: data.username, password: data.password }))
			.then(unwrapResult)
			.then(value => {
				localStorage.setItem('isRegistrationComplete', 'true')
			})
			.catch(error => {
				toast.error('Login Failed')
			})
			.finally(() => setIsSubmitting(false))
	})

	const { i18n } = useTranslation()
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	const dir = getDirection()

	return (
		<AppLayout isLoggedIn={true} onBackArrowPress={() => setState({ ...state, step: FormSteps.PERSONAL })}>
			<div className="py-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div
					dir={dir}
					className={clsx('flex flex-col', {
						'items-center justify-center': state.step === FormSteps.COMPLETE
					})}>
					<div className="flex w-full justify-end">
						<select
							name="language"
							className="rounded-md border-none text-xs text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-500 text-left"
							defaultValue={i18n.language || window.localStorage.i18nextLng}
							onChange={event => changeLanguage(event.target.value)}>
							<option value={Language.EN}>{t('select.languageOptions.english')}</option>
							<option value={Language.UR}>{t('select.languageOptions.urdu')}</option>
							<option value={Language.SD}>{t('select.languageOptions.sindhi')}</option>
						</select>
					</div>
					<form onSubmit={onSubmit} className="space-y-6 w-full font-bold">
						<>{renderComponent}</>
						{state.step < FormSteps.COMPLETE && (
							<div className="flex items-center flex-col justify-center">
								<div className="flex justify-center items-center">
									{isSubmitting ? (
										<Spinner className="w-12 h-12 my-3.5" />
									) : (
										<CheckButton type="submit" />
									)}
								</div>
								<div className="flex items-center mt-4">
									<Speaker filename="login.mp3" />
									<p className="pt-1.5">{t('login.labels.main')}</p>
								</div>
							</div>
						)}

						{state.step === FormSteps.COMPLETE && (
							<div className="flex items-center flex-col justify-center">
								<button
									type="submit"
									className="text-md mb-2 max-w-max p-2 rounded-full bg-green-500 font-bold  text-white">
									<CheckIcon className="h-12 w-12" />
								</button>
								<p>
									<Speaker filename="submit.mp3" />
									{t('buttons.finish')}
								</p>
							</div>
						)}
					</form>
				</div>
			</div>
			<p className="absolute inset-x-0 bottom-10 text-center text-xs font-light text-slate-700 font-sans">
				App Version: {AppVersion}
			</p>
		</AppLayout>
	)
}

interface FormProps {
	register?: UseFormRegister<RegistrationForm>
	errors: FieldErrors<FieldValues>
}

const UsernameAndPasswordForm = ({ register, errors }: FormProps) => {
	return (
		<div>
			<div className="mt-36 w-full space-y-6">
				<div className="flex items-center justify-center">
					<Speaker filename="loginToTheApp.mp3" />
					<h1 className="text-2xl font-semibold leading-10 text-center">
						{t('login.labels.login')}
					</h1>
				</div>

				<Input
					register={register}
					type="text"
					name="username"
					id="username"
					required
					placeholder={t(tKey('placeholders.username'))}
					rounded={true}
				/>

				<Input
					required
					register={register}
					error={errors}
					type="password"
					name="password"
					id="password"
					rounded={true}
					placeholder={t(tKey('placeholders.password'))}
				/>
			</div>
		</div>
	)
}

const RegistrationComplete = () => {
	return (
		<div className="flex items-center">
			<Speaker filename="pleaseAddYourFingerprint.mp3" />
			<h1 className="text-center">{t(tKey('labels.successAddFingerprint'))}</h1>
		</div>
	)
}
