import axios from 'services/axios'
//getCrpSale.php
//https://api-test.micare.pk/services/saveRequisition.php

const saveRequisition = (data: any) => {
    console.log("data",data);
	return axios.post('saveRequisition.php', {
		data
	})
}
const saveRequisitionRecievedNote = (data: any) => {
    console.log("data",data);
	return axios.post('saveGrn.php', {
		data
	})
}
const saveRequisitionRequestApproval = (data: any) => {
    console.log("data",data);
	return axios.post('saveApprovals.php', {
		data
	})
}
//https://api-test.micare.pk/services/getRequisitions.php
const getRequisition = () => {
	return axios.get(`/getRequisitions.php`).then(response => {
		return response.data
	})
	.catch(error => {
		console.error("Error fetching data:", error);
		throw error; // Optionally re-throw the error to propagate it to the calling code.
	});
}
//getRequisitionsForSeniorCrp.php

const getRequisitionForSeniorCrp = () => {
	return axios.get(`/getRequisitionsForSeniorCrp.php`).then(response => {
		return response.data
	})
	.catch(error => {
		console.error("Error fetching data:", error);
		throw error; // Optionally re-throw the error to propagate it to the calling code.
	});
}
const getGrn = () => {
	return axios.get(`/getGrns.php`).then(response => {
		return response.data
	})
	.catch(error => {
		console.error("Error fetching data:", error);
		throw error; // Optionally re-throw the error to propagate it to the calling code.
	});
}
//https://api-test.micare.pk/services/getStockTransfers.php For showing list of transfers

const getStockTransfers = () => {
	return axios.get(`/getStockTransfers.php`).then(response => {
		return response.data
	})
	.catch(error => {
		console.error("Error fetching data:", error);
		throw error; // Optionally re-throw the error to propagate it to the calling code.
	});
}

const requisitionService = {
    saveRequisition,
    getRequisition,
    saveRequisitionRecievedNote,
    getGrn,
	getRequisitionForSeniorCrp,
	saveRequisitionRequestApproval,
	getStockTransfers
}

export default requisitionService
