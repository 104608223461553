import axios from 'axios'
import localForage from 'localforage'
import { apiHost } from 'utils/host'

const login = (username: string, password: string) => {
	return axios
		.post(`${apiHost}/login.php`, {
			user: { username, password, clientType: 'crp' }
		})
		.then(response => {
			return response.data
		})
}

const logout = (callback = () => console.log('store clear')) => {
	localForage.clear(callback)
}

const authService = {
	login,
	logout
}

export default authService
