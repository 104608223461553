import {
	CondomIcon,
	HighIncomeIcom,
	InjectionIcon,
	IUCDIcon,
	LowIncomeIcom,
	MidIncomeIcom,
	OCPIcon,
	ShrugIcon
} from 'assets/icons'

export const contraceptives = [
	{ title: 'ocp', image: OCPIcon },
	{ title: 'condom', image: CondomIcon },
	{ title: 'iud', image: IUCDIcon },
	{ title: 'implant' },
	// { title: 'ring' },
	// { title: 'diaphragm' },
	{ title: 'injection', image: InjectionIcon }
]

export const incomeRange = [
	{ title: '0 - 15000', image: LowIncomeIcom },
	{ title: '15001 - 25000', image: MidIncomeIcom },
	{ title: '25001 - 35000', image: HighIncomeIcom },
	{ title: '35001 - 45000', image: HighIncomeIcom },
	{ title: 'Above 45000', image: HighIncomeIcom },
	{ title: "Don't know", image: ShrugIcon }
]

export enum Language {
	EN = 'en',
	UR = 'ur',
	SD = 'sd'
}

export const enum WomanConditionTypes {
	PREGNANT = 'pregnant',
	LACTATING = 'lactating',
	MWRA = 'mwra',
	DECEASED = 'deceased'
	// GRADUATION = 'graduation'
}

export const conditions = [
	'pregnant',
	'lactating',
	'mwra',
	'deceased'
	// 'graduation'
]

// export enum StatsPeriodTypes {
// 	DAILY = 'daily',
// 	WEEKLY = 'weekly',
// 	MONTHLY = 'monthly',
// 	YEARLY = 'yearly',
// 	CUSTOM_DATE = 'custom_date'
// }
export enum StatsPeriodTypes {
	TODAY = 'today',
	YESTERDAY = 'yesterday',
	THIS_WEEK = 'this_week',
	LAST_WEEK = 'last_week',
	THIS_MONTH = 'this_month',
	LAST_MONTH = 'last_month',
	LAST_3_MONTHS = 'last_3_months',
	THIS_YEAR = 'this_year',
	LAST_YEAR = 'last_year',
	CUSTOM_DATE = 'custom_date'
  }
  