import { ArrowDownIcon } from 'assets/icons'

interface FilterProps {
	title: string
	onClick: () => void
}
export const CustomFilter = ({ title, onClick }: FilterProps) => {
	return (
		<div
			className={
				'border border-neutral-300 rounded-full text-black space-x-2 px-4 py-2 bg-white text-xs inline-flex items-center cursor-pointer'
			}
			onClick={onClick}>
			<p className="text-xs">{title}</p>
			<img className="h-2 w-2.5" src={ArrowDownIcon}></img>
		</div>
	)
}
