import { indexedStorageDB } from 'utils/local-forage'

export const getAuthHeader = async () => {
	let db = (await indexedStorageDB.getItem('persist:root')) as string

	// For deep parsing, have to pass reviver parameter.
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse#the_reviver_parameter

	const jwt = JSON.parse(db, (key, value) => {
		if (typeof value === 'string') {
			return JSON.parse(value)
		}
		return value
	})?.auth?.jwt

	return 'Bearer ' + jwt
}
