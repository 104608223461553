import { useParams } from 'react-router-dom'

import { BasketIcon, EditProfileIcon, FillFormMobile, OrderHistoryIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { LinkCard } from 'components/cards/link-cards'
import { MWRAInfoCard } from 'components/cards/mwra-info-card'
import { useAppSelector } from 'hooks'
import { useLoadResources } from 'hooks/useLoadResources'
import clsx from 'utils/clsx'
import { t } from 'utils/language'
import { getDirection } from 'utils/string'

export const MwraMenu = () => {
	const { id } = useParams()

	const cluster = useAppSelector(state => state.auth.profile.cluster)
	const mwras = useAppSelector(state => state.db.mwras)

	// TODO: if there's wrong id or mwra not found
	// show error page
	const mwra = mwras[parseInt(id ?? '0')]

	// Try loading resources again
	useLoadResources()

	const routes = [
		{
			slug: 'mwras-sale',
			title: t('followUp.labels.sale'),
			icon: BasketIcon,
			to: `/mwras/${mwra.id}/sale`,
			speakerFileName: 'sellProducts.mp3'
		},
		{
			slug: 'mwras-followups',
			title: t('followUp.labels.fillForm'),
			icon: FillFormMobile,
			to: `/mwras/${mwra.id}/followup`,
			speakerFileName: 'fillTheFollowUpForm.mp3'
		},
		{
			slug: 'mwras-sales',
			title: t('followUp.labels.history'),
			icon: OrderHistoryIcon,
			to: `/mwras/${mwra.id}/sales`,
			speakerFileName: 'salesHistory.mp3'
		},
		{
			slug: 'mwras-details',
			title: t('followUp.labels.moreDetails'),
			icon: EditProfileIcon,
			to: `/mwras/${mwra.id}/edit`,
			speakerFileName: 'viewMoreInformation.mp3'
		}
	]

	const dir = getDirection()

	return (
		<AppLayout showHeader>
			<div dir={dir} className="space-y-4">
				<MWRAInfoCard mwra={mwra} cluster={cluster} />

				{routes.map(route => {
					return (
						<LinkCard
							key={route.title}
							imageSrc={route.icon}
							to={route.to}
							title={route.title}
							className={clsx({
								'bg-[#AFE9F5]': route.slug === 'mwras-sale',
								'bg-[#91F2DD]': route.slug === 'mwras-followups',
								'bg-[#FBE1C5]': route.slug === 'mwras-sales',
								'bg-[#9ed1fc]': route.slug === 'mwras-details'
							})}
							speakerFileName={route.speakerFileName}
						/>
					)
				})}
			</div>
		</AppLayout>
	)
}
