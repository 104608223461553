import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Routing } from 'routing'

import store from 'store'
import './i18n'

import * as serviceWorkerRegistration from './service-worker-registration'

import { Language } from 'constants/constants'
import 'styles/helper.css'
import 'styles/main.css'
import { getAppLang } from 'utils/language'

const root = ReactDOM.createRoot(document.getElementById('crp-root') as HTMLElement)

const persistor = persistStore(store)

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Toaster
					position={'top-right'}
					toastOptions={{
						style: {
							margin: '15px',
							background: '#828282',
							color: '#fff',
							width: '340px'
						},
						className: `${getAppLang() === Language.EN ? '' : 'font-urdu'} text-base`,
						duration: 3000
					}}
				/>
				<Routing />
			</PersistGate>
		</Provider>
	</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// register() the service worker
serviceWorkerRegistration.register()
