import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { CustomFilter } from "components/app/filter";
import { AppLayout } from "components/app/layout";
import { RadioInput } from "components/app/radio";
import { ButtonOkay } from "components/buttons/button-ok";
import { Modal } from "components/modals/modal";
import { useAppSelector } from "hooks";
import { toShortISO8086 } from "utils/date";
import { getLocalizedName, t } from "utils/language";
import { getDirection } from "utils/string";
import clsx from "utils/clsx";

export enum SalesPeriodTypes {
  TODAY = "today",
  YESTERDAY = "yesterday",
  THIS_WEEK = "this_week",
  LAST_WEEK = "last_week",
  THIS_MONTH = "this_month",
  LAST_MONTH = "last_month",
  LAST_3_MONTHS = "last_3_months",
  THIS_YEAR = "this_year",
  LAST_YEAR = "last_year",
  CUSTOM_DATE = "custom_date",
}
const getDateRange = (period: SalesPeriodTypes) => {
  const currentDate = DateTime.now().toISODate();

  switch (period) {
    case SalesPeriodTypes.TODAY:
      return [currentDate, currentDate];
    case SalesPeriodTypes.YESTERDAY:
      const yesterday = DateTime.now().minus({ days: 1 }).toISODate();
      return [yesterday, yesterday];
    case SalesPeriodTypes.THIS_WEEK:
      const startOfWeek = DateTime.now().startOf("week").toISODate();
      return [startOfWeek, currentDate];
    case SalesPeriodTypes.LAST_WEEK:
      const startOfLastWeek = DateTime.now().minus({ weeks: 1 }).startOf("week").toISODate();
      const endOfLastWeek = DateTime.now().minus({ weeks: 1 }).endOf("week").toISODate();
      return [startOfLastWeek, endOfLastWeek];
    case SalesPeriodTypes.THIS_MONTH:
      const startOfMonth = DateTime.now().startOf("month").toISODate();
      return [startOfMonth, currentDate];
    case SalesPeriodTypes.LAST_MONTH:
      const startOfLastMonth = DateTime.now().minus({ months: 1 }).startOf("month").toISODate();
      const endOfLastMonth = DateTime.now().minus({ months: 1 }).endOf("month").toISODate();
      return [startOfLastMonth, endOfLastMonth];
    case SalesPeriodTypes.LAST_3_MONTHS:
      const startOfLast3Months = DateTime.now().minus({ months: 3 }).startOf("month").toISODate();
      return [startOfLast3Months, currentDate];
    case SalesPeriodTypes.THIS_YEAR:
      const startOfYear = DateTime.now().startOf("year").toISODate();
      return [startOfYear, currentDate];
    case SalesPeriodTypes.LAST_YEAR:
      const startOfLastYear = DateTime.now().minus({ years: 1 }).startOf("year").toISODate();
      const endOfLastYear = DateTime.now().minus({ years: 1 }).endOf("year").toISODate();
      return [startOfLastYear, endOfLastYear];
    // Add case for CUSTOM_DATE if needed
    default:
      return [currentDate, currentDate];
  }
};
export const SalesPerformance = () => {
	const requisitionsData = useAppSelector(state => Object.values(state.db.requisitions));
	
	// Example usage:
	


  const products = useAppSelector((state) => state.db.products);
  const sales = useAppSelector((state) => state.db.sales);
 // console.log("mappedSalesItems mappedSalesItems sales", sales);
  const [customStartDate, setCustomStartDate] = useState(""); // State for custom start date
  const [customEndDate, setCustomEndDate] = useState(""); // State for custom end date

  const [params, setParam] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [startOfWeek, currentDate] = getDateRange(SalesPeriodTypes.THIS_WEEK);

  const salesPeriod = (params.get("sales-period") as SalesPeriodTypes) ?? SalesPeriodTypes.THIS_WEEK;
  const [salesStart, salesEnd] = getDateRange(salesPeriod) ?? [startOfWeek, currentDate];

  const totalAmountReq = calculateTotalAmount(requisitionsData,salesPeriod,customStartDate,customEndDate);
	// console.log("totalAmount",totalAmountReq)
	// console.log("requisitionsData",requisitionsData)
	// console.log("customStartDate",customStartDate)
	// console.log("customEndDate",customEndDate)

	
	 
 // console.log("salesPeriod",salesPeriod)
  //   const mappedSalesItems = Object.values(sales)
  //     .reduce((agg, sale) => {
  //       const d1 = DateTime.fromISO(salesStart);
  //       const d2 = DateTime.fromISO(salesEnd);
  //       const saleDate = DateTime.fromISO(toShortISO8086(sale.saleDate));

  //       if (saleDate >= d1 && saleDate <= d2) {
  //         return [...agg, ...sale.saleItems];
  //       }

  //       return agg;
  //     }, [] as SaleItem[])
  //     .reduce(
  //       (agg, saleItem) => {
  //         return {
  //           ...agg,
  //           [saleItem.productId]: [...(agg[saleItem.productId] ?? []), saleItem],
  //         };
  //       },
  //       {} as { [pid: ID]: SaleItem[] }
  //     );
  //new
  const mappedSalesItems = Object.values(sales)
    .reduce((agg, sale) => {
     // console.log("salesdasdsd",sale)
      const saleDate = DateTime.fromISO(toShortISO8086(sale.saleDate));

      switch (salesPeriod) {
        case SalesPeriodTypes.TODAY:
          if (saleDate.hasSame(DateTime.now(), "day")) {
            return [...agg, ...sale.saleItems ];
          }
          break;
        case SalesPeriodTypes.YESTERDAY:
          if (saleDate.hasSame(DateTime.now().minus({ days: 1 }), "day")) {
            return [...agg, ...sale.saleItems];
          }
          break;
        case SalesPeriodTypes.THIS_WEEK:
          if (saleDate >= DateTime.now().startOf("week") && saleDate <= DateTime.now().endOf("week")) {
            return [...agg, ...sale.saleItems];
          }
          break;
        case SalesPeriodTypes.LAST_WEEK:
          const lastWeekStart = DateTime.now().minus({ weeks: 1 }).startOf("week");
          const lastWeekEnd = DateTime.now().minus({ weeks: 1 }).endOf("week");
          if (saleDate >= lastWeekStart && saleDate <= lastWeekEnd) {
            return [...agg, ...sale.saleItems];
          }
          break;
        case SalesPeriodTypes.THIS_MONTH:
          const startOfMonth = DateTime.now().startOf("month");
          const endOfMonth = DateTime.now().endOf("month");
          if (saleDate >= startOfMonth && saleDate <= endOfMonth) {
            return [...agg, ...sale.saleItems];
          }
          break;

        case SalesPeriodTypes.LAST_MONTH:
          const lastMonthStart = DateTime.now().minus({ months: 1 }).startOf("month");
          const lastMonthEnd = DateTime.now().minus({ months: 1 }).endOf("month");
          if (saleDate >= lastMonthStart && saleDate <= lastMonthEnd) {
            return [...agg, ...sale.saleItems];
          }
          break;

        case SalesPeriodTypes.LAST_3_MONTHS:
          const last3MonthsStart = DateTime.now().minus({ months: 3 }).startOf("month");
          const last3MonthsEnd = DateTime.now().endOf("month");
          if (saleDate >= last3MonthsStart && saleDate <= last3MonthsEnd) {
            return [...agg, ...sale.saleItems];
          }
          break;

        case SalesPeriodTypes.THIS_YEAR:
          const startOfYear = DateTime.now().startOf("year");
          const endOfYear = DateTime.now().endOf("year");
          if (saleDate >= startOfYear && saleDate <= endOfYear) {
            return [...agg, ...sale.saleItems];
          }
          break;

        case SalesPeriodTypes.LAST_YEAR:
          const lastYearStart = DateTime.now().minus({ years: 1 }).startOf("year");
          const lastYearEnd = DateTime.now().minus({ years: 1 }).endOf("year");
          if (saleDate >= lastYearStart && saleDate <= lastYearEnd) {
            return [...agg, ...sale.saleItems];
          }
          break;

        case SalesPeriodTypes.CUSTOM_DATE:
          // Handle CUSTOM_DATE case
          const saleCustomStartDate = DateTime.fromISO(toShortISO8086(customStartDate));
          const saleCustomEndDate = DateTime.fromISO(toShortISO8086(customEndDate));

          if (saleDate >= saleCustomStartDate && saleDate <= saleCustomEndDate) {
            return [...agg, ...sale.saleItems];
          }
          break;

        default:
          // Handle default case
          break;
      }

      return agg;
    }, [] as SaleItem[])
    .reduce(
      (agg, saleItem) => {
       // console.log("da,jsbdkjashgdhsaldgashida",agg)

        return {
          ...agg,
          [saleItem.productId]: [...(agg[saleItem.productId] ?? []), saleItem],
        };
      },
      {} as { [pid: ID]: SaleItem[] }
    );
    // console.log('Sales',sales)
    const timePeriodSales = Object.values(sales)
    .reduce((agg:any, sale:any) => {
      const saleDate = DateTime.fromISO(toShortISO8086(sale.saleDate));
  
      switch (salesPeriod) {
        case SalesPeriodTypes.TODAY:
          if (saleDate.hasSame(DateTime.now(), "day")) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.YESTERDAY:
          if (saleDate.hasSame(DateTime.now().minus({ days: 1 }), "day")) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.THIS_WEEK:
          if (saleDate >= DateTime.now().startOf("week") && saleDate <= DateTime.now().endOf("week")) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.LAST_WEEK:
          const lastWeekStart = DateTime.now().minus({ weeks: 1 }).startOf("week");
          const lastWeekEnd = DateTime.now().minus({ weeks: 1 }).endOf("week");
          if (saleDate >= lastWeekStart && saleDate <= lastWeekEnd) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.THIS_MONTH:
          const startOfMonth = DateTime.now().startOf("month");
          const endOfMonth = DateTime.now().endOf("month");
          if (saleDate >= startOfMonth && saleDate <= endOfMonth) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.LAST_MONTH:
          const lastMonthStart = DateTime.now().minus({ months: 1 }).startOf("month");
          const lastMonthEnd = DateTime.now().minus({ months: 1 }).endOf("month");
          if (saleDate >= lastMonthStart && saleDate <= lastMonthEnd) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.LAST_3_MONTHS:
          const last3MonthsStart = DateTime.now().minus({ months: 3 }).startOf("month");
          const last3MonthsEnd = DateTime.now().endOf("month");
          if (saleDate >= last3MonthsStart && saleDate <= last3MonthsEnd) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.THIS_YEAR:
          const startOfYear = DateTime.now().startOf("year");
          const endOfYear = DateTime.now().endOf("year");
          if (saleDate >= startOfYear && saleDate <= endOfYear) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.LAST_YEAR:
          const lastYearStart = DateTime.now().minus({ years: 1 }).startOf("year");
          const lastYearEnd = DateTime.now().minus({ years: 1 }).endOf("year");
          if (saleDate >= lastYearStart && saleDate <= lastYearEnd) {
            return [...agg, sale];
          }
          break;
        case SalesPeriodTypes.CUSTOM_DATE:
          const saleCustomStartDate = DateTime.fromISO(toShortISO8086(customStartDate));
          const saleCustomEndDate = DateTime.fromISO(toShortISO8086(customEndDate));
          if (saleDate >= saleCustomStartDate && saleDate <= saleCustomEndDate) {
            return [...agg, sale];
          }
          break;
        default:
          break;
      }
  
      return agg;
    }, []);
  
 // console.log("timePeriodSales", timePeriodSales);
  
 // console.log("mappedSalesItemsmappedSalesItems", mappedSalesItems);
  function countUniqueMawraids(sales:any) {
    const uniqueMawraids = new Set(); // Create a Set to store unique mawraids
    
    // Loop through the response object and add unique mawraids to the Set
    Object.values(timePeriodSales).forEach((sale:any) => {
      uniqueMawraids.add(sale.mwraId);
    });
  
    // Return the count of unique mawraids
    return uniqueMawraids.size;
  }

  // map sales items by product id for
  // fast checking product sales exist
  // or not

  const countHouseHold = countUniqueMawraids(timePeriodSales) ;
 // console.log("countHouseHold",countHouseHold)

  function calculateTotalSalesAndProfit(products: { [key: string]: Product }, mappedSalesItems: { [pid: string]: SaleItem[] }): { totalSalesAmounts: { [pid: string]: number }; totalProfit: number } {
    const calculatedTotalSalesAmounts: { [pid: string]: number } = {};
    let totalProfit = 0;

    Object.entries(products).forEach(([pid, product]) => {
      const saleItems = mappedSalesItems[pid as unknown as ID];
      if (saleItems) {
        const totalSalesAmount = saleItems.reduce((agg, item) => agg + item.amount, 0);
        calculatedTotalSalesAmounts[pid] = totalSalesAmount;

        // Calculate total profit for each product
        const totalQuantity = saleItems.reduce((agg, item) => agg + item.quantity, 0);
        const totalSalesMargin = totalQuantity * (product.margin || 0);
        totalProfit += totalSalesMargin;
      }
    });

    return { totalSalesAmounts: calculatedTotalSalesAmounts, totalProfit };
  }

  // Usage
  const { totalSalesAmounts, totalProfit } = calculateTotalSalesAndProfit(products, mappedSalesItems);
 // console.log("totalSalesAmounts", totalSalesAmounts);
 // console.log("totalProfit", totalProfit);

  // Calculate the sum of total sales amounts
  const sumOfSalesAmounts = Object.values(totalSalesAmounts).reduce((sum, amount) => sum + amount, 0);
  // const mappedSalesItemsarray = Object.values(mappedSalesItems)
  // console.log("sumOfSalesAmounts",mappedSalesItemsarray)

  const dir = getDirection();
//   function calculateTotalAmount(reqData: any[]): number {
//     let totalAmount = 0;

//     reqData.forEach((order: any) => {
//         if (order.orderedProducts.length > 0) {
//             order.orderedProducts.forEach((product: any) => {
//                 totalAmount += product.amount;
//             });
//         }
//     });

//     return totalAmount;
// }

function calculateTotalAmount(reqData: any[], salesPeriod: SalesPeriodTypes, customStartDate: string, customEndDate: string): number {
	let totalAmount = 0;
  
	const startDate = getStartDateBasedOnPeriod(salesPeriod, customStartDate);
	const endDate = getEndDateBasedOnPeriod(salesPeriod, customEndDate);
  // console.log(reqData)

	reqData.forEach((order: any) => {
	  const orderDate = DateTime.fromISO(order.orderDate);
	  // Check if the order date is within the selected date range
	  if (orderDate >= startDate && orderDate <= endDate) {
		if (order.orderedProducts.length > 0) {
		  order.orderedProducts.forEach((product: any) => {
			totalAmount += product.quantity*product.unitPrice;
		  });
		}
	  }
	});
  // console.log(totalAmount)
	return totalAmount;
  }

function getStartDateBasedOnPeriod(salesPeriod: SalesPeriodTypes, customStartDate: string): DateTime {
	switch (salesPeriod) {
	  case SalesPeriodTypes.TODAY:
		return DateTime.now().startOf("day");
	  case SalesPeriodTypes.YESTERDAY:
		return DateTime.now().minus({ days: 1 }).startOf("day");
	  case SalesPeriodTypes.THIS_WEEK:
		return DateTime.now().startOf("week");
	  case SalesPeriodTypes.LAST_WEEK:
		return DateTime.now().minus({ weeks: 1 }).startOf("week");
	  case SalesPeriodTypes.THIS_MONTH:
		return DateTime.now().startOf("month");
	  case SalesPeriodTypes.LAST_MONTH:
		return DateTime.now().minus({ months: 1 }).startOf("month");
	  case SalesPeriodTypes.LAST_3_MONTHS:
		return DateTime.now().minus({ months: 3 }).startOf("month");
	  case SalesPeriodTypes.THIS_YEAR:
		return DateTime.now().startOf("year");
	  case SalesPeriodTypes.LAST_YEAR:
		return DateTime.now().minus({ years: 1 }).startOf("year");
	  case SalesPeriodTypes.CUSTOM_DATE:
		return DateTime.fromISO(customStartDate);
	  default:
		return DateTime.now();
	}
  }
  
  function getEndDateBasedOnPeriod(salesPeriod: SalesPeriodTypes, customEndDate: string): DateTime {
	switch (salesPeriod) {
	  case SalesPeriodTypes.TODAY:
		return DateTime.now().endOf("day");
	  case SalesPeriodTypes.YESTERDAY:
		return DateTime.now().minus({ days: 1 }).endOf("day");
	  case SalesPeriodTypes.THIS_WEEK:
		return DateTime.now().endOf("week");
	  case SalesPeriodTypes.LAST_WEEK:
		return DateTime.now().minus({ weeks: 1 }).endOf("week");
	  case SalesPeriodTypes.THIS_MONTH:
		return DateTime.now().endOf("month");
	  case SalesPeriodTypes.LAST_MONTH:
		return DateTime.now().minus({ months: 1 }).endOf("month");
	  case SalesPeriodTypes.LAST_3_MONTHS:
		return DateTime.now().endOf("month");
	  case SalesPeriodTypes.THIS_YEAR:
		return DateTime.now().endOf("year");
	  case SalesPeriodTypes.LAST_YEAR:
		return DateTime.now().minus({ years: 1 }).endOf("year");
	  case SalesPeriodTypes.CUSTOM_DATE:
		return DateTime.fromISO(customEndDate);
	  default:
		return DateTime.now();
	}
  }
  

// function calculateTotalAmount(reqData: any[], startDate: DateTime, endDate: DateTime): number {
//     let totalAmount = 0;

//     reqData.forEach((order: any) => {
//         const orderDate = DateTime.fromISO(toShortISO8086(order.orderDate));

//         if (orderDate >= startDate && orderDate <= endDate) {
//             if (order.orderedProducts.length > 0) {
//                 order.orderedProducts.forEach((product: SaleItem) => {
//                     totalAmount += product.amount;
//                 });
//             }
//         }
//     });

//     return totalAmount;
// }

// Example usage:


  const radioItems = {
    [SalesPeriodTypes.TODAY]: t("viewOrderSummary.modalSummary.radioToday"),
    [SalesPeriodTypes.YESTERDAY]: t("viewOrderSummary.modalSummary.radioYesterday"),
    [SalesPeriodTypes.THIS_WEEK]: t("viewOrderSummary.modalSummary.radioThisWeek"),
    [SalesPeriodTypes.LAST_WEEK]: t("viewOrderSummary.modalSummary.radioLastWeek"),
    [SalesPeriodTypes.THIS_MONTH]: t("viewOrderSummary.modalSummary.radioThisMonth"),
    [SalesPeriodTypes.LAST_MONTH]: t("viewOrderSummary.modalSummary.radioLastMonth"),
    [SalesPeriodTypes.LAST_3_MONTHS]: t("viewOrderSummary.modalSummary.radioLast3Months"),
    [SalesPeriodTypes.THIS_YEAR]: t("viewOrderSummary.modalSummary.radioThisYear"),
    [SalesPeriodTypes.LAST_YEAR]: t("viewOrderSummary.modalSummary.radioLastYear"),
    [SalesPeriodTypes.CUSTOM_DATE]: t("viewOrderSummary.modalSummary.radioSelectDate"),
  };
 // console.log("mappedSalesItems", mappedSalesItems);

  return (
    <AppLayout showHeader>
      <div className={"flex justify-end mb-4"}>
        <CustomFilter title={radioItems[salesPeriod] ?? t("sales.labels.performance.modal.radioWeek")} onClick={() => setShowModal(true)} />
      </div>
      <div style={{ marginBottom: "9px" }} dir={dir} className="grid grid-cols-3 text-center gap-2">
        <div className={clsx("py-1 rounded md-rounded w-auto", "bg-[#dbf1d3d1]")}>
          <h2 className="font-medium text-sm">{ t("viewOrderSummary.totalOrdeerAmount")}</h2>
          <h2> {totalAmountReq.toFixed(1)}</h2>
        </div>
        <div className={clsx("py-1 rounded md-rounded w-auto", "bg-[#F4DBC2]")}>
          <h2 className="font-medium text-sm">{ t("viewOrderSummary.saleeAmount")}</h2>
          <h2>{sumOfSalesAmounts.toFixed(1)}</h2>
        </div>
        <div className={clsx("py-1 rounded md-rounded w-auto", "bg-[#E3F18C]")}>
          <h2 className="font-medium text-sm">{ t("viewOrderSummary.tootalProfit")}</h2>
          <h2>{totalProfit.toFixed(1)}</h2>
        </div>
        <div   className={clsx("py-1 rounded md-rounded w-auto", "bg-[#b0d7fd]")}>
          <h2 className="font-medium text-sm">{ t("viewOrderSummary.visits")}</h2>
          <h2>{timePeriodSales?.length}</h2>
        </div>
        <div style={{width:"130px"}} className={clsx("py-1 px-2 rounded md-rounded w-auto", "bg-[#91F2DD]")}>
          <h2 className="font-medium text-sm">{ t("viewOrderSummary.household")}</h2>
          <h2>{countHouseHold}</h2>
        </div>
      </div>

      <div className="space-y-2" dir={dir}>
        {Object.entries(products).map(([pid, product]) => {
          const saleItems = mappedSalesItems[pid as unknown as ID];
          if (!saleItems) {
            return null;
          }
         // console.log("saleItems", saleItems);

          const totalSalesAmount = saleItems.reduce((agg, item) => agg + item.amount, 0);
         // console.log("totalSalesAmount.toLocaleString()", totalSalesAmount.toLocaleString());

          const totalQuantity = saleItems.reduce((agg, item) => agg + item.quantity, 0);

          // TODO: need to find a way to lock the version so that it doesn't
          // change over time when price or margin updated
          const totalSalesMargin = totalQuantity * product.margin;

          return (
            <div key={product.id} className={`flex flex-row items-center shadow-sm border border-gray-200 bg-white rounded-lg `}>
              <img className="h-28 w-28 object-contain" src={product.attachments[0].url} />
              <div className="space-y-1 mx-4">
                <p className="font-semibold">{getLocalizedName(product)}</p>
                <p className="text-md font-medium">
                  <span className="font-semibold">{t("sales.labels.performance.totalQuantity")}: </span>
                  {totalQuantity.toLocaleString()}
                </p>
                <p className="text-md font-medium">
                  <span className="font-semibold">{t("sales.labels.performance.totalSales")}: </span>
                  {totalSalesAmount.toLocaleString()} {t("products.label.rupees")}
                </p>
                <p className="text-md font-medium">
                  <span className="font-semibold">{t("sales.labels.performance.totalProfit")}: </span>
                  {totalSalesMargin.toLocaleString()} {t("products.label.rupees")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {showModal && (
        <Modal title={t("sales.labels.performance.modal.heading")} onClose={() => setShowModal(false)}>
          <div dir={dir} className="w-full bg-white p-4">
            <div className="">
              <div className="space-y-2 mt-4">
                {Object.entries(radioItems).map(([id, labelText], index) => (
                  <RadioInput onChange={() => setParam({ "sales-period": id }, { replace: true })} checked={salesPeriod === id} labelText={labelText} key={index} />
                ))}

                {/* Custom fields for CUSTOM_DATE */}
                {salesPeriod === SalesPeriodTypes.CUSTOM_DATE && (
                  <div className={clsx("flex-col space-y-2 px-2 text-sm mt-4")}>
                    <label className="block text-sm font-medium text-gray-700">
                      Custom Start Date:
                      <input type="date" className="mt-1 p-2 border rounded-md w-full" value={customStartDate} onChange={(e) => setCustomStartDate(e.target.value)} />
                    </label>

                    <label className="block text-sm font-medium text-gray-700">
                      Custom End Date:
                      <input type="date" className="mt-1 p-2 border rounded-md w-full" value={customEndDate} onChange={(e) => setCustomEndDate(e.target.value)} />
                    </label>
                  </div>
                )}
              </div>
            </div>
            <ButtonOkay onClick={() => setShowModal(false)} title={t("buttons.okay")} />
          </div>
        </Modal>
      )}
    </AppLayout>
  );
};
