import axios from 'services/axios'

const createMeeting = (meeting: MeetingForm) => {
	//https://api-test.micare.pk/services/saveMeeting.php
	return axios.post('/saveMeeting.php', {
		meeting
	})
}

const meetingService = {
	createMeeting
}

export default meetingService
