import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { indexedStorageDB } from 'utils/local-forage'

import authService from 'services/auth-service'
import authReducer from 'slices/auth'
import dbReducer, { initialDbState } from 'slices/db'
import queuedReducer, { initialQueuedState } from 'slices/queued'

const rootPersistConfig = {
	key: 'root',
	version: 1,
	storage: indexedStorageDB
}

const appReducer = combineReducers({
	auth: authReducer,
	queued: queuedReducer,
	db: dbReducer
})

export const logout = () => ({
	type: 'USER_LOGOUT'
})

const rootReducer = (state: RootReducerState, action: AnyAction) => {
	if (action.type === 'USER_LOGOUT') {
		// for all keys defined in your persistConfig(s)
		authService.logout()

		state = {
			auth: {} as AuthState,
			db: initialDbState,
			queued: initialQueuedState
		}

		return appReducer(state, action)
	}

	return appReducer(state, action)
}

//@ts-ignore
const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>

export default store
