import { useAppSelector } from 'hooks'

type QueuedItems = keyof QueuedState

type QueuedItemCounter = {
	[item in QueuedItems]: number
}

export const useQueuedItemsCounter = () => {
	const queued = useAppSelector(state => state.queued ?? {})

	const queuedItemsCount = (Object.keys(queued) as QueuedItems[]).reduce((agg, curr) => {
		const unsyncedItemCount = Object.keys(queued[curr] ?? {}).length
		return { ...agg, [curr]: unsyncedItemCount }
	}, {} as QueuedItemCounter)

	return queuedItemsCount
}
