import { Menu, Transition } from "@headlessui/react";
import { ArrowRightOnRectangleIcon, CloudArrowDownIcon, Cog6ToothIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { LanguageIcon, UserIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

import clsx from "clsx";
import { Fragment, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Spinner } from "components/animations/spinner";
import { BackButton } from "components/buttons/back-button";
import { Modal } from "components/modals/modal";
import { Language } from "constants/constants";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState, logout } from "store";
import { t } from "utils/language";
import { getDirection } from "utils/string";
import { AppVersion } from "utils/version";
import { RadioInput } from "components/app/radio";

import { saveSycingOn } from "slices/db";

interface HeaderProps {
  onBackArrowPress?: () => void;
  title?: string;
  showHeader?: boolean;
  showCrossIcon?: boolean;
  onCrossPress?: () => void;
}

export const AppHeader: React.FC<HeaderProps> = ({ onBackArrowPress, showCrossIcon, onCrossPress }) => {
  const queuedChanges = useSelector((state: RootState) => state.queued);
  const lastSyncedOn = useAppSelector((state) => state.db.lastSyncedOn);
  const isSyncedOn = useAppSelector((state) => state.db.isSyncedOn);
  const [openLangMenu, setOpenLangMenu] = useState(false);
  const [exportData, setExportData] = useState(false);

  const [isExporting, setExporting] = useState(false);
  const [openModal, setOpenModal] = useState({ logoutModal: false, exportModal: false });
  const [syncing, setSyncing] = useState<boolean>(isSyncedOn);

  const isHomeRoute = window.location.pathname.endsWith("/home") || window.location.pathname === "/";
  const auth = useAppSelector((state) => state.auth);

  const unsavedData = useAppSelector((state) => state.queued);

  const unSyncCount = Object.values(unsavedData).reduce((agg, curr) => {
    return agg + Object.keys(curr).length;
  }, 0);

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);

    // TODO: Check why layout not RTL-lized
    // on language change

    // hack to force layout change
    window.location.reload();
  };

  const dir = getDirection();

  const handleLogout = () => {
    if (unSyncCount > 0) {
      const message = unSyncCount === 1 ? t("errors.unSyncChange", { count: unSyncCount }) : t("errors.unSyncChanges", { count: unSyncCount });

      return toast.custom(
        (t) => (
          <div dir={dir} className={`${t.visible ? "animate-enter" : "animate-leave"} max-w-md w-full bg-white shadow-lg justify-between rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}>
            <div className={clsx("flex items-center px-2")}>
              <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500" />
              <p
                className={clsx("px-2 text-red-600", {
                  "font-urdu": dir === "rtl",
                })}
              >
                {message}
              </p>
            </div>
            <div className={clsx("flex border-gray-200", dir === "ltr" ? "border-l" : "border-r")}>
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
        ),
        {
          position: "top-center",
        }
      );
    }

    setOpenModal((prev) => ({ ...prev, logoutModal: true }));
  };

  const exportUnsyncedChanges = (state: QueuedState) => {
    setExporting(true);

    const unsyncedChanges = {
      mwras: Object.values(state.mwras),
      sales: Object.values(state.sales),
      followups: Object.values(state.followups),
      meetings: Object.values(state.meetings),
      distributions: Object.values(state.distributions),
    };

    const hasUnsyncedChanges = Object.values(unsyncedChanges).some((items) => items.length > 0);

    if (hasUnsyncedChanges) {
      const json = JSON.stringify(unsyncedChanges, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${new Date().getTime()}_crp_sync_data.json`;
      a.click();

      URL.revokeObjectURL(url);
    }

    setTimeout(() => {
      setExporting(false);
    }, 1500);
  };

  const changeDateFormate = (timestamp: number) => {
    const date = new Date(timestamp);

    // Get month abbreviation
    const monthAbbreviation = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);

    // Get day, year, and time components
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear().toString().slice(-2);
    const hours: any = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert 12-hour time to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Create the formatted date string
    const formattedDate = `${monthAbbreviation}-${day}-${year} ${formattedHours}:${minutes} ${period}`;

    return formattedDate;
  };
  const handleChangeSyncing = (e: any) => {
    setSyncing(e.target.checked);
    dispatch(saveSycingOn(e.target.checked));
  };
  return (
    <nav
      className={clsx("mx-auto flex items-center justify-start border-b border-gray-200 px-6 py-8 justify-between", {
        "justify-end py-6": isHomeRoute,
      })}
    >
     { window.location.pathname === "/"
 &&<>
        <div>
          <p className="text-xs">Last Synced On:</p> <p className="text-xs">{changeDateFormate(lastSyncedOn)}</p>
        </div>
        <div>
          {/* <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value="syncing"
              checked={syncing}
              onChange={(e) => {
                handleChangeSyncing(e);
              }}
              className="focus:outline-none border p-2 sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <div className="ml-2">
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                <div>Sync Data</div>
              </span>
            </div>
          </label> */}
        </div>
      </>}
      {openModal.exportModal && (
        <Modal
          onClose={() => {
            setExportData(false);
            setOpenModal((prev) => ({ ...prev, exportModal: false }));
          }}
          showCrossIcon={true}
          className="space-y-6"
        >
          <p className="whitespace-nowrap">{t("exportData.labels.confirmExportData")}</p>
          <div className="flex justify-center items-center mt-4">
            <button onClick={() => exportUnsyncedChanges(queuedChanges)} type="button" className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-[#9ed1fc]  font-bold rounded-3xl text-md text-center mr-2 mb-2">
              <span dir={dir} className={clsx("flex items-center", dir === "rtl" && "gap-2")}>
                {unSyncCount > 0 && isExporting ? (
                  <>
                    <Spinner className="h-6 w-6 mr-2 text-white" />
                    <p>{`${t("exportData.buttons.downloading")}`}</p>
                  </>
                ) : (
                  <>
                    <CloudArrowDownIcon className="h-6 w-6 mr-2" />
                    <p> {`${t("exportData.buttons.download")}`}</p>
                  </>
                )}
              </span>
            </button>
          </div>
        </Modal>
      )}
      {openModal.logoutModal && (
        <Modal>
          {t("plw.options.logoutModal")}
          <div className="flex flex-row justify-center items-center mt-4">
            <button onClick={() => dispatch(logout())} type="button" className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-red-500 font-bold py-2 text-white">
              {t("buttons.yes")}
            </button>
            <button onClick={() => setOpenModal((prev) => ({ ...prev, logoutModal: false }))} type="button" className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-green-500 font-bold py-2 text-white">
              {t("buttons.no")}
            </button>
          </div>
        </Modal>
      )}
      {!isHomeRoute && (
        <div dir={dir} className={clsx("relative flex w-full items-center space-x-3 px-2 lg:px-0 text-white")}>
          {/* eslint-disable no-restricted-globals */}
          <BackButton onClick={() => (onBackArrowPress ? onBackArrowPress() : history.back())} />
          {/* eslint-enable no-restricted-globals */}
        </div>
      )}
      {showCrossIcon && (
        <div dir={dir} className={clsx(dir == "ltr" ? "ml-auto" : "absolute mr-auto")}>
          <XCircleIcon className="h-6 w-6 text-red-500" onClick={() => onCrossPress?.()} />
        </div>
      )}
      {isHomeRoute && (
        <div dir={dir} className="flex items-center gap-4">
          <div className="flex items-center space-x-2">
            <div className="inset-y-0">
              <Menu as="div" className="relative inline-block text-left">
                {({ open: openMenu }) => (
                  <>
                    <Menu.Button className="inline-flex w-full items-center space-x-4 text-slate-900">
                      {!openMenu ? (
                        <div className="flex cursor-pointer h-10 w-10 items-center justify-center rounded-full bg-white">
                          <UserIcon className="h-5 w-5 text-gray-600" />
                        </div>
                      ) : (
                        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white">
                          <XMarkIcon className="h-6 w-6 text-red-500" onClick={() => onCrossPress?.()} />
                        </div>
                      )}
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute top-full right-0 mt-3 -mr-0.5 w-60 origin-top-right scale-100 transform divide-y divide-gray-100 rounded-lg bg-white text-sm font-normal text-slate-900 opacity-100 shadow-md ring-1 ring-slate-900/5 focus:outline-none sm:-mr-3.5">
                        <div className="truncate py-3 px-2" role="none">
                          {/* <span className="block text-xs text-gray-500" role="none">
														Signed in as
													</span> */}
                          <div className="flex items-center">
                            <UserIcon className="h-4 w-4" />
                            <span className="mx-2" role="none">
                              {auth?.profile?.cluster?.code}
                            </span>
                          </div>
                        </div>

                        <Menu.Items>
                          <div className="relative z-10">
                            <button
                              type="button"
                              className="w-full items-center px-2 py-4 text-sm flex flex-row hover:bg-gray-200 md:px-2"
                              onClick={() => {
                                setOpenLangMenu(!openLangMenu);
                                setExportData(false);
                              }}
                            >
                              <LanguageIcon className="block h-5" />
                              <span className="mx-2">{t("select.language")}</span>
                            </button>
                            {openLangMenu && (
                              <div className="absolute right-0 mt-2 w-40 bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50">
                                <div className="py-1 divide-y-2 divide-gray-100" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <button
                                    className="block w-full px-4 py-3.5 text-left text-sm text-gray-700 outline-none hover:bg-gray-100"
                                    role="menuitem"
                                    onClick={() => {
                                      changeLanguage(Language.EN);
                                    }}
                                  >
                                    {t("select.languageOptions.english")}
                                  </button>
                                  <button
                                    className="block w-full px-4 py-3.5 text-left text-sm text-gray-700 outline-none hover:bg-gray-100"
                                    role="menuitem"
                                    onClick={() => {
                                      changeLanguage(Language.UR);
                                    }}
                                  >
                                    {t("select.languageOptions.urdu")}
                                  </button>
                                  <button
                                    className="block w-full px-4 py-3.5 text-left text-sm text-gray-700 outline-none hover:bg-gray-100"
                                    role="menuitem"
                                    onClick={() => {
                                      changeLanguage(Language.SD);
                                    }}
                                  >
                                    {t("select.languageOptions.sindhi")}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </Menu.Items>

                        <Menu.Items>
                          <div className="relative">
                            <button
                              type="button"
                              className="w-full items-center px-2 py-4 text-sm flex flex-row hover:bg-gray-200 md:px-2"
                              onClick={() => {
                                setExportData(!exportData);
                                setOpenLangMenu(false);
                              }}
                            >
                              <Cog6ToothIcon className="block h-5" />
                              <span className="mx-2">{t("exportData.buttons.settings")}</span>
                            </button>
                            {exportData && (
                              <div className="absolute right-0 mt-2 w-40 bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50">
                                <div className="py-1 divide-y-2 divide-gray-100" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <button
                                    className="block w-full px-4 py-3.5 text-left text-sm text-gray-700 outline-none hover:bg-gray-100"
                                    role="menuitem"
                                    onClick={() => {
                                      setOpenModal((prev) => ({ ...prev, exportModal: true }));
                                    }}
                                  >
                                    {t("exportData.buttons.exportData")}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </Menu.Items>

                        <Menu.Item>
                          <button onClick={handleLogout} className="group flex w-full items-center rounded-md px-2 py-4 text-sm hover:bg-gray-100">
                            <ArrowRightOnRectangleIcon className="w-5 h-5" />
                            <span className="mx-2">{t("buttons.btnLogout")}</span>
                          </button>
                        </Menu.Item>

                        {unSyncCount > 0 && (
                          <Menu.Item>
                            <div className="flex items-center px-2 py-4 hover:bg-gray-100">
                              <ExclamationTriangleIcon className="w-5 h-5" />
                              <p className="text-red-600 text-sm mx-2">{unSyncCount === 1 ? t("errors.unSyncChange", { count: unSyncCount }) : t("errors.unSyncChanges", { count: unSyncCount })}</p>
                            </div>
                          </Menu.Item>
                        )}

                        <Menu.Item>
                          <div dir="ltr" className={"inline-flex w-full items-center px-4 py-2 text-xs leading-4 font-sans"}>
                            App Version <p className="pl-2">v{AppVersion}</p>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};
