import { getLocalizedName, getTKey, t } from "utils/language";
import { Disclosure } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { DateTime } from "luxon";
import axios from "services/axios";
import { toShortISO8086 } from "utils/date";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Modal } from "components/modals/modal";
import { AppLayout } from "components/app/layout";
import { Checkbox } from "components/inputs/checkbox";
import { Input, InputNumber } from "components/inputs/input";
import { Select } from "components/inputs/select";
import { conditions, contraceptives, incomeRange } from "constants/constants";
import { useAppDispatch, useAppSelector } from "hooks";
import registrationService from "services/registration-service";
import { saveMwras } from "slices/db";
import { getDirection, getRegistrationNumber } from "utils/string";
import locationService from "./../../services/location-service";
import { OrderDetail } from "./../../components/cards/order-detail";
import { Table } from "components/app/table";
import { StatsPeriodTypes } from "constants/constants";
import { CustomFilter } from "components/app/filter";
import { ButtonOkay } from "components/buttons/button-ok";
import { RadioInput } from "components/app/radio";

const tKey = getTKey("plw");

interface CRPFormValues {
  clusterCodeId: string;
  crpName: string;
  unionCouncilId: string;
  tehsilId: string;
  districtId: string;
  bastiId: string;
  orderDate: string;
  paymentTrackingId: string;
  orderNo: string;
  products: any;
}
interface ProductData {
  "Product Picture": string;
  productId: string;
  productName: string;
  threshold: number;
  availableQuantity: number;
  stockIn: number;
  stockOut: number;
}

interface Product {
  quantity: number;
  price: number;
  // Add other properties as needed
}
const getDateRange = (view: StatsPeriodTypes) => {
  const currentDate = DateTime.now().toISODate();

  if (view === StatsPeriodTypes.TODAY) {
    return [currentDate, currentDate];
  }

  if (view === StatsPeriodTypes.YESTERDAY) {
    const yesterday = DateTime.now().minus({ days: 1 }).toISODate();
    return [yesterday, yesterday];
  }

  if (view === StatsPeriodTypes.THIS_WEEK) {
    const startOfWeek = DateTime.now().startOf("week").toISODate();
    return [startOfWeek, currentDate];
  }

  if (view === StatsPeriodTypes.LAST_WEEK) {
    const startOfLastWeek = DateTime.now().minus({ weeks: 1 }).startOf("week").toISODate();
    const endOfLastWeek = DateTime.now().minus({ weeks: 1 }).endOf("week").toISODate();
    return [startOfLastWeek, endOfLastWeek];
  }

  if (view === StatsPeriodTypes.THIS_MONTH) {
    const startOfMonth = DateTime.now().startOf("month").toISODate();
    return [startOfMonth, currentDate];
  }

  if (view === StatsPeriodTypes.LAST_MONTH) {
    const startOfLastMonth = DateTime.now().minus({ months: 1 }).startOf("month").toISODate();
    const endOfLastMonth = DateTime.now().minus({ months: 1 }).endOf("month").toISODate();
    return [startOfLastMonth, endOfLastMonth];
  }

  if (view === StatsPeriodTypes.LAST_3_MONTHS) {
    const startOfLast3Months = DateTime.now().minus({ months: 3 }).startOf("month").toISODate();
    return [startOfLast3Months, currentDate];
  }

  if (view === StatsPeriodTypes.THIS_YEAR) {
    const startOfYear = DateTime.now().startOf("year").toISODate();
    return [startOfYear, currentDate];
  }

  if (view === StatsPeriodTypes.LAST_YEAR) {
    const startOfLastYear = DateTime.now().minus({ years: 1 }).startOf("year").toISODate();
    const endOfLastYear = DateTime.now().minus({ years: 1 }).endOf("year").toISODate();
    return [startOfLastYear, endOfLastYear];
  }

  // For CUSTOM_DATE and any other unspecified type, default to the whole year.
  const startOfYear = DateTime.now().startOf("year").toISODate();
  return [startOfYear, currentDate];
};

export const Dashboard = () => {
  const products = useAppSelector(state => state.db.products)

  const [loader, setLoader] = useState<any>(false);

  const [params, setParam] = useSearchParams();

  const [showModal, setShowModal] = useState(false);

  const [stockData, setStockData] = useState<ProductData[]>([]);
  const dir = getDirection();
  const [statsPeriod, setStatsPeriod] = useState<StatsPeriodTypes>(
    (params.get("stats-period") as StatsPeriodTypes) ?? StatsPeriodTypes.THIS_MONTH
  );

  const [customStartDate, setCustomStartDate] = useState(DateTime.now().toISODate());
  const [customEndDate, setCustomEndDate] = useState(DateTime.now().toISODate());

  // Initialize startDate and endDate based on the current date
  const [startDate, setStartDate] = useState(() => {
    return statsPeriod === StatsPeriodTypes.CUSTOM_DATE ? customStartDate : getDateRange(statsPeriod)[0];
  });

  const [endDate, setEndDate] = useState(() => {
    return statsPeriod === StatsPeriodTypes.CUSTOM_DATE ? customEndDate : getDateRange(statsPeriod)[1];
  });

  const productsList = Object.values(products);
  const uniliverProducts = productsList.filter((p) => p.manufacturedId == "11");
  const otherProducts = productsList.filter((p) => p.manufacturedId !== "11");


  useEffect(() => {
    applyDateFilter();
  }, []);


  const handleStatsPeriodChange = (selectedPeriod: StatsPeriodTypes) => {
    setStatsPeriod(selectedPeriod);

    if (selectedPeriod === StatsPeriodTypes.CUSTOM_DATE) {
      // For custom date, set start and end date to current custom values
      setStartDate(customStartDate);
      setEndDate(customEndDate);
    } else {
      // For other periods, get the date range and set start and end date accordingly
      const [newStartDate, newEndDate] = getDateRange(selectedPeriod);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };


  const radioItems = {
    [StatsPeriodTypes.TODAY]: t('viewOrderSummary.modalSummary.radioToday'),
    [StatsPeriodTypes.YESTERDAY]: t('viewOrderSummary.modalSummary.radioYesterday'),
    [StatsPeriodTypes.THIS_WEEK]: t('viewOrderSummary.modalSummary.radioThisWeek'),
    [StatsPeriodTypes.LAST_WEEK]: t('viewOrderSummary.modalSummary.radioLastWeek'),
    [StatsPeriodTypes.THIS_MONTH]: t('viewOrderSummary.modalSummary.radioThisMonth'),
    [StatsPeriodTypes.LAST_MONTH]: t('viewOrderSummary.modalSummary.radioLastMonth'),
    [StatsPeriodTypes.LAST_3_MONTHS]: t('viewOrderSummary.modalSummary.radioLast3Months'),
    [StatsPeriodTypes.THIS_YEAR]: t('viewOrderSummary.modalSummary.radioThisYear'),
    [StatsPeriodTypes.LAST_YEAR]: t('viewOrderSummary.modalSummary.radioLastYear'),
    [StatsPeriodTypes.CUSTOM_DATE]: t('viewOrderSummary.modalSummary.radioSelectDate'),
  };


  //	headers={['Sr.', 'Product Name', 'Stock In','Stock Out', 'Quantity']}

  const renderProductsTBody = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        {uniliverProducts.map((product, index) => (
          <tr className="text-left" key={index}>
            <td className="text-left"><span className="text-sm"><img
              className="w-20 h-20 object-contain rounded"
              src={product.attachments[0].url ?? ""}
              alt={getLocalizedName(product)} // Add alt attribute for accessibility
            /> {getLocalizedName(product)}</span></td>
            <td className="text-left"><span className="text-sm">{product.sku}</span></td>
            {/* <td className="text-center"><span className="text-sm">{product.buying}</span></td>
            <td className="text-center"><span className="text-sm">{product.selling}</span></td> */}
            <td className="text-center"><span className="text-sm">{product.margin.toFixed(2)}</span></td>

          </tr>
        ))}
      </tbody>
    );
  };
  const renderProductsOtherTBody = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        {otherProducts.map((product, index) => (
          <tr className="text-left" key={index}>
            <td className="text-left"><span className="text-sm">   <img
              className="w-20 h-20 object-contain rounded"
              src={product.attachments[0].url ?? ""}
              alt={getLocalizedName(product)} // Add alt attribute for accessibility
            /> {getLocalizedName(product)}</span></td>
            <td className="text-left"><span className="text-sm">{product.sku}</span></td>
            {/* <td className="text-center"><span className="text-sm">{product.buying}</span></td> */}
            {/* <td className="text-center"><span className="text-sm">{product.selling}</span></td> */}
            <td className="text-center"><span className="text-sm">{product.margin.toFixed(2)}</span></td>
          </tr>
        ))}
      </tbody>
    );
  };

  const orders = useAppSelector(state => state.db.requisitions)
  
  const mappedOrders = Object.values(orders).filter((p: any) => {
    const orderDate = DateTime.fromISO(toShortISO8086(p.orderDate))
    switch (statsPeriod) {
      case StatsPeriodTypes.TODAY:
        if (orderDate.hasSame(DateTime.now(), "day")) {
          return p;
        }
        break;
      case StatsPeriodTypes.YESTERDAY:
        if (orderDate.hasSame(DateTime.now().minus({ days: 1 }), "day")) {
          return p;
        }
        break;
      case StatsPeriodTypes.THIS_WEEK:
        if (orderDate >= DateTime.now().startOf("week") && orderDate <= DateTime.now().endOf("week")) {
          return p;
        }
        break;
      case StatsPeriodTypes.LAST_WEEK:
        const lastWeekStart = DateTime.now().minus({ weeks: 1 }).startOf("week");
        const lastWeekEnd = DateTime.now().minus({ weeks: 1 }).endOf("week");
        if (orderDate >= lastWeekStart && orderDate <= lastWeekEnd) {
          return p;
        }
        break;
      case StatsPeriodTypes.THIS_MONTH:
        const startOfMonth = DateTime.now().startOf("month");
        const endOfMonth = DateTime.now().endOf("month");
        if (orderDate >= startOfMonth && orderDate <= endOfMonth) {
          return p;
        }
        break;

      case StatsPeriodTypes.LAST_MONTH:
        const lastMonthStart = DateTime.now().minus({ months: 1 }).startOf("month");
        const lastMonthEnd = DateTime.now().minus({ months: 1 }).endOf("month");
        if (orderDate >= lastMonthStart && orderDate <= lastMonthEnd) {
          return p;
        }
        break;

      case StatsPeriodTypes.LAST_3_MONTHS:
        const last3MonthsStart = DateTime.now().minus({ months: 3 }).startOf("month");
        const last3MonthsEnd = DateTime.now().endOf("month");
        if (orderDate >= last3MonthsStart && orderDate <= last3MonthsEnd) {
          return p;
        }
        break;

      case StatsPeriodTypes.THIS_YEAR:
        const startOfYear = DateTime.now().startOf("year");
        const endOfYear = DateTime.now().endOf("year");
        if (orderDate >= startOfYear && orderDate <= endOfYear) {
          return p;
        }
        break;

      case StatsPeriodTypes.LAST_YEAR:
        const lastYearStart = DateTime.now().minus({ years: 1 }).startOf("year");
        const lastYearEnd = DateTime.now().minus({ years: 1 }).endOf("year");
        if (orderDate >= lastYearStart && orderDate <= lastYearEnd) {
          return p;
        }
        break;

      case StatsPeriodTypes.CUSTOM_DATE:
        // Handle CUSTOM_DATE case
        const saleCustomStartDate = DateTime.fromISO(toShortISO8086(customStartDate));
        const saleCustomEndDate = DateTime.fromISO(toShortISO8086(customEndDate));

        if (orderDate >= saleCustomStartDate && orderDate <= saleCustomEndDate) {
          return p;
        }
        break;

      default:
        // Handle default case
        break;
    }
  });

  let sumManufacturerUniliver = 0,sumManufacturerUniliverInc=0;
  let sumManufacturerOthers = 0,sumManufacturerOthersInc=0;
  mappedOrders.forEach((order: any) => {
    order.orderedProducts.forEach((p: any) => {
      if (p.manufacturerId == 11) {
        sumManufacturerUniliver += p.unitPrice*p.quantity;
        sumManufacturerUniliverInc += p.margin*p.quantity;
      } else {
        sumManufacturerOthers +=  p.unitPrice*p.quantity;
        sumManufacturerOthersInc += p.margin*p.quantity;
      }
    })
  });
  const targets = useAppSelector(state => state.db.targets)
  const targetsList = Object.values(targets);
  const mappedTargets = targetsList.filter((p: any) => {
    const orderDate = DateTime.fromISO(toShortISO8086(p.targetMonth))
    switch (statsPeriod) {
      case StatsPeriodTypes.TODAY:
        if (orderDate.hasSame(DateTime.now(), "day")) {
          return p;
        }
        break;
      case StatsPeriodTypes.YESTERDAY:
        if (orderDate.hasSame(DateTime.now().minus({ days: 1 }), "day")) {
          return p;
        }
        break;
      case StatsPeriodTypes.THIS_WEEK:
        if (orderDate >= DateTime.now().startOf("week") && orderDate <= DateTime.now().endOf("week")) {
          return p;
        }
        break;
      case StatsPeriodTypes.LAST_WEEK:
        const lastWeekStart = DateTime.now().minus({ weeks: 1 }).startOf("week");
        const lastWeekEnd = DateTime.now().minus({ weeks: 1 }).endOf("week");
        if (orderDate >= lastWeekStart && orderDate <= lastWeekEnd) {
          return p;
        }
        break;
      case StatsPeriodTypes.THIS_MONTH:
        const startOfMonth = DateTime.now().startOf("month");
        const endOfMonth = DateTime.now().endOf("month");
        if (orderDate >= startOfMonth && orderDate <= endOfMonth) {
          return p;
        }
        break;

      case StatsPeriodTypes.LAST_MONTH:
        const lastMonthStart = DateTime.now().minus({ months: 1 }).startOf("month");
        const lastMonthEnd = DateTime.now().minus({ months: 1 }).endOf("month");
        if (orderDate >= lastMonthStart && orderDate <= lastMonthEnd) {
          return p;
        }
        break;

      case StatsPeriodTypes.LAST_3_MONTHS:
        const last3MonthsStart = DateTime.now().minus({ months: 3 }).startOf("month");
        const last3MonthsEnd = DateTime.now().endOf("month");
        if (orderDate >= last3MonthsStart && orderDate <= last3MonthsEnd) {
          return p;
        }
        break;

      case StatsPeriodTypes.THIS_YEAR:
        const startOfYear = DateTime.now().startOf("year");
        const endOfYear = DateTime.now().endOf("year");
        if (orderDate >= startOfYear && orderDate <= endOfYear) {
          return p;
        }
        break;

      case StatsPeriodTypes.LAST_YEAR:
        const lastYearStart = DateTime.now().minus({ years: 1 }).startOf("year");
        const lastYearEnd = DateTime.now().minus({ years: 1 }).endOf("year");
        if (orderDate >= lastYearStart && orderDate <= lastYearEnd) {
          return p;
        }
        break;

      case StatsPeriodTypes.CUSTOM_DATE:
        // Handle CUSTOM_DATE case
        const saleCustomStartDate = DateTime.fromISO(toShortISO8086(customStartDate));
        const saleCustomEndDate = DateTime.fromISO(toShortISO8086(customEndDate));

        if (orderDate >= saleCustomStartDate && orderDate <= saleCustomEndDate) {
          return p;
        }
        break;

      default:
        // Handle default case
        break;
    }
  });

  let wellmaTarget = 0, mmsTarget = 0, sanityTarget = 0, unileverTarget = 0, othersTarget = 0;
  mappedTargets.forEach((p: any) => {
      if (p.productId == 1) {
        wellmaTarget = p.target;
      } else if (p.productId == 31) {
        mmsTarget = p.target;
      } else if (p.productId == 3) {
        sanityTarget += p.target;
      }
      else if (p.productId == 1000) {
        unileverTarget += p.target;
      }
      else if (p.productId == 2000) {
        othersTarget += p.target;
      }
  });
  const renderOrderTBody = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        <tr className="text-left">
          <td className="text-left"><span className="text-sm">{t("dashboard.order_placement_unilever")}</span></td>
          <td className="text-left"><span className="text-sm">PKR</span></td>
          <td className="text-center"><span className="text-sm">{unileverTarget.toFixed(2)}</span></td>
          <td className="text-center"><span className="text-sm">{sumManufacturerUniliver.toFixed(2)}</span></td>
          {/* <td className="text-center"><span className="text-sm">10,880</span></td> */}
          <td className="text-center"><span className="text-sm">{sumManufacturerUniliverInc.toFixed(2)}</span></td>

        </tr>
        <tr className="text-left">
          <td className="text-left"><span className="text-sm">{t("dashboard.order_placement_other")}</span></td>
          <td className="text-left"><span className="text-sm">PKR</span></td>
          <td className="text-center"><span className="text-sm">{othersTarget.toFixed(2)}</span></td>

          <td className="text-center"><span className="text-sm">{sumManufacturerOthers.toFixed(2)}</span></td>
          {/* <td className="text-center"><span className="text-sm">12,957</span></td> */}
          <td className="text-center"><span className="text-sm">{sumManufacturerOthersInc.toFixed(2)}</span></td>

        </tr>
        <tr className="text-left">
          <td className="text-left"><span className="text-sm"><b>{t("dashboard.total")}</b></span></td>
          <td className="text-left"><span className="text-sm"><b>PKR</b></span></td>
          <td className="text-center"><span className="text-sm"><b>{(unileverTarget + othersTarget).toFixed(2)}</b></span></td>
          <td className="text-center"><span className="text-sm"><b>{(sumManufacturerOthers + sumManufacturerUniliver).toFixed(2)}</b></span></td>
          {/* <td className="text-center"><span className="text-sm"><b>23,837</b></span></td> */}
          <td className="text-center"><span className="text-sm"><b>{(sumManufacturerOthersInc + sumManufacturerUniliverInc).toFixed(2)}</b></span></td>

        </tr>


      </tbody>
    );
  };


  const visits = useAppSelector(state => state.db.sales)

  const registrations = useAppSelector(state => state.db.mwras)
 
  const registrationsList = Object.values(registrations);
  
  const mappedVisits = Object.values(visits)
    .reduce((agg, sale) => {
      const saleDate = DateTime.fromISO(toShortISO8086(sale.saleDate));

      switch (statsPeriod) {
        case StatsPeriodTypes.TODAY:
          if (saleDate.hasSame(DateTime.now(), "day")) {
            return [...agg, sale];
          }
          break;
        case StatsPeriodTypes.YESTERDAY:
          if (saleDate.hasSame(DateTime.now().minus({ days: 1 }), "day")) {
            return [...agg, sale];
          }
          break;
        case StatsPeriodTypes.THIS_WEEK:
          if (saleDate >= DateTime.now().startOf("week") && saleDate <= DateTime.now().endOf("week")) {
            return [...agg, sale];
          }
          break;
        case StatsPeriodTypes.LAST_WEEK:
          const lastWeekStart = DateTime.now().minus({ weeks: 1 }).startOf("week");
          const lastWeekEnd = DateTime.now().minus({ weeks: 1 }).endOf("week");
          if (saleDate >= lastWeekStart && saleDate <= lastWeekEnd) {
            return [...agg, sale];
          }
          break;
        case StatsPeriodTypes.THIS_MONTH:
          const startOfMonth = DateTime.now().startOf("month");
          const endOfMonth = DateTime.now().endOf("month");
          if (saleDate >= startOfMonth && saleDate <= endOfMonth) {
            return [...agg, sale];
          }
          break;

        case StatsPeriodTypes.LAST_MONTH:
          const lastMonthStart = DateTime.now().minus({ months: 1 }).startOf("month");
          const lastMonthEnd = DateTime.now().minus({ months: 1 }).endOf("month");
          if (saleDate >= lastMonthStart && saleDate <= lastMonthEnd) {
            return [...agg, sale];
          }
          break;

        case StatsPeriodTypes.LAST_3_MONTHS:
          const last3MonthsStart = DateTime.now().minus({ months: 3 }).startOf("month");
          const last3MonthsEnd = DateTime.now().endOf("month");
          if (saleDate >= last3MonthsStart && saleDate <= last3MonthsEnd) {
            return [...agg, sale];
          }
          break;

        case StatsPeriodTypes.THIS_YEAR:
          const startOfYear = DateTime.now().startOf("year");
          const endOfYear = DateTime.now().endOf("year");
          if (saleDate >= startOfYear && saleDate <= endOfYear) {
            return [...agg, sale];
          }
          break;

        case StatsPeriodTypes.LAST_YEAR:
          const lastYearStart = DateTime.now().minus({ years: 1 }).startOf("year");
          const lastYearEnd = DateTime.now().minus({ years: 1 }).endOf("year");
          if (saleDate >= lastYearStart && saleDate <= lastYearEnd) {
            return [...agg, sale];
          }
          break;

        case StatsPeriodTypes.CUSTOM_DATE:
          // Handle CUSTOM_DATE case
          const saleCustomStartDate = DateTime.fromISO(toShortISO8086(customStartDate));
          const saleCustomEndDate = DateTime.fromISO(toShortISO8086(customEndDate));

          if (saleDate >= saleCustomStartDate && saleDate <= saleCustomEndDate) {
            return [...agg, sale];
          }
          break;

        default:
          // Handle default case
          break;
      }

      return agg;
    }, [] as Sale[])
    .reduce(
      (agg, sale) => {
        return {
          ...agg,
          [sale.id]: sale,
        };
      },
      {} as { [sid: ID]: Sale }
    );

  const mappedVisitsList = Object.values(mappedVisits);

  let wellmaSalesQty = 0;
  let wellmaSalesAmt = 0;
  let wellmaSalesInc = 0;
  let mmsSalesQty = 0, mmsSalesAmt = 0 , mmsSalesInc = 0;
  let sanitySalesQty = 0, sanitySalesAmt = 0, sanitySalesInc = 0;
  mappedVisitsList.forEach((sale: any) => {
    sale.saleItems.forEach((p: any) => {
      if (p.productId == 1) {
        wellmaSalesQty += p.quantity;
        wellmaSalesAmt += p.amount;
        wellmaSalesInc +=  (p.margin/100)*p.quantity;
      } else if (p.productId == 31) {
        mmsSalesQty += p.quantity;
        mmsSalesAmt += p.amount;
        mmsSalesInc +=  (p.margin/100)*p.quantity;
      } else if (p.productId == 3) {
        sanitySalesQty += p.quantity;
        sanitySalesAmt += p.amount;
        sanitySalesInc += (p.margin/100)*p.quantity;
      }
    })
  });

  

  const renderTBody = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        <tr className="text-left">
          <td className="text-left"><span className="text-sm">{t("dashboard.wellma")}</span></td>
          <td className="text-left"><span className="text-sm">{t("dashboard.sachet")}</span></td>
          <td className="text-center"><span className="text-sm">{wellmaTarget}</span></td>
          <td className="text-center"><span className="text-sm">{wellmaSalesQty.toFixed(2)}</span></td>
          <td className="text-center"><span className="text-sm">{wellmaSalesAmt.toFixed(2)}</span></td>
          <td className="text-center"><span className="text-sm">{wellmaSalesInc.toFixed(2)}</span></td>
        </tr>
        <tr className="text-left">
          <td className="text-left"><span className="text-sm">{t("dashboard.mms")}</span></td>
          <td className="text-left"><span className="text-sm">{t("dashboard.bottle")}</span></td>
          <td className="text-center"><span className="text-sm">{mmsTarget}</span></td>
          <td className="text-center"><span className="text-sm">{mmsSalesQty.toFixed(2)}</span></td>
          <td className="text-center"><span className="text-sm">{mmsSalesAmt.toFixed(2)}</span></td>
          <td className="text-center"><span className="text-sm">{mmsSalesInc.toFixed(2)}</span></td>
        </tr>
        <tr className="text-left">
          <td className="text-left"><span className="text-sm">{t("dashboard.napkin")}</span></td>
          <td className="text-left"><span className="text-sm">{t("dashboard.units")}</span></td>
          <td className="text-center"><span className="text-sm">{sanityTarget}</span></td>
          <td className="text-center"><span className="text-sm">{sanitySalesQty.toFixed(2)}</span></td>
          <td className="text-center"><span className="text-sm">{sanitySalesAmt.toFixed(2)}</span></td>
          <td className="text-center"><span className="text-sm">{sanitySalesInc.toFixed(2)}</span></td>
        </tr>
        <tr className="text-left">
          <td className="text-left"><span className="text-sm "><b>{t("dashboard.total_income")}</b></span></td>
          <td className="text-left "><span className="text-sm">-</span></td>
          <td className="text-center"><span className="text-sm">-</span></td>
          <td className="text-center"><span className="text-sm">-</span></td>
          <td className="text-center "><span className="text-sm"><b>{(wellmaSalesAmt+mmsSalesAmt+sanitySalesAmt).toFixed(2)}</b></span></td>
          <td className="text-center"><span className="text-sm"><b>{(wellmaSalesInc+mmsSalesInc+sanitySalesInc).toFixed(2)}</b></span></td>
        </tr>
      </tbody>
    );
  };
  const uniqueMwraids = mappedVisitsList.map(p => p.mwraId)  // Extract mwraid from each object
    .filter((value, index, self) => self.indexOf(value) === index);  // Keep only unique values

  var percent = (uniqueMwraids.length / registrationsList.length * 100).toFixed(2);
  const renderHHTBody = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        <tr className="text-left">
          <td className="text-left"><span className="text-sm">{t("dashboard.household_visits")}</span></td>
          <td className="text-center"><span className="text-sm">{registrationsList.length}</span></td>
          <td className="text-center"><span className="text-sm">{uniqueMwraids.length}</span></td>
          <td className="text-center"><span className="text-sm">{percent}%</span></td>
          <td className="text-center"><span className="text-sm">{mappedVisitsList.length}</span></td>
        </tr>

      </tbody>
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const applyDateFilter = () => {

    let startDateToUse: string | undefined;
    let endDateToUse: string | undefined;

    // Check if the custom date range is selected
    if (statsPeriod === StatsPeriodTypes.CUSTOM_DATE) {
      // Use custom date range states
      startDateToUse = customStartDate;
      endDateToUse = customEndDate;

      // Check if the custom date range is valid
      if (startDateToUse > endDateToUse || endDateToUse > DateTime.now().toISODate()) {
        // Handle invalid date range (you can show a message or take appropriate action)
        return;
      }
    } else {
      // Use the previous date range states
      [startDateToUse, endDateToUse] = getDateRange(statsPeriod);
    }

    // Filter orderDetail based on the selected date range
    // fetchData(startDateToUse, endDateToUse);
    // Update the filtered order details state

    // Close the modal
    closeModal();
  };



  const { profile: crp } = useAppSelector((state) => state.auth);
  const { connected: connected } = useAppSelector((state) => state.db);
  const mwras = useAppSelector((state) => state.db.mwras);

  return (
    <AppLayout showHeader title={t("edit.labels.mwraDetails")}>
      <div className={clsx("flex space-x-1 items-center mb-2")}>
        <CustomFilter title={radioItems[statsPeriod] ?? t("viewOrderSummary.modalSummary.radioWeek")} onClick={() => setShowModal(true)} />
      </div>
    
      {/* Problem here */}
      {(true || stockData.length > 0) && (<div dir={dir} className="w-full">
        <h4><b>{t("dashboard.performance_target_vs_achievement")}</b></h4>
        <h4><b>{t("dashboard.sales")}</b></h4>
        <div className="flex-col border border-gray-200 divide-y divide-gray-200 bg-white rounded-md w-full px-2 py-3s space-y-1 mt-2">
          {!loader ? <Table headers={[t("dashboard.indicator"), t("dashboard.uom"), t("dashboard.target"), t("dashboard.achievement"), t("dashboard.sales"), t("dashboard.profit")]} renderComponent={renderTBody} isReport={true} /> : (null)
          }



        </div>


        <br />
        <h4><b>{t("dashboard.order_placement")}</b></h4>
        <Table headers={[t("dashboard.indicator"), t("dashboard.uom"), t("dashboard.target"), t("dashboard.achievement"), t("dashboard.expected_income")]} renderComponent={renderOrderTBody} isReport={true} />
        <br />
        <h3><b>{t("dashboard.household_visits")}</b></h3>
        <Table headers={[t("dashboard.indicator"),  t("dashboard.target_unique_household"), t("dashboard.unique_household_visited"), t("dashboard.percentAchieved"), t("dashboard.noOfVisits")]} renderComponent={renderHHTBody} isReport={true} />   <br />

        <h3><b>{t("dashboard.product_catalogue_high")}</b></h3>
        <Table headers={[t("dashboard.product"), t("dashboard.sku"), t("dashboard.income")]} renderComponent={renderProductsOtherTBody} isReport={true} />
        <br></br>
        <h3><b>{t("dashboard.product_catalogue_unilever")}</b></h3>
        <Table headers={[t("dashboard.product"), t("dashboard.sku"), t("dashboard.income")]} renderComponent={renderProductsTBody} isReport={true} />
        <br></br>
      </div>

      )}  {showModal && (
        <Modal title={t("viewOrderSummary.pickDateRange")} onClose={closeModal}>
          <div dir={dir} className="w-full bg-white p-4">
            <div className="">
              <div className="space-y-2 mt-4">
                {Object.entries(radioItems).map(([id, labelText], index) => (
                  <RadioInput
                    key={index}
                    onChange={() => {
                      setParam({ "stats-period": id }, { replace: true });
                      handleStatsPeriodChange(id as StatsPeriodTypes);
                    }}
                    checked={statsPeriod === id}
                    labelText={labelText}
                  />
                ))}
                <ButtonOkay onClick={applyDateFilter} title={t("buttons.okay")} />
              </div>
              {statsPeriod === StatsPeriodTypes.CUSTOM_DATE && (
                <div dir={dir} className={clsx("flex-col space-y-2 px-2 text-sm mt-4")}>
                  <Input labelText="Start Date" type="date" name="customStartDate" onChange={(event) => setCustomStartDate(event.target.value)} value={customStartDate} />
                  <Input labelText="End Date" type="date" name="customEndDate" onChange={(event) => setCustomEndDate(event.target.value)} value={customEndDate} />
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}

    </AppLayout>
  );
};
