import clsx from 'clsx'

import { CheckIcon } from 'assets/icons'

interface ButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	children?: React.ReactNode
}

export const CheckButton: React.FC<ButtonProps> = ({ children, className, ...props }) => {
	return (
		<button
			{...props}
			className={clsx(
				className,
				'inline-flex items-center rounded-full h-20 w-20 bg-[#04C882] justify-center'
			)}>
			<img className="w-12 h-10" src={CheckIcon} />
		</button>
	)
}
