import { CheckIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import cond from "cond-construct";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Control, Controller, FieldErrors, UseFormRegister, UseFormResetField, UseFormSetValue, UseFormWatch, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import * as yup from "yup";

import { BabyboyIcon, BabygirlIcon, CheckmarkIcon, CrossIcon, LactatingIcon, ManIcon, PregnantIcon, WomanIcon } from "assets/icons";
import { Label } from "components/app/label";
import { AppLayout } from "components/app/layout";
import { Speaker } from "components/audio-player";
import { InputFieldError } from "components/error/field-error";
import { CounterDecimalInput, CounterInput } from "components/inputs/counter";
import { Input, InputNumber } from "components/inputs/input";
import { Select } from "components/inputs/select";
import { Modal } from "components/modals/modal";
import { WomanConditionTypes, contraceptives, incomeRange } from "constants/constants";
import { useAppDispatch, useAppSelector } from "hooks";
import { saveMwras, updateLastLocalAutoId } from "slices/db";
import { addMWRA } from "slices/queued";
import clsx from "utils/clsx";
import { getLocalizedName, getTKey, t } from "utils/language";
import { getDirection, getYearsPassed } from "utils/string";

import registrationService from "services/registration-service";

enum FormSteps {
  PERSONAL = 1,
  CONTACT,
  FAMILY,
  CHILDREN_COUNT,
  TWO_YEAR_CHILDREN,
  MUAC_CHILDREN,
  INCOME,
  BISP,
  WOMAN_MUAC,
  STATUS,
  DELIVERY_DATE,
  ANC,
  AGE_OF_CHILD_BEING_BREASTFED,
  CONTRACEPTIVE,
  CONTRACEPTIVE_TYPE,
  SUMMARY,
  COMPLETE,
}

type StateType = {
  step: FormSteps;
  displayExitModal: boolean;
  stepsTaken: number[];
};

const tKey = getTKey("plw");
let finalData: any = {};
export const CreateMwra = () => {
  const [submitData, setSubmitData] = useState<any>(null);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { profile: user } = useAppSelector((state) => state.auth);
  const registeredMWRAs = useAppSelector((state) => state.db.mwras);
  let lastLocalAutoId = useAppSelector((state) => state.db.lastLocalAutoId);

  useEffect(() => {
    registrationService.getMWRAsByCRP(user.id).then((response) => dispatch(saveMwras(response.data)));
  }, [user]);

  function getBastiName(bastiId: any) {
    console.log("Basti ID:", bastiId);
    console.log("All Bastis:", user.cluster.bastis);

    // Use debugger to pause execution and inspect variables

    const basti = user.cluster.bastis.find((item: any) => parseInt(item.id) === bastiId);
    console.log("Found Basti:", basti);

    return basti ? basti.nameEn : null;
  }

  // If the users never logout or clean cache
  // etc local auto id would be in sequence starting
  // from 0 to upto N -- N is the number of registrations
  //
  // What if users logout or clean the cache, how to know
  // what would be the next local auto id?
  //
  // In this case lastLocalAutoId would be 0 and there would
  // be mwras registered.
  //
  // We can sort the mwras with local auto id in
  // descending order and pick the first mwra local auto id
  // and use it as lastLocalAutoId

  // Solution A
  if (lastLocalAutoId === 0 && Object.keys(registeredMWRAs).length > 0) {
    const [lastRegisteredMWRA] = Object.values(registeredMWRAs).sort((a, b) => (b.localAutoId ?? 0) - (a.localAutoId ?? 0));

    lastLocalAutoId = lastRegisteredMWRA.localAutoId ?? 0;
  }

  // TODO: Handle case when registered MWRAs not loaded - There's
  // chance of duplication in this case
  //
  // TODO: Store serial number in the CRP profile at some point
  // If we had setup profile edits of CRP, it would save our life

  const [state, setState] = useState<StateType>({
    step: FormSteps.PERSONAL,
    displayExitModal: false,
    stepsTaken: [],
  });
  const schema = yup.object({
    name: yup
      .string()
      .required(t("errors.required", { field: t(tKey("labels.name")) }))
      .matches(/^[^\d\p{P}\p{S}]*$/u, t("errors.invalid", { field: t(tKey("labels.name")) })),
    age: yup
      .number()
      .typeError(t("errors.invalid", { field: t(tKey("labels.age")) }))
      .test("is-valid-age", "is-valid-age", function (value) {
        const birthMonth = this.parent.birthMonth;
        const birthYear = this.parent.birthYear;
        const age = getYearsPassed(birthMonth, birthYear);
        value = age;

        if (!value) {
          return this.createError({
            path: "age",
            message: t("errors.invalid", { field: t(tKey("labels.age")) }),
          });
        } else if (value < 13) {
          return this.createError({
            path: "age",
            message: t("errors.ageLessThan", { age: 13 }),
          });
        } else if (value > 49) {
          return this.createError({
            path: "age",
            message: t("errors.ageMoreThan", { age: 49 }),
          });
        }

        return true;
      }),
    birthMonth: yup
      .number()
      .required(t("errors.required", { field: t(tKey("labels.month")) }))
      .typeError(t("errors.invalid", { field: t(tKey("labels.month")) })),
    birthYear: yup
      .number()
      .required(t("errors.required", { field: t(tKey("labels.year")) }))
      .typeError(t("errors.invalid", { field: t(tKey("labels.year")) })),
    condition: yup.string().when("$step", {
      is: FormSteps.STATUS,
      then: (schema) => schema.required(t("errors.required", { field: t(tKey("labels.condition")) })),
    }),
    husbandName: yup.string().when("$step", {
      is: FormSteps.CONTACT,
      then: (schema) => schema.required(t("errors.required", { field: t(tKey("labels.husband")) })),
    }),
    phone: yup.string().when("$step", {
      is: FormSteps.CONTACT,
      then: (schema) =>
        schema
          .required(t("errors.required", { field: t(tKey("labels.contactNumber")) }))
          .length(11, t("errors.phoneLength", { value: "11" }))
          .max(11, t("errors.phoneMoreThan", { value: "11" })),
    }),
    bastiId: yup.number().when("$step", {
      is: FormSteps.CONTACT,
      then: (schema) => schema.required(t("errors.required", { field: t(tKey("labels.basti")) })),
    }),
    totalFamilyMembers: yup
      .number()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when("$step", {
        is: FormSteps.FAMILY,
        then: (schema) =>
          schema
            .typeError(t("errors.invalid", { field: t(tKey("labels.familyMembers")) }))
            .required(t("errors.required", { field: t(tKey("labels.familyMemberRequired")) }))
            .min(2, t("errors.leastFamilyLength", { value: "2" }))
            .max(20, t("errors.familyMoreThan", { value: "20" })),
        otherwise: (schema) => schema.nullable(),
      }),
    familyIncome: yup.string().when("$step", {
      is: FormSteps.INCOME,
      then: (schema) => schema.required(t("errors.mandatorySelection")),
    }),
    isBispMember: yup.boolean().when("$step", {
      is: FormSteps.BISP,
      then: (schema) => schema.required(t("errors.mandatorySelection")),
    }),
    isBispNashonuma: yup.boolean().when(["$step", "isBispMember"], {
      is: (step: number, isBispMember: boolean) => step === FormSteps.BISP && isBispMember,
      then: (schema) => schema.required(t("errors.mandatorySelection")),
    }),

    expectedDeliveryDate: yup.string().when("$step", {
      is: FormSteps.DELIVERY_DATE,
      then: (schema) => schema.required(t("errors.required", { field: t(tKey("labels.expectedMonth")) })),
    }),
    isMeetingWithAnc: yup.boolean().when("$step", {
      is: FormSteps.ANC,
      then: (schema) => schema.required(t("errors.mandatorySelection")),
    }),
    breastfeedingChildAge: yup
      .number()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when("$step", {
        is: FormSteps.AGE_OF_CHILD_BEING_BREASTFED,
        then: (schema) =>
          schema
            .typeError(t("errors.invalid", { field: t(tKey("labels.breastfedRequired")) }))
            .required(t("errors.required", { field: t(tKey("labels.breastfedRequired")) }))
            .max(6, t("errors.ageMoreThan", { age: "6 months" })),
        otherwise: (schema) => schema.nullable(),
      }),
    muac: yup
      .number()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when("$step", {
        is: FormSteps.WOMAN_MUAC,
        then: (schema) =>
          schema
            .typeError(t("errors.invalid", { field: t(tKey("labels.MUACWomanRequired")) }))
            .required(t("errors.required", { field: t(tKey("labels.MUACWomanRequired")) }))
            .min(15, t("errors.lessThan", { value: 15 }))
            .max(50, t("errors.moreThan", { value: 50 })),
        otherwise: (schema) => schema.nullable(),
      }),
    totalChildren: yup
      .number()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when("$step", {
        is: FormSteps.CHILDREN_COUNT,
        then: (schema) =>
          schema
            .typeError(t("errors.invalid", { field: t(tKey("labels.children")) }))
            .required(t("errors.required", { field: t(tKey("labels.children")) }))
            .max(20, t("errors.moreThan", { value: 20 })),
        otherwise: (schema) => schema.nullable(),
      }),
    // malesUnderTwo: yup
    //   .number()
    //   .transform((val) => (isNaN(val) ? undefined : val))
    // .when(["$step", "totalChildren"], ([step, totalChildren, femalesUnderTwo], schema) => {
    //   debugger;
    //   console.log("fif",finalData)
    //   return step === FormSteps.TWO_YEAR_CHILDREN
    //     ? schema
    //         .typeError(t("errors.invalid", { field: t(tKey("labels.maleChildrenUnderTwoRequired")) }))
    //         .required(t("errors.required", { field: t(tKey("labels.maleChildrenUnderTwoRequired")) }))
    //         .max(totalChildren - (femalesUnderTwo ?? 0), t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }))
    //     : schema.nullable();
    // }),
    // femalesUnderTwo: yup
    //   .number()
    //   .transform((val) => (isNaN(val) ? undefined : val))
    //   .when(["$step", "totalChildren"], ([step, totalChildren, malesUnderTwo], schema) => {
    //     return step === FormSteps.TWO_YEAR_CHILDREN
    //       ? schema
    //           .typeError(t("errors.invalid", { field: t(tKey("labels.femaleChildrenUnderTwoRequired")) }))
    //           .required(t("errors.required", { field: t(tKey("labels.femaleChildrenUnderTwoRequired")) }))
    //           .max(totalChildren - (malesUnderTwo ?? 0), t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }))
    //       : schema.nullable();
    malesUnderTwo: yup
      .number()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when(["$step", "totalChildren"], ([step, totalChildren, femalesUnderTwo], schema) => {
        console.log("fif", finalData);
        return step === FormSteps.TWO_YEAR_CHILDREN
          ? schema
              .typeError(t("errors.invalid", { field: t(tKey("labels.maleChildrenUnderTwoRequired")) }))
              .required(t("errors.required", { field: t(tKey("labels.maleChildrenUnderTwoRequired")) }))
              .max(totalChildren - (femalesUnderTwo ?? 0), t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }))
          : schema.nullable();
      })
      .test("is-valid-count", "Sum of malesUnderTwo and femalesUnderTwo cannot exceed total children", function (value) {
        const { step, totalChildren, femalesUnderTwo } = this.parent;

        const malesUnderTwo = value ?? 0;
        const parsedFemalesUnderTwo = parseInt(femalesUnderTwo) || 0; // Convert femalesUnderTwo to integer

        if (malesUnderTwo + parsedFemalesUnderTwo > totalChildren) {
          return this.createError({
            path: "malesUnderTwo",
            message: t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }),
          });
        }
        else if (malesUnderTwo  > totalChildren) {
          return this.createError({
            path: "malesUnderTwo",
            message: t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }),
          });
        }
        else{

          return true;
        }


      }),
    femalesUnderTwo: yup
      .number()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when(["$step", "totalChildren"], ([step, totalChildren, femalesUnderTwo], schema) => {
        console.log("fif", finalData);
        return step === FormSteps.TWO_YEAR_CHILDREN
          ? schema
              .typeError(t("errors.invalid", { field: t(tKey("labels.maleChildrenUnderTwoRequired")) }))
              .required(t("errors.required", { field: t(tKey("labels.maleChildrenUnderTwoRequired")) }))
              .max(totalChildren - (femalesUnderTwo ?? 0), t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }))
          : schema.nullable();
      })
      .test("is-valid-count", "Sum of malesUnderTwo and femalesUnderTwo cannot exceed total children", function (value) {
        const { step, totalChildren, malesUnderTwo } = this.parent;

        const femalesUnderTwo = value ?? 0;
        const parsedMalesUnderTwo = parseInt(malesUnderTwo) || 0; // Convert malesUnderTwo to integer

        if (femalesUnderTwo + parsedMalesUnderTwo > totalChildren) {
          return this.createError({
            path: "femalesUnderTwo",
            message: t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }),
          });
        }
        else if (femalesUnderTwo  > totalChildren) {
          return this.createError({
            path: "femalesUnderTwo",
            message: t("errors.moreThan", { value: t(tKey("labels.totalChildren")) }),
          });
        }
        else{

          return true;
        }

      }),
    muacChildren: yup
      .array()

      .when(["$step", "totalChildren"], ([step, totalChildren], schema) => {
        if (step === FormSteps.MUAC_CHILDREN) {
          return schema
            .of(
              yup.object().shape({
                name: yup.string().required(t("errors.required", { field: t(tKey("labels.childName")) })),
                age: yup
                  .number()
                  .typeError(t("errors.invalid", { field: t(tKey("labels.muac_age")) }))
                  .max(59, t("errors.moreThan", { value: "59" }))
                  .default(6), // Set default age to 6
                muac: yup
                  .number()
                  .typeError(t("errors.invalid", { field: t(tKey("labels.lowMUACChildren")) }))
                  .max(11.5, t("errors.moreThan", { value: "11.5" })),
              })
            )
            .max(totalChildren, t("errors.exceedsSum"));
        }
        return schema;
      }),
    isFamilyPlanning: yup.boolean().when("$step", {
      is: FormSteps.CONTRACEPTIVE,
      then: (schema) => schema.required(t("errors.required", { field: t(tKey("labels.familyPlanningRequired")) })),
    }),

    contraceptiveMethods: yup.array().when("$step", {
      is: FormSteps.CONTRACEPTIVE_TYPE,
      then: (schema) => schema.required(t("errors.required", { field: t(tKey("labels.familyPlanningMethodsRequired")) })).min(1, t("errors.lessThan", { value: "1" })),
    }),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
    resetField,
    control,
  } = useForm<MWRARegistrationForm>({
    resolver: yupResolver(schema as any),
    context: { step: state.step },
    defaultValues: {
      birthMonth: "",
      birthYear: "",
      age: undefined,
      condition: undefined,
      totalFamilyMembers: 2,
      muac: undefined,
      familyIncome: undefined,
      malesUnderTwo: 0,
      femalesUnderTwo: 0,
      totalChildren: 0,
      breastfeedingChildAge: undefined,
      registrationDate: DateTime.now().toISO(),
      clusterId: user.cluster.id,
      crpId: user.id,
      localAutoId: lastLocalAutoId + 1,
    },
    mode: "all",
  });

  const renderComponent = cond([
    [state.step === FormSteps.PERSONAL, () => <PersonalDetailsForm {...{ errors, register, control, watch, setValue }} />],
    [state.step === FormSteps.CONTACT, () => <ContactForm bastis={user.cluster.bastis} {...{ errors, register, control }} />],
    [state.step === FormSteps.FAMILY, () => <FamilyMembersForm {...{ errors, register, control }} />],
    [state.step === FormSteps.CHILDREN_COUNT, () => <TotalChildrenForm {...{ errors, register, control }} />],
    [state.step === FormSteps.TWO_YEAR_CHILDREN, () => <ChildrenUnderTwoForm {...{ errors, register, control }} />],
    [state.step === FormSteps.MUAC_CHILDREN, () => <MUACChildrenForm {...{ errors, register, control }} totalChildren={getValues().totalChildren} />],
    [state.step === FormSteps.INCOME, () => <IncomeForm {...{ errors, register, control }} />],
    [state.step === FormSteps.BISP, () => <BISPForm {...{ errors, register, control, watch }} />],
    [state.step === FormSteps.WOMAN_MUAC, () => <MUACForm {...{ errors, register, control }} />],
    [state.step === FormSteps.STATUS, () => <WomanConditionSelectionForm {...{ errors, register, control }} />],
    [state.step === FormSteps.DELIVERY_DATE, () => <DeliveryDateForm errors={errors} register={register} control={control} resetField={resetField} />],
    [state.step === FormSteps.ANC, () => <ANCForm {...{ errors, register, control }} />],
    [state.step === FormSteps.AGE_OF_CHILD_BEING_BREASTFED, () => <BreastfeedingChildForm {...{ errors, register, control }} />],
    [state.step === FormSteps.CONTRACEPTIVE, () => <ContraceptiveUseForm {...{ errors, register, control }} />],
    [state.step === FormSteps.CONTRACEPTIVE_TYPE, () => <ContraceptivesForm {...{ errors, register, control }} />],
    [state.step === FormSteps.SUMMARY, () => <SummaryForm {...{ errors, register, control, getBastiName }} />],
  ]);

  const nextStep = handleSubmit((data) => {
    // finalData = data
    finalData = {
      ...data,
      childrenInfo: {
        totalChildren: data.totalChildren,
        malesUnderTwo: data.malesUnderTwo,
        muacChildren: data.muacChildren,
        breastfeedingChildAge: data.breastfeedingChildAge,
        femalesUnderTwo: data.femalesUnderTwo,
      },
    };
    {
      console.log(data, "finalData");
    }

    let numberOfSteps = 1;
    switch (state.step) {
      case FormSteps.STATUS:
        numberOfSteps = data.condition === WomanConditionTypes.PREGNANT ? 1 : data.condition === WomanConditionTypes.LACTATING ? 3 : 4;
        break;
      case FormSteps.ANC:
        numberOfSteps = data.condition === WomanConditionTypes.LACTATING ? 1 : 4;

        break;
      case FormSteps.CHILDREN_COUNT:
        numberOfSteps = data.totalChildren > 0 ? 1 : 3;
        // if (data.totalChildren === 0 && data.condition === WomanConditionTypes.PREGNANT) {
        // 	onSubmit()
        // 	return
        // }
        break;
      case FormSteps.MUAC_CHILDREN:
        numberOfSteps = data.condition === WomanConditionTypes.PREGNANT ? 3 : 1;

        // if (data.condition === WomanConditionTypes.PREGNANT) {
        // 	onSubmit()
        // 	return
        // }
        break;
      case FormSteps.CONTRACEPTIVE:
        numberOfSteps = data.isFamilyPlanning ? 1 : 2;
        // if (!data.isFamilyPlanning) {
        // 	onSubmit()
        // 	return
        // }
        break;

      case FormSteps.SUMMARY:
        onSubmit();
        return;
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      step: prevState.step + numberOfSteps,
      stepsTaken: [...state.stepsTaken, numberOfSteps],
    }));
  });

  const previousStep = () => {
    if (state.step === 1) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      step: prevState.step - state.stepsTaken[state.stepsTaken.length - 1],
      stepsTaken: state.stepsTaken.filter((number, index) => {
        return index !== state.stepsTaken.length - 1;
      }),
    }));
  };
  const handleSubmitConfirm = () => {
    const data = submitData;
    setSuccessModal(false);
    const mwraForm: MWRARegistrationForm = {
      ...data,
      childrenInfo: {
        totalChildren: data.totalChildren,
        malesUnderTwo: data.malesUnderTwo,
        muacChildren: data.muacChildren,
        breastfeedingChildAge: data.breastfeedingChildAge,
        femalesUnderTwo: data.femalesUnderTwo,
      },
    };
    console.log(mwraForm, "mwraForm");
    // return;
    dispatch(addMWRA(mwraForm));
    // Store the new last local auto id
    dispatch(updateLastLocalAutoId(lastLocalAutoId + 1));

    setState({ ...state, step: FormSteps.COMPLETE });
  };

  const onSubmit = handleSubmit((data) => {
    setSuccessModal(true);
    setSubmitData(data);
    // handleSubmitConfirm(data)
  });

  const dir = getDirection();

  return (
    /* eslint-disable no-restricted-globals */

    <AppLayout showHeader showCrossIcon onBackArrowPress={() => (state.step !== FormSteps.COMPLETE && state.step !== FormSteps.PERSONAL ? previousStep() : history.back())} onCrossPress={() => setState({ ...state, displayExitModal: true })}>
      <div dir={dir}>
        <form
          onSubmit={(event) => {
            if (state.step === FormSteps.SUMMARY) {
              onSubmit(event);
            } else {
              nextStep(event);
            }
          }}
          className="space-y-4 w-full font-bold"
        >
          <>{renderComponent}</>

          {state.step < FormSteps.COMPLETE && (
            <div className="py-2">
              <button type="submit" className="p-4 max-w-max mx-auto  flex flex-row justify-between bg-green-500 text-white rounded-full ">
                <CheckIcon className="h-10" />
              </button>

              {state.step - 2 == 13 || state.step - 2 == 16 ? <h1 className="text-center mt-4">Confirm and Submit</h1> : <h1 className="text-center mt-4">{t("buttons.next")}</h1>}
            </div>
          )}
          <div className="py-2 p-4 max-w-max mx-auto  flex flex-row justify-between" style={{ position: "absolute", bottom: "10px", left: "50%", transform: "translateX(-50%)" }}>
            {state.step < 11 ? (
              <p>{state.step !== FormSteps.COMPLETE && state.step !== FormSteps.SUMMARY ? (finalData.condition === "pregnant" ? `${state.step} of 12` : `${state.step} of 16`) : null}</p>
            ) : (
              <p>
                {state.step !== FormSteps.COMPLETE && state.step !== FormSteps.SUMMARY
                  ? finalData.condition === "pregnant"
                    ? `${state.step} of 12`
                    : finalData.condition === "lactating"
                    ? `${state.step - 2} of 13`
                    : `${state.step - 3} of 12`
                  : null}
              </p>
            )}{" "}
          </div>
        </form>
      </div>
      {state.step === FormSteps.COMPLETE && (
        <div className="flex justify-center items-center h-full">
          <div className="space-y-4 mt-36">
            <div className="flex items-center">
              <h1 dir={dir}> {t("plw.labels.success")}</h1>
              <Speaker filename="mwraRegisterationSubmitted.mp3" />
            </div>
            <button
              onClick={(event) => {
                event.preventDefault();
                history.back();
              }}
              className="p-4 max-w-max mx-auto flex justify-between bg-green-500 text-white rounded-full "
            >
              <CheckIcon className="h-10" />
            </button>
          </div>
        </div>
      )}
      {state.displayExitModal && (
        <Modal>
          <div className="bg-white px-6 space-y-4 py-4">
            <h1 className="text-center font-semibold">{t("common.labels.backWithoutSaving")}</h1>
            <div className="flex flex-row justify-center items-center">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  history.back();
                }}
                type="button"
                className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-red-500 font-bold px-8 py-2 text-white"
              >
                {t("buttons.yes")}
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  setState({ ...state, displayExitModal: false });
                }}
                type="button"
                className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-green-500 font-bold px-8 py-2 text-white"
              >
                {t("buttons.no")}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {successModal && (
        <Modal
          onClose={() => {
            setSuccessModal(false);
          }}
          showCrossIcon={true}
          className="space-y-6"
        >
          <div>
            <h3 className="text-xl font-semibold">Confirmation </h3>
            {/* Add your logic to display product summary based on productsDataFinal */}
            {/* Example: */}
            <div></div>
            <div className="flex-col border border-gray-200 divide-y divide-gray-200 bg-white rounded-md w-full px-2 py-3s space-y-1 mt-2">
              <h1 className=" text-md font-semibold">{"Are you sure to submit this form?"}</h1>
            </div>
            <div style={{ height: "30px" }}></div>
          </div>

          <div className="flex justify-center items-center mt-4">
            <button onClick={handleSubmitConfirm} type="button" className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-[#00cfae]  font-bold rounded-3xl text-md text-center mr-2 mb-2">
              Confirm
            </button>
            <button onClick={() => setSuccessModal(false)} type="button" className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-blue-600  font-bold rounded-3xl text-md text-center mb-2">
              No
            </button>
          </div>
        </Modal>
      )}
    </AppLayout>
    /* eslint-enable no-restricted-globals */
  );
};

interface FormProps {
  register?: UseFormRegister<MWRARegistrationForm>;
  errors?: FieldErrors<MWRARegistrationForm>;
  control?: Control<MWRARegistrationForm, any>;
  setValue?: UseFormSetValue<MWRARegistrationForm>;
  resetField?: UseFormResetField<MWRARegistrationForm>;
  watch?: UseFormWatch<MWRARegistrationForm>;
}

const PersonalDetailsForm = ({ register, errors, control, watch, setValue }: FormProps) => {
  const [isValid, setIsValid] = useState<number>();
  const birthMonth = watch?.("birthMonth") ?? "";
  const birthYear = watch?.("birthYear") ?? "";

  const age = getYearsPassed(birthMonth, birthYear);

  useEffect(() => {
    if (isValid != undefined) {
      setValue?.("age", age, { shouldValidate: true });
    }
    setIsValid(age);
  }, [age]);

  const dir = getDirection();

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.name"))} filename={"nameOfMWRA.mp3"} />
      <Input register={register} error={errors} type="text" name="name" className="rounded-lg border" placeholder={t(tKey("placeholders.name"))} />

      <Label labelText={t(tKey("labels.age"))} filename={"ageOfMWRA.mp3"} />
      <div className={clsx("flex justify-center space-x-8 py-6", dir == "rtl" && "space-x-8")}>
        <Controller
          control={control}
          name={"birthMonth"}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <Select name="birthMonth" className="pl-2 w-32" controllerError={error} onChange={onChange} value={value}>
                <option value="">{t(tKey("labels.month"))}</option>
                {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Select>
            </div>
          )}
        />
        <Controller
          control={control}
          name={"birthYear"}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <Select name="birthYear" className="pl-2 w-32" controllerError={error} onChange={onChange} value={value}>
                <option value="">{t(tKey("labels.year"))}</option>
                {Array.from({ length: 100 }, (_, index) => 2023 - index).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </div>
          )}
        />
      </div>
      <div className="text-center text-lg pt-6">
        <div>
          {t(tKey("labels.age"))}:
          <div className="flex justify-center">
            <Input className="text-center" name="age" value={age == 0 ? "" : age} register={register} error={errors} disabled />
          </div>
        </div>
      </div>
    </div>
  );
};

const WomanConditionSelectionForm = ({ control }: FormProps) => {
  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.condition"))} filename={"statusOfMWRA.mp3"} />
      <Controller
        control={control}
        name={"condition"}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <>
            <div className="font-normal space-y-2 px-2">
              <label>
                <div className={clsx("flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value === WomanConditionTypes.PREGNANT })}>
                  <input
                    value={WomanConditionTypes.PREGNANT}
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={(event) => onChange(event.target.value)} // send value to hook form
                    checked={value === WomanConditionTypes.PREGNANT}
                  />
                  <img className="h-10 w-10" src={PregnantIcon} />
                  <div className="flex items-center">
                    <p>{t(tKey("options.pregnant"))}</p>
                    <Speaker filename="Pregnant.mp3" />
                  </div>
                </div>
              </label>
              <label>
                <div className={clsx("flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value === WomanConditionTypes.LACTATING })}>
                  <input
                    value={WomanConditionTypes.LACTATING}
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={(event) => onChange(event.target.value)} // send value to hook form
                    checked={value === WomanConditionTypes.LACTATING}
                  />
                  <img className="h-10 w-10 pl-2" src={LactatingIcon} />
                  <div className="flex items-center">
                    <p>{t(tKey("options.lactatingOption"))}</p>
                    <Speaker filename="Lactating.mp3" />
                  </div>
                </div>
              </label>
              <label>
                <div className={clsx("flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value === WomanConditionTypes.MWRA })}>
                  <input
                    value={WomanConditionTypes.MWRA}
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={(event) => onChange(event.target.value)} // send value to hook form
                    checked={value === WomanConditionTypes.MWRA}
                  />
                  <img className="h-10 w-10" src={WomanIcon} />
                  <div className="flex items-center">
                    <p>{t(tKey("options.mwra"))}</p>
                    <Speaker filename="woman.mp3" />
                  </div>
                </div>
              </label>
            </div>
            <InputFieldError message={error?.message as string} className="ml-2" />
          </>
        )}
      />
    </div>
  );
};

const ContactForm = ({
  control,
  register,
  errors,
  bastis,
}: FormProps & {
  bastis: Basti[];
}) => {
  const bastiErrorText = errors?.["bastiId"]?.message as string;

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.husband"))} filename={"husbandName.mp3"} />
      <Input register={register} error={errors} type="text" name={"husbandName"} rounded={true} placeholder={t(tKey("labels.husband"))} />

      <Label labelText={t(tKey("labels.contactNumber"))} filename={"phonenumberOfMWRA.mp3"} />

      <Controller
        name={"phone"}
        control={control as any}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputNumber name={"phone"} placeholder={t(tKey("labels.contactNumber"))} maxLength={11} value={value} controllerError={error} type="text" rounded={true} onChange={onChange} />
        )}
      />

      <Label labelText={t(tKey("labels.basti"))} filename={"selectABasti.mp3"} />
      <Controller
        control={control}
        name={"bastiId"}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="grid grid-cols-2 gap-2 pb-4">
            {bastis.map((basti) => {
              return (
                <label key={basti.id}>
                  <div className={clsx("flex w-full flex-row items-center space-x-2 rounded-lg border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value === basti.id })}>
                    <input
                      value={basti.id}
                      type="radio"
                      onBlur={onBlur} // notify when input is touched
                      onChange={(event) => onChange(parseInt(event.target.value))} // send value to hook form
                      checked={value === basti.id}
                    />
                    <p className="pl-2">{getLocalizedName(basti)}</p>
                  </div>
                </label>
              );
            })}
            <InputFieldError message={bastiErrorText} />
          </div>
        )}
      />
    </div>
  );
};

const FamilyMembersForm = ({ control }: FormProps) => {
  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.familyMembers"))} filename={"countOfFamilyMembers.mp3"} />
      <img className="h-10 w-10 mt-2 mx-auto" src={ManIcon} />
      <Controller
        control={control}
        name={"totalFamilyMembers"}
        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
          <div>
            <CounterInput maxLength={2} minimumValue={2} controllerError={error} onBlur={onBlur} onChange={onChange} onNumberChange={(number) => onChange(number)} value={value} />
          </div>
        )}
      />
    </div>
  );
};

const IncomeForm = ({ control, errors }: FormProps) => {
  const incomeErrorText = errors?.["familyIncome"]?.message as string;

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.familyIncome"))} filename={"householdIncome.mp3"} />
      <Controller
        control={control}
        name={"familyIncome"}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="py-2">
            {incomeRange.map(({ title: incomeRange, image }) => {
              return (
                <label key={incomeRange}>
                  <div className={clsx("flex w-full flex-row items-center my-2 space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value === incomeRange })}>
                    <input
                      value={incomeRange}
                      type="radio"
                      onBlur={onBlur} // notify when input is touched
                      onChange={(event) => onChange(event.target.value)} // send value to hook form
                      checked={value === incomeRange}
                    />
                    <img className="h-8 w-8" src={image} />
                    <p>{t(tKey(`options.${incomeRange}`))}</p>
                  </div>
                </label>
              );
            })}
            <InputFieldError message={incomeErrorText} />
          </div>
        )}
      />
    </div>
  );
};

const BISPForm = ({ control, errors, watch }: FormProps) => {
  const bispMemberError = errors?.["isBispMember"]?.message as string;
  const bispNashunumaError = errors?.["isBispNashonuma"]?.message as string;
  const isBispMember = watch?.("isBispMember") || false;

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.BISP"))} filename={"bispBeneficiary.mp3"} />
      <Controller
        control={control}
        name={"isBispMember"}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="space-y-2 py-2">
            <div className="space-y-1">
              <label>
                <div className={clsx("flex w-full flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && value })}>
                  <input
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={() => onChange(true)} // send value to hook form
                    checked={value !== undefined && value}
                  />
                  <img className="h-8 aspect-square" src={CheckmarkIcon} />
                  <p>{t("buttons.yes")}</p>
                </div>
              </label>
              <label>
                <div className={clsx("flex w-full flex-row items-center space-x-2 my-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && !value })}>
                  <input
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={() => onChange(false)} // send value to hook form
                    checked={value !== undefined && !value}
                  />
                  <img className="h-8 aspect-square" src={CrossIcon} />

                  <p>{t("buttons.no")}</p>
                </div>
              </label>
            </div>
            <InputFieldError message={bispMemberError} />
          </div>
        )}
      />
      {isBispMember && (
        <>
          <Label labelText={t(tKey("labels.BISPNashonuma"))} filename={"bispNashunuma.mp3"} />
          <Controller
            control={control}
            name={"isBispNashonuma"}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="space-y-2 py-4 px-2">
                <div className="space-y-1">
                  <label>
                    <div className={clsx("flex w-full flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && value })}>
                      <input
                        type="radio"
                        onBlur={onBlur} // notify when input is touched
                        onChange={() => onChange(true)} // send value to hook form
                        checked={value !== undefined && value}
                      />
                      <img className="h-8 aspect-square" src={CheckmarkIcon} />

                      <p>{t("buttons.yes")}</p>
                    </div>
                  </label>
                  <label>
                    <div className={clsx("flex w-full flex-row items-center space-x-2 my-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && !value })}>
                      <input
                        type="radio"
                        onBlur={onBlur} // notify when input is touched
                        onChange={() => onChange(false)} // send value to hook form
                        checked={value !== undefined && !value}
                      />
                      <img className="h-8 aspect-square" src={CrossIcon} />

                      <p>{t("buttons.no")}</p>
                    </div>
                  </label>
                </div>
                <InputFieldError message={bispNashunumaError} />
              </div>
            )}
          />
        </>
      )}
    </div>
  );
};
const months: { [monthName: string]: number } = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};
const DeliveryDateForm = ({ control, errors, resetField }: FormProps) => {
  const currYear = DateTime.now().year;
  const [selectedYear, setSelectedYear] = useState(currYear);
  const deliveryDateError = errors?.["expectedDeliveryDate"]?.message as string;
  const [customError, setCustomError] = useState<string>();
  const yearsArray = [currYear, currYear + 1];

  const validateDate = (month: number, year: number) => {
    const selectedDate = DateTime.fromObject({ day: 1, month, year });

    const currentDate = DateTime.fromObject({
      day: 1,
      month: DateTime.now().month,
      year: currYear,
    });
    const isPreviousDate = selectedDate.diff(currentDate, "month").months < 0;

    // Not using this now but can be a later requirement
    const differenceGreaterThanTenMonths = selectedDate.diff(currentDate, "month").months > 10;
    if (isPreviousDate) {
      setCustomError(t(tKey("errors.deliveryMonth")));
      return false;
    }
    setCustomError(undefined);
    return true;
  };

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.expectedMonth"))} filename={"dueDeliveryMonth.mp3"} />
      <div dir="ltr" className="flex flex-row items-center justify-center">
        {yearsArray.map((year, index) => (
          <div
            key={year}
            onClick={(event) => {
              event.preventDefault();
              resetField?.("expectedDeliveryDate");
              setSelectedYear(year);
            }}
            className={clsx("px-8 border border-gray-400 py-2 ", index === 0 ? "rounded-l-md" : "rounded-r-md", { "text-blue-500 border-blue-500 bg-blue-100": selectedYear == year })}
          >
            {year}
          </div>
        ))}
      </div>
      <Controller
        control={control}
        name={"expectedDeliveryDate"}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="grid grid-cols-3 gap-2 pt-4">
            {Object.entries(months).map(([monthName, monthNumber]) => {
              // Check if the current date is previous to the selected date
              const currentDate = DateTime.now();

              const selectedDate = DateTime.fromObject({
                day: 1,
                month: monthNumber,
                year: selectedYear,
              });

              const isPreviousDate = selectedDate.year > currentDate.year ? false : selectedDate.month <= currentDate.month - 1;

              return (
                <label key={monthName}>
                  <div
                    className={clsx("flex w-full flex-row items-center space-x-2 rounded-xl border px-2 py-3", {
                      "bg-gray-300 border-gray-300 cursor-not-allowed": isPreviousDate, // Apply disable cursor for previous months
                      "bg-[#E9ECFF] border-[#E9ECFF]": value === `${monthNumber}-${selectedYear}`,
                    })}
                  >
                    <input
                      value={monthName}
                      type="radio"
                      onBlur={onBlur}
                      onChange={(event) => {
                        if (validateDate(monthNumber, selectedYear)) {
                          onChange(`${monthNumber}-${selectedYear}`);
                        }
                      }}
                      checked={value === `${monthNumber}-${selectedYear}`}
                      disabled={isPreviousDate}
                    />
                    <p>{t(`months.${monthName.toLocaleLowerCase()}`)}</p>
                  </div>
                </label>
              );
            })}
          </div>
        )}
      />
      <InputFieldError message={deliveryDateError} className="ml-1" />

      {customError && <InputFieldError message={customError} />}
    </div>
  );
};

const ANCForm = ({ control, errors }: FormProps) => {
  const ancError = errors?.["isMeetingWithAnc"]?.message as string;

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.ANC"))} filename={"meetingANC.mp3"} />
      <Controller
        control={control}
        name={"isMeetingWithAnc"}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="space-y-2 px-2">
            <div className="space-y-1">
              <label>
                <div className={clsx("flex w-full flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && value })}>
                  <input
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={() => onChange(true)} // send value to hook form
                    checked={value !== undefined && value}
                  />
                  <img className="h-8 aspect-square" src={CheckmarkIcon} />
                  <p>{t("buttons.yes")}</p>
                </div>
              </label>
              <label>
                <div className={clsx("flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && !value })}>
                  <input
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={() => onChange(false)} // send value to hook form
                    checked={value !== undefined && !value}
                  />
                  <img className="h-8 aspect-square" src={CrossIcon} />
                  <p>{t("buttons.no")}</p>
                </div>
              </label>
            </div>
            <InputFieldError message={ancError} className="my-2" />
          </div>
        )}
      />
    </div>
  );
};

const BreastfeedingChildForm = ({ control }: FormProps) => {
  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.breastfed"))} filename={"ageOfLactatingChild.mp3"} />
      <Controller
        control={control}
        name={"breastfeedingChildAge"}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <div>
            <CounterInput controllerError={error} onBlur={onBlur} maxLength={1} maxValue={6} onChange={onChange} onNumberChange={(number) => onChange(number === 0 ? "" : number)} value={value == undefined ? 0 : value} />
          </div>
        )}
      />
    </div>
  );
};

const MUACForm = ({ control }: FormProps) => {
  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.MUACWoman"))} filename={"muacOfMWRA.mp3"} />
      <Controller
        control={control}
        name={"muac"}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <CounterDecimalInput
              maxLength={2}
              controllerError={error}
              onChange={(event) => {
                onChange(event.target.valueAsNumber);
              }}
              onNumberChange={(number) => {
                onChange(number);
              }}
              value={value}
            />
          </div>
        )}
      />
    </div>
  );
};

const TotalChildrenForm = ({ control }: FormProps) => {
  console.log("finalData", finalData);
  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.children"))} filename={"totalNumberOfKids.mp3"} />
      <img className="h-10 aspect-square mx-auto my-2" src={BabyboyIcon} />

      <Controller
        control={control}
        name={"totalChildren"}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <div>
            <CounterInput maxValue={finalData?.totalFamilyMembers - 2} maxLength={finalData?.totalFamilyMembers - 2} onBlur={onBlur} controllerError={error} onChange={onChange} onNumberChange={(number) => onChange(number)} value={value} />
          </div>
        )}
      />
    </div>
  );
};

const ChildrenUnderTwoForm = ({ control }: FormProps) => {
  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.maleChildrenUnderTwo"))} filename={"boysUnderTheAgeOfTwo.mp3"} />
      <img className="h-10 aspect-square block mx-auto" src={BabyboyIcon} />
      <Controller
        control={control}
        name={"malesUnderTwo"}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <div>
            <CounterInput maxLength={2} controllerError={error} onBlur={onBlur} onChange={onChange} onNumberChange={(number) => onChange(number)} value={value || 0} />
          </div>
        )}
      />

      <Label labelText={t(tKey("labels.femaleChildrenUnderTwo"))} filename={"girlsUnderTheAgeOfTwo.mp3"} />
      <img className="h-10 aspect-square block mx-auto" src={BabygirlIcon} />
      <Controller
        control={control}
        name={"femalesUnderTwo"}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <CounterInput maxLength={2} controllerError={error} onChange={onChange} onNumberChange={(number) => onChange(number)} value={value || 0} />
          </div>
        )}
      />
    </div>
  );
};

const MUACChildrenForm = ({ errors, control, totalChildren, register }: FormProps & { totalChildren: number }) => {
  const { fields, append, remove } = useFieldArray({ name: "muacChildren", control });

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.lowMUACChildren"))} filename={"childrenInformation.mp3"} />

      {fields.map((child, index) => {
        return (
          <div key={index} className="space-y-4">
            <div>
              <Input placeholder="Name" type="text" labelText={t(tKey("labels.childName"))} name={`muacChildren.${index}.name`} register={register} rounded={true} error={errors} />
              <InputFieldError message={errors?.["muacChildren"]?.[index]?.["name"]?.message ?? ""} className="my-2" />
            </div>
            <h1 className="font-medium text-black">{t(tKey("labels.muac"))}</h1>
            <Controller
              control={control}
              name={`muacChildren.${index}.muac`}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <div>
                  <CounterDecimalInput onChange={onChange} onBlur={onBlur} controllerError={error} maxLength={2} onNumberChange={(number) => onChange(number === 0 ? "" : number)} value={value} />
                </div>
              )}
            />
            <h1 className="font-medium text-black">{t(tKey("labels.muac_age"))}</h1>
            <Controller
              control={control}
              name={`muacChildren.${index}.age`}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  <CounterInput  minimumValue={6} maxValue={59} controllerError={error} onChange={onChange} onNumberChange={(number) => onChange(Number(number === 0 ? "" : number))} value={value || 6} isMuacAge={true} />
                </div>
              )}
            />

            <div className="flex w-full justify-end max-w-max pb-2 hover:cursor-pointer" onClick={() => remove(index)}>
              <TrashIcon className="w-5 h-5 text-red-500" />
              <p className="text-red-500 cursor-pointer max-w-max">{t("buttons.remove")}</p>
            </div>
          </div>
        );
      })}
      <div>
        <p
          className="text-blue-600 px-2 cursor-pointer max-w-max hover:underline"
          onClick={(event) => {
            event.preventDefault();
            if (fields.length >= totalChildren) {
              return toast.error("Cannot add more children");
            }
            append({ name: "", age: 0, muac: "" as any });
          }}
        >
          {t(tKey("buttons.addChild"))}
        </p>
      </div>
    </div>
  );
};

const ContraceptiveUseForm = ({ control, errors }: FormProps) => {
  const contraceptiveUseError = errors?.["isFamilyPlanning"]?.message as string;

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.familyPlanning"))} filename={"familyPlanning.mp3"} />
      <Controller
        control={control}
        name={"isFamilyPlanning"}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="space-y-2 px-2">
            <div className="space-y-1">
              <label>
                <div className={clsx("flex w-full flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && value })}>
                  <input
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={() => onChange(true)} // send value to hook form
                    checked={value !== undefined && value}
                  />
                  <img className="h-10 aspect-square" src={CheckmarkIcon} />

                  <p>{t("buttons.yes")}</p>
                </div>
              </label>
              <label>
                <div className={clsx("flex w-full my-2 flex-row items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value !== undefined && !value })}>
                  <input
                    type="radio"
                    onBlur={onBlur} // notify when input is touched
                    onChange={() => onChange(false)} // send value to hook form
                    checked={value !== undefined && !value}
                  />
                  <img className="h-10 aspect-square" src={CrossIcon} />

                  <p>{t("buttons.no")}</p>
                </div>
              </label>
            </div>
            <InputFieldError message={contraceptiveUseError} />
          </div>
        )}
      />
    </div>
  );
};

const ContraceptivesForm = ({ control, errors }: FormProps) => {
  const contraceptiveMethods = errors?.["contraceptiveMethods"]?.message as string;

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.familyPlanningMethods"))} filename={"methodForFamilyPlanning.mp3"} />
      <Controller
        control={control}
        name={"contraceptiveMethods"}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="py-2">
            {contraceptives.map(({ title: contraceptiveMethod, image }) => {
              return (
                <label key={contraceptiveMethod}>
                  <div className={clsx("flex w-full flex-row my-2 items-center space-x-2 rounded-xl border border-gray-400 px-2 py-3", { "bg-[#E9ECFF] border-[#E9ECFF] ": value?.includes(contraceptiveMethod) })}>
                    <input
                      value={contraceptiveMethod}
                      type="checkbox"
                      onBlur={onBlur} // notify when input is touched
                      onChange={(event) => {
                        if (value?.includes(event.target.value)) {
                          const newValue = value.filter((contraceptive) => contraceptive !== contraceptiveMethod);
                          return onChange(newValue);
                        }
                        onChange([...(value ?? []), contraceptiveMethod]);
                      }} // send value to hook form
                      checked={value?.includes(contraceptiveMethod)}
                    />
                    {image && <img src={image} className="h-8 w-8" />}

                    <p>{t(tKey(`options.${contraceptiveMethod}`))}</p>
                  </div>
                </label>
              );
            })}
            <InputFieldError message={contraceptiveMethods} />
          </div>
        )}
      />
    </div>
  );
};

const SummaryForm = ({ control, errors, getBastiName }: FormProps & { getBastiName: any }) => {
  const contraceptiveMethods = errors?.["contraceptiveMethods"]?.message as string;

  return (
    <div className="space-y-4">
      <Label labelText={t(tKey("labels.Summary"))} filename={"methodForFamilyPlanning.mp3"} />
      {/* <Disclosure defaultOpen={true}>
				{({ open }) => (
					<div className="bg-white shadow p-4">
						<Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left  font-medium text-slate-900  focus:outline-none ">
							<span>{t('edit.labels.personalDetails')}</span>
							<ChevronUpIcon
								className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-slate-500`}
							/>
						</Disclosure.Button>
						<Disclosure.Panel className="px-4 divide-y py-4 text-gray-500">

						</Disclosure.Panel>
					</div>
				)}
			</Disclosure> */}
      <div>
        <div style={{ marginBottom: "20px", padding: "15px", border: "1px solid #ccc", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
          <h1 style={{ fontSize: "1.5em", color: "#333", marginBottom: "10px" }}>Personal Information</h1>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Name:</span>
            <span>{finalData?.name}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Age:</span>
            <span>{finalData?.age}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Birth Year:</span>
            <span>{finalData?.birthYear}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Birth Month:</span>
            <span>{finalData?.birthMonth}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Basti:</span>
            <span>{getBastiName(finalData?.bastiId)}</span>
          </div>
          {/* ... Add other personal information items */}
        </div>

        <div style={{ marginBottom: "20px", padding: "15px", border: "1px solid #ccc", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
          <h1 style={{ fontSize: "1.5em", color: "#333", marginBottom: "10px" }}>Family Details</h1>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Husband:</span>
            <span>{finalData?.husbandName}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Contact Number:</span>
            <span>{finalData?.phone}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Number Of Family Member:</span>
            <span>{finalData?.totalFamilyMembers}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Number Of Children:</span>
            <span>{finalData?.totalChildren}</span>
          </div>

          {finalData?.condition === "lactating" && (
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
              <span>What is the age of the child being breastfed(Months)</span>
              <span>{finalData?.breastfeedingChildAge}</span>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>How many of the children are male and under the age of 2?</span>
            <span>{finalData?.malesUnderTwo}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>How many of the children are female and under the age of 2?</span>
            <span>{finalData?.femalesUnderTwo}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Benazir Income Support Programme Beneficiary Status:</span>
            <span>{finalData?.isBispMember ? "YES" : "NO"}</span>
          </div>

          {finalData?.isBispMember && (
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
              <span>Benazir Income Support Programme Nashonuma Status:</span>
              <span>{finalData?.isBispNashonuma ? "YES" : "NO"}</span>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>MUAC measurement of the MWRA/PLW:</span>
            <span>{finalData?.muac}</span>
          </div>
          {/* ... Add other family details items */}
        </div>

        <div style={{ marginBottom: "20px", padding: "15px", border: "1px solid #ccc", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
          <h1 style={{ fontSize: "1.5em", color: "#333", marginBottom: "10px" }}>Health and Family Planning</h1>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Condition:</span>
            <span>{finalData?.condition}</span>
          </div>
          {finalData?.condition === "pregnant" && (
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
              <span>Excepted Month Of delivery:</span>
              <span>{finalData?.expectedDeliveryDate}</span>
            </div>
          )}
          {finalData?.condition !== "pregnant" && (
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
              <span>Contraceptive Methods:</span>
              <span>{finalData?.contraceptiveMethods && finalData?.contraceptiveMethods.join(",")}</span>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Family Income:</span>
            <span>{finalData?.familyIncome}</span>
          </div>
          {/* ... Add other health and family planning items */}
        </div>
        <div style={{ marginBottom: "20px", padding: "15px", border: "1px solid #ccc", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
          <h1 style={{ fontSize: "1.5em", color: "#333", marginBottom: "10px" }}>Others</h1>

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <span>Are you planning to meet ANC?</span>
            <span>{finalData?.isMeetingWithAnc ? "YES" : "NO"}</span>
          </div>

          {/* ... Add other health and family planning items */}
        </div>

        {finalData?.muacChildren?.length > 0 && (
          <div style={{ marginBottom: "20px", padding: "15px", border: "1px solid #ccc", borderRadius: "8px", backgroundColor: "#f9f9f9", marginTop: "10px" }}>
            <h1 style={{ fontSize: "1.5em", color: "#333", marginBottom: "10px" }}>MUAC Children</h1>
            {finalData?.muacChildren.map((item: any, index: any) => (
              <div key={index}>
                <div>
                  <span>Child Name:</span>
                  <span>{item?.name}</span>
                </div>
                <div>
                  <span>MUAC:</span>
                  <span>{item?.muac}</span>
                </div>
                <div>
                  <span>Age(Months):</span>
                  <span>{item?.age}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
