import { DateTime } from 'luxon'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks'
import { updateProfile } from 'slices/auth'
import { updateLocationInfo } from 'slices/db'

export const useGeoLocation = () => {
	const dispatch = useAppDispatch()
	const { profile: user } = useAppSelector(state => state.auth)
	const locationInfo = useAppSelector(state => state.db.locationInfo)

	useEffect(() => {
		if ('geolocation' in navigator) {
			const currentDate = DateTime.now().toISODate()
			const nextDate = DateTime.fromISO(currentDate).plus({ days: 7 }).toFormat('yyyy-MM-dd')

			if (
				!user.latlng ||
				!locationInfo?.nextPromptDate ||
				locationInfo?.nextPromptDate === currentDate
			) {
				navigator.geolocation.getCurrentPosition(
					location => {
						const coords = `${location.coords.latitude} ${location.coords.longitude}`
						// need to create sync mechanism for
						// updates
						dispatch(updateProfile({ user: { latlng: coords } as CRP }))
						dispatch(
							updateLocationInfo({
								locationInfo: {
									nextPromptDate: nextDate,
									lastPromptDate: currentDate
								}
							})
						)
					},
					error => {
						console.log(error)
					}
				)
			}
		} else {
			// do nothing for now
		}
	}, [user.latlng, locationInfo?.nextPromptDate])
}
