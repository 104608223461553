import { AdjustmentsVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { InputHTMLAttributes } from 'react'

import clsx from 'clsx'
import { getDirection } from 'utils/string'

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
	showMenuButton?: boolean
	showMenuCallback?: Function
}

export const SearchInput = ({ showMenuButton, showMenuCallback, ...props }: SearchInputProps) => {
	const dir = getDirection()
	return (
		<div className="relative w-full">
			<input
				type="search"
				{...props}
				name="search"
				className={clsx(
					props.className,
					'text-gray-500 rounded-lg shadow-sm py-2.5 w-full text-ellipsis px-8'
				)}
				placeholder={props.placeholder ?? 'Search here...'}
				autoComplete="off"
			/>
			<div className="absolute text-gray-500 mx-4 my-3 top-0">
				<MagnifyingGlassIcon className={clsx('h-5 w-5', dir === 'rtl' ? 'right-0' : 'left-0')} />
			</div>
			{showMenuButton && (
				<div
					className={clsx(
						'absolute text-gray-500 mr-2 ml-4 my-3 -top-0.5',
						dir === 'rtl' ? 'left-0' : 'right-0'
					)}>
					<AdjustmentsVerticalIcon
						onClick={showMenuCallback ? () => showMenuCallback() : undefined}
						className="h-7 w-7 cursor-pointer stroke-4 text-[#84CDDA]"
					/>
				</div>
			)}
		</div>
	)
}
