import axios from 'axios'
import { toast } from 'react-hot-toast'

import { apiHost } from 'utils/host'
import { getAuthHeader } from './auth-header'
import AuthService from './auth-service'

axios.defaults.baseURL = apiHost

const axiosInstance = axios.create({
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=UTF-8'
	}
})

axiosInstance.interceptors.request.use(async config => {
	config.headers.Authorization = await getAuthHeader()
	return config
})

axiosInstance.interceptors.response.use(
	response => response,
	error => {
		if (!error.response) {
			// toast.error('Service not available!')
		} else if (error.response.status === 401) {
			toast.error('Session has been expired, login again!')
			setTimeout(() => {
				AuthService.logout()
				window.location.reload()
			}, 1000)
		}
		return error
	}
)

export default axiosInstance
