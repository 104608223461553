import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { useAppSelector } from 'hooks'
import { useDetectNetwork } from 'hooks/useDetectNetwork'
import { useLoadResources } from 'hooks/useLoadResources'
import { useSyncWithServer } from 'hooks/useSyncWithServer'
import { RootState } from 'store'

import { PageNotFound } from 'pages/404'
import { Login } from 'pages/auth/login'
import { Home } from 'pages/home'
import { RecurringLogin } from 'pages/login/recurringLogin'
import { CreateMeeting } from 'pages/meetings/create'
import { AddMwraMenu } from 'pages/mwras'
import { CreateMwra } from 'pages/mwras/create'
import { EditMwra } from 'pages/mwras/edit'
import { CreateFollowup } from 'pages/mwras/followups/create'
import { FollowUpMenu } from 'pages/mwras/followups/menu'
import { MwraList } from 'pages/mwras/list'
import { MwraMenu } from 'pages/mwras/menu'
import { CreateSale } from 'pages/mwras/sales/create'
import { SaleDetails } from 'pages/mwras/sales/history/details'
import { SalesHistoryList } from 'pages/mwras/sales/history/list'
import { UploadForm } from 'pages/mwras/upload-form'
import { SalesPerformance } from 'pages/sales/performance'
import { CreateStockEntry } from 'pages/stocks/create'
import { ListAssignedCRPs } from 'pages/stocks/list-crps'
import { CRPForm } from 'pages/mwras/sampleFormOne'
import { SeniorCRPForm } from 'pages/mwras/seniorCrp'
import { CRPFormViewOrder } from 'pages/mwras/CRPFormViewOrder'
import { GoodReceivedNote } from 'pages/mwras/view-received-note'
import { ViewCurrentStock } from 'pages/mwras/view-current-stock'
import { AddGoodReceivedNote } from 'pages/mwras/add-received-note'
import { SeniorCrpRequisitions } from 'pages/mwras/seniorCrpRequisitions'
import { InventoryManagement } from 'pages/inventory-management'
import { ApproveDeliveryChallan } from 'pages/mwras/approve-delivery-challan'
import { StattusViewCurrentStock } from 'pages/mwras/status-view-current-stock'
import { StockReports } from 'pages/stock-reports'
import { ViewPendingStock } from 'pages/mwras/view-pending-stock'
import { Dashboard } from 'pages/dashboard/index'

export const Routing = () => {
	const auth = useAppSelector(state => state.auth)

	useDetectNetwork()
	useSyncWithServer()
	useLoadResources()

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={auth.jwt ? <Home /> : <Login />} />
				{/* <Route path="/test-form" element={ <testForm />} /> */}
				<Route
					path="/requisitions"
					element={
						<ProtectedRoute auth={auth}>
							<CRPForm />
						</ProtectedRoute>
					}
				/>
				
				<Route
					path="/view-order-summary"
					element={
						<ProtectedRoute auth={auth}>
							<CRPFormViewOrder />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/view-received-note"
					element={
						<ProtectedRoute auth={auth}>
							<GoodReceivedNote />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/approve-delivery-challan"
					element={
						<ProtectedRoute auth={auth}>
							<ApproveDeliveryChallan />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/add-received-note"
					element={
						<ProtectedRoute auth={auth}>
							<AddGoodReceivedNote />
						</ProtectedRoute>
					}
				/>
				
				<Route
					path="/view-current-stock"
					element={
						<ProtectedRoute auth={auth}>
							<ViewCurrentStock />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/view-pending-stock"
					element={
						<ProtectedRoute auth={auth}>
							<ViewPendingStock />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/dashboard/index"
					element={
						<ProtectedRoute auth={auth}>
							<Dashboard />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/status-current-stock"
					element={
						<ProtectedRoute auth={auth}>
							<StattusViewCurrentStock />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/sampleFormTwo"
					element={
						<ProtectedRoute auth={auth}>
							<SeniorCrpRequisitions />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/login"
					element={
						<PublicRoute auth={auth}>
							<Login />
						</PublicRoute>
					}
				/>

				<Route
					path="/home"
					element={
						<ProtectedRoute auth={auth}>
							<Home />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/inventory-management"
					element={
						<ProtectedRoute auth={auth}>
							<InventoryManagement  />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/stock-reports"
					element={
						<ProtectedRoute auth={auth}>
							<StockReports  />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras"
					element={
						<ProtectedRoute auth={auth}>
							<AddMwraMenu />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/add"
					element={
						<ProtectedRoute auth={auth}>
							<CreateMwra />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/add"
					element={
						<ProtectedRoute auth={auth}>
							<CreateMwra />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/upload-form"
					element={
						<ProtectedRoute auth={auth}>
							<UploadForm />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/meetings"
					element={
						<ProtectedRoute auth={auth}>
							<CreateMeeting />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/followup-menu"
					element={
						<ProtectedRoute auth={auth}>
							<FollowUpMenu />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/list"
					element={
						<ProtectedRoute auth={auth}>
							<MwraList />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/:id"
					element={
						<ProtectedRoute auth={auth}>
							<MwraMenu />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/:id/followup"
					element={
						<ProtectedRoute auth={auth}>
							<CreateFollowup />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/:id/sale"
					element={
						<ProtectedRoute auth={auth}>
							<CreateSale />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/:id/sales"
					element={
						<ProtectedRoute auth={auth}>
							<SalesHistoryList />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/:id/sales/:saleId"
					element={
						<ProtectedRoute auth={auth}>
							<SaleDetails />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/mwras/:id/edit"
					element={
						<ProtectedRoute auth={auth}>
							<EditMwra />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/sales-performance"
					element={
						<ProtectedRoute auth={auth}>
							<SalesPerformance />
						</ProtectedRoute>
					}
				/>
				{/* <Route
					path="/stocks/crps"
					element={
						<ProtectedRoute auth={auth}>
							<ListAssignedCRPs />
						</ProtectedRoute>
					}
				/> */}
				<Route
					path="/stocks/crps/:id"
					element={
						<ProtectedRoute auth={auth}>
							<CreateStockEntry />
						</ProtectedRoute>
					}
				/>
				<Route path="/biometric" element={<RecurringLogin />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	)
}

type RouteProps = {
	redirectPath?: string
	children: JSX.Element
	auth: RootState['auth']
}

const ProtectedRoute = ({ auth, children, redirectPath = '/' }: RouteProps) => {
	if (!auth?.jwt) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}

const PublicRoute = ({ auth, children, redirectPath = '/' }: RouteProps) => {
	if (auth?.jwt) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}
