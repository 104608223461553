import axios from 'services/axios'

const getMWRAsByCRP = (id: ID) => {
	return axios.get(`/getMwrasByCrp.php`).then(response => {
		return response.data
	})
	.catch(error => {
		console.error("Error fetching data:", error);
		throw error; // Optionally re-throw the error to propagate it to the calling code.
	});
}
//https://api-test.micare.pk/services/saveRegistration.php For Registration

const createMWRA = (mwra: MWRARegistrationForm) => {
	return axios.post('/saveRegistration.php', {
		mwra
	})
}

const uploadForm = (form: FormData) => {
	return axios.postForm(`/registrations/forms`, form).then(response => {
		return response.data
	})
}
const updateMWRAById = (id: ID, mwra: MWRARegistrationForm) => {
	return axios.put(`/registrations/${id}`, { mwra }).then(response => {
		return response.data
	})
}
const registrationService = {
	createMWRA,
	getMWRAsByCRP,
	uploadForm,
	updateMWRAById
}

export default registrationService
