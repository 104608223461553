import { ClassValue, clsx as dClsx } from 'clsx'
import { Language } from 'constants/constants'
import { getAppLang } from './language'

export default function clsx(...inputs: ClassValue[]): string {
	const lang = getAppLang()
	const dirClasses = lang === Language.EN ? '' : 'space-x-reverse'

	return dClsx(inputs, dirClasses)
}
