import axios from 'services/axios'

const getProducts = () => {
	return axios.get('/getProducts.php').then(response => {
		// return response.data
		return response.data
	})
}
//https://api-test.micare.pk/services/getStockStatus.php no parameters

const getStockStatus = () => {
	return axios.get('/getStockStatus.php').then(response => {
		// return response.data
		return response.data.data
	})
}
//products?district_prefix=""
//getProductsByDistricts.php?district_id
const getProductByDistrictPrefix = (prefix: string) => {
	return axios.get(`/getProductsByDistricts.php?district_id=${prefix}`).then(response => {
		return response.data
	})
}
const getTargetsByDistricts = (prefix: string) => {
	return axios.get(`/getTargetsByDistricts.php?district_id=${prefix}`).then(response => {
		return response.data
	})
}
const productService = {
	getProducts,
	getProductByDistrictPrefix,
	getStockStatus,
	getTargetsByDistricts
}

export default productService
