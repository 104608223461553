import { SpeakerWaveIcon } from '@heroicons/react/24/outline'
import useSound from 'use-sound'
import clsx from 'utils/clsx'
import { getAppLang } from 'utils/language'

type SpeakerProps = {
	filename: string
	isBlack?: boolean
}
export const Speaker = ({ filename, isBlack = true }: SpeakerProps) => {
	const appLang = getAppLang()
	const isEnglish = appLang === 'en'
	const path = `/audios/${appLang}/${filename}`
	const [play] = useSound(path)

	return !isEnglish ? (
		<button
			type="button"
			onClick={event => {
				console.log(path)
				event.preventDefault()
				event.stopPropagation()
				play()
			}}
			className="max-w-max outline-none px-2 focus:outline-none">
			<SpeakerWaveIcon className={clsx('h-6 w-6', isBlack ? 'text-black' : 'text-white')} />
		</button>
	) : null
}
