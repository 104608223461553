import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 } from 'uuid'

export const initialQueuedState: QueuedState = {
	followups: {},
	mwras: {},
	sales: {},
	meetings: {},
	distributions: {},
	requisitions:{},
	recievedRequisitions:{},
	requisitionsRequestSeniorCrp:{}
}

type QueuedItems = keyof QueuedState

type SyncedItems = {
	[item in QueuedItems]: Array<any>
}

const queuedSlice = createSlice({
	name: 'queued',
	initialState: initialQueuedState,
	reducers: {
		addMWRA(state: QueuedState, action: PayloadAction<MWRARegistrationForm>) {
			const localId = v4()

			return {
				...state,
				mwras: {
					...state.mwras,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},
		addRequisition(state: QueuedState, action: PayloadAction<any>) {
			const localId = v4()

			return {
				...state,
				requisitions: {
					...state.requisitions,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},
		addRequisitionForSeniorCrpReq(state: QueuedState, action: PayloadAction<any>) {
			const localId = v4()

			return {
				...state,
				requisitionsRequestSeniorCrp: {
					...state.requisitionsRequestSeniorCrp,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},
		addRequisitionReceivedNote(state: QueuedState, action: PayloadAction<any>) {
			const localId = v4()

			return {
				...state,
				recievedRequisitions: {
					...state.recievedRequisitions,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},
		addSale(state: QueuedState, action: PayloadAction<Sale>) {
			const localId = v4()

			return {
				...state,
				sales: {
					...state.sales,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},
		addFollowup(state: QueuedState, action: PayloadAction<FollowupForm>) {
			const localId = v4()

			return {
				...state,
				followups: {
					...state.followups,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},
		addMeeting(state: QueuedState, action: PayloadAction<MeetingForm>) {
			const localId = v4()

			return {
				...state,
				meetings: {
					...state.meetings,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},
		addDistribution(state: QueuedState, action: PayloadAction<Distribution>) {
			const localId = v4()

			return {
				...state,
				distributions: {
					...state.distributions,
					[localId]: {
						...action.payload,
						localUniqueId: localId
					}
				}
			}
		},

		removeSyncedItems(state: QueuedState, action: PayloadAction<{ syncedItems: SyncedItems }>) {
			debugger;
		
			let prevMWRAs = { ...state.mwras }
			for (const mwra of action.payload.syncedItems.mwras ?? []) {
				delete prevMWRAs[mwra.localUniqueId as string]
			}

			let prevSales = { ...state.sales }
			for (const sale of action.payload.syncedItems.sales ?? []) {
				delete prevSales[sale.localUniqueId as string]
			}

			let prevMeetings = { ...state.meetings }
			for (const meeting of action.payload.syncedItems.meetings ?? []) {
				delete prevMeetings[meeting.localUniqueId as string]
			}

			let prevFollowups = { ...state.followups }
			for (const meeting of action.payload.syncedItems.followups ?? []) {
				delete prevFollowups[meeting.localUniqueId as string]
			}

			let prevDistributions = { ...state.distributions }
			for (const distribution of action.payload.syncedItems.distributions ?? []) {
				delete prevDistributions[distribution.localUniqueId as string]
			}
			let prevRequisitions = { ...state.requisitions }
			for (const requisition of action.payload.syncedItems.requisitions ?? []) {
				delete prevRequisitions[requisition.localUniqueId as string]
			}
		
			let prevRequisitionsRecievedNote = { ...state.recievedRequisitions }
			for (const recievedRequisition of action.payload.syncedItems.recievedRequisitions ?? []) {
				delete prevRequisitionsRecievedNote[recievedRequisition.localUniqueId as string]
			}
			debugger
			let prevRequisitionsSeniorRequest = { ...state.requisitionsRequestSeniorCrp }
			for (const recievedRequisition of action.payload.syncedItems.requisitionsRequestSeniorCrp ?? []) {
				debugger
				delete prevRequisitionsSeniorRequest[recievedRequisition.localUniqueId as string]
			}

			return {
				...state,
				mwras: prevMWRAs,
				sales: prevSales,
				meetings: prevMeetings,
				followups: prevFollowups,
				distributions: prevDistributions,
				requisitions:prevRequisitions,
				recievedRequisitions:prevRequisitionsRecievedNote,
				requisitionsRequestSeniorCrp:prevRequisitionsSeniorRequest

			}
		}
	},
	extraReducers: {}
})

const { reducer } = queuedSlice

export const { addFollowup, addMeeting, addMWRA, addSale, addDistribution, removeSyncedItems,addRequisition,addRequisitionReceivedNote,addRequisitionForSeniorCrpReq } =
	queuedSlice.actions

export default reducer
