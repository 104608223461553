import { Speaker } from 'components/audio-player'

interface LabelProps {
	labelText: string
	filename: string
}

export const Label = ({ labelText, filename }: LabelProps) => {
	return (
		<div className="flex items-center">
			<Speaker filename={filename} />
			<span className="px-1 pt-1.5">{labelText}</span>
		</div>
	)
}
