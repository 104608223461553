// eslint-disable-next-line
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line
import authService from "services/auth-service";

type AuthUser = User & CRP;

export const login = createAsyncThunk("login.php", async (user: { username: string; password: string }, thunkAPI) => {
  try {
    const response = await authService.login(user.username, user.password);
    if (response.status == "ok") {
      return thunkAPI.fulfillWithValue({ ...response.data });
    }
    return thunkAPI.rejectWithValue(false);
  } catch (error: any) {
    console.log("in this ");
    // return thunkAPI.fulfillWithValue({
    // 	"jwt": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJtaWNhcmUiLCJleHAiOjE3MjEzNzU1NzksImlhdCI6MTY5ODA0NzU3OSwiaXNzIjoibWljYXJlIiwianRpIjoiNGFhZTFlMWYtYmVkMi00MzZhLWFhNGMtODYzMmE1ODU1NDM0IiwibmJmIjoxNjk4MDQ3NTc4LCJzdWIiOiI3IiwidHlwIjoiYWNjZXNzIn0.7T4QTEcMZMtqkVJ7i3qc7FbO4mwuFPE4UN2cPUtqiEmRPC-oR4GgK6n6F5VfnzmM6qXf24c8qjPbYQKonUmAJg",
    // 	"user": {
    // 		"address": "Mir Nihal Khan Lashari",
    // 		"adminId": 2,
    // 		"cboId": 1,
    // 		"cluster": {
    // 			"bastis": [
    // 				{
    // 					"clusterId": 1,
    // 					"id": 1,
    // 					"nameEn": "Nihal Khan Lashari",
    // 					"nameSd": "نهال خان لاشاري",
    // 					"nameUr": "نہال خان لاشاری",
    // 					"totalHouseholds": 250,
    // 					"totalPopulation": 1625
    // 				}
    // 			],
    // 			"code": "JCD-001",
    // 			"disabled": false,
    // 			"id": 1,
    // 			"nameEn": "Mir Nihal Khan Lashari",
    // 			"nameSd": "نھال خان لاشاري",
    // 			"nameUr": "میر نہال خان لاشاری",
    // 			"totalPopulation": 0,
    // 			"unionCouncilId": 1,
    // 			"villageNameEn": "Mir Nihal Khan Lashari",
    // 			"villageNameSd": "نھال خان لاشاري",
    // 			"villageNameUr": "میر نہال خان لاشاری"
    // 		},
    // 		"email": null,
    // 		"id": 1,
    // 		"isSenior": false,
    // 		"latlng": null,
    // 		"name": "Zainab Khaton",
    // 		"notes": null,
    // 		"permissions": {},
    // 		"phone": "0300-3072302",
    // 		"settings": {},
    // 		"title": "crp",
    // 		"uid": 7,
    // 		"username": "JCD001"
    // 	}
    // })

    return thunkAPI.rejectWithValue(false);
  }
});

const initialState = {} as AuthState;
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
  /* eslint-disable no-restricted-globals */
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    updateProfile(state, action: PayloadAction<{ user: CRP }>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.user,
        },
      };
    },
  },
  

  extraReducers: (builder) => {
	builder
	  .addCase(
		login.fulfilled,
		(state: AuthState, action: PayloadAction<{ jwt: string; user: AuthUser }>) => {
		  const { uid, username, title, ...profile } = action.payload.user;
  
		  return {
			uid,
			username,
			title,
			jwt: action.payload.jwt,
			profile: profile,
		  };
		}
	  )
	  .addCase(login.rejected, (state: AuthState, action: PayloadAction<any>) => {
		return initialState;
	  });
  },
  
});


const { reducer } = authSlice;

export const { updateProfile } = authSlice.actions;

export default reducer;
