import axios from 'services/axios'

const getFollowupsByCRP = (id: ID) => {
	return axios.get(`/getFollowups.php`).then(response => {
		return response.data
	})
}
//https://api-test.micare.pk/services/saveFollowup.php For Followups


const createFollowup = (followup: FollowupForm) => {
	return axios.post('/saveFollowup.php', {
		followup
	})
}

const followupService = {
	createFollowup,
	getFollowupsByCRP
}

export default followupService
