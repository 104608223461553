import { Disclosure } from '@headlessui/react'
import { DateTime } from 'luxon'

import { useEffect, useState } from 'react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { CustomFilter } from 'components/app/filter'

import { AppLayout } from 'components/app/layout'
import { Checkbox } from 'components/inputs/checkbox'
import { Input, InputNumber } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { StatsPeriodTypes, conditions, contraceptives, incomeRange } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import registrationService from 'services/registration-service'
import { saveMwras } from 'slices/db'
import { getLocalizedName, getTKey, t } from 'utils/language'
import { getDirection, getRegistrationNumber } from 'utils/string'
import locationService from '../../services/location-service'
import { OrderDetail } from '../../components/cards/order-detail'
import { Modal } from 'components/modals/modal'
import { RadioInput } from 'components/app/radio'
import { ButtonOkay } from 'components/buttons/button-ok'
const tKey = getTKey('plw')

interface CRPFormValues {
	clusterCodeId: string
	crpName: string
	unionCouncilId: string
	tehsilId: string
	districtId: string
	bastiId: string
	orderDate: string
	paymentTrackingId: string
	orderNo: string
	products: any
}

const getDateRange = (view: StatsPeriodTypes) => {
	const currentDate = DateTime.now().toISODate();
  
	if (view === StatsPeriodTypes.TODAY) {
	  return [currentDate, currentDate];
	}
  
	if (view === StatsPeriodTypes.YESTERDAY) {
	  const yesterday = DateTime.now().minus({ days: 1 }).toISODate();
	  return [yesterday, yesterday];
	}
  
	if (view === StatsPeriodTypes.THIS_WEEK) {
	  const startOfWeek = DateTime.now().startOf("week").toISODate();
	  return [startOfWeek, currentDate];
	}
  
	if (view === StatsPeriodTypes.LAST_WEEK) {
	  const startOfLastWeek = DateTime.now().minus({ weeks: 1 }).startOf("week").toISODate();
	  const endOfLastWeek = DateTime.now().minus({ weeks: 1 }).endOf("week").toISODate();
	  return [startOfLastWeek, endOfLastWeek];
	}
  
	if (view === StatsPeriodTypes.THIS_MONTH) {
	  const startOfMonth = DateTime.now().startOf("month").toISODate();
	  return [startOfMonth, currentDate];
	}
  
	if (view === StatsPeriodTypes.LAST_MONTH) {
	  const startOfLastMonth = DateTime.now().minus({ months: 1 }).startOf("month").toISODate();
	  const endOfLastMonth = DateTime.now().minus({ months: 1 }).endOf("month").toISODate();
	  return [startOfLastMonth, endOfLastMonth];
	}
  
	if (view === StatsPeriodTypes.LAST_3_MONTHS) {
	  const startOfLast3Months = DateTime.now().minus({ months: 3 }).startOf("month").toISODate();
	  return [startOfLast3Months, currentDate];
	}
  
	if (view === StatsPeriodTypes.THIS_YEAR) {
	  const startOfYear = DateTime.now().startOf("year").toISODate();
	  return [startOfYear, currentDate];
	}
  
	if (view === StatsPeriodTypes.LAST_YEAR) {
	  const startOfLastYear = DateTime.now().minus({ years: 1 }).startOf("year").toISODate();
	  const endOfLastYear = DateTime.now().minus({ years: 1 }).endOf("year").toISODate();
	  return [startOfLastYear, endOfLastYear];
	}
  
	// For CUSTOM_DATE and any other unspecified type, default to the whole year.
	const startOfYear = DateTime.now().startOf("year").toISODate();
	return [startOfYear, currentDate];
  };

export const CRPFormViewOrder = () => {
	//Object.values(ordersObject)
	const requisitionsData = useAppSelector(state => Object.values(state.db.requisitions));
	
	console.log("requisitionsData",requisitionsData)

	const navigate = useNavigate()

	const [params, setParam] = useSearchParams()
	const [successModal, setSuccessModal] = useState<boolean>(false)
	const [showModal, setShowModal] = useState(false)
  const [statsPeriod, setStatsPeriod] = useState<StatsPeriodTypes>(
    (params.get("stats-period") as StatsPeriodTypes) ?? StatsPeriodTypes.THIS_WEEK
  );
  
  const [customStartDate, setCustomStartDate] = useState(DateTime.now().toISODate());
  const [customEndDate, setCustomEndDate] = useState(DateTime.now().toISODate());
  
  // Initialize startDate and endDate based on the current date
  const [startDate, setStartDate] = useState(() => {
    return statsPeriod === StatsPeriodTypes.CUSTOM_DATE ? customStartDate : getDateRange(statsPeriod)[0];
  });
  
  const [endDate, setEndDate] = useState(() => {
    return statsPeriod === StatsPeriodTypes.CUSTOM_DATE ? customEndDate : getDateRange(statsPeriod)[1];
  });
  

	//

	const dispatch = useAppDispatch()
	const { id } = useParams()
	
	const [filteredOrderDetails, setFilteredOrderDetails] = useState<any[]>([])


	
	const [juniorCrpsDetails, setJuniorCrpsDetails] = useState<any>(requisitionsData)

	const { profile: crp } = useAppSelector(state => state.auth)
	const { connected: isConnectedToInternet } = useAppSelector(state => state.db)
	const mwras = useAppSelector(state => state.db.mwras)
	const mwra = id ? mwras[parseInt(id)] : undefined
	// let productsDataFinal:any = [];
	// useEffect(() => {
	// 	locationService
	// 		.getLocation()
	// 		.then(response => setLocationData(response))

	// }, [])

	useEffect(() => {
		applyDateFilter()
	}, [])

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		getValues,
		trigger,
		control
	} = useForm<CRPFormValues>({
		defaultValues: {
			clusterCodeId: '',
			crpName: crp?.name,
			unionCouncilId: '',
			tehsilId: '',
			districtId: '',
			bastiId: '',
			orderDate: '',
			paymentTrackingId: '',
			orderNo: ''
		},

		mode: 'all'
	})

	const onSubmit = handleSubmit(async data => {
		// debugger
		setSuccessModal(true)
	})

	const dir = getDirection()

	//t(tKey('labels.orderNo'))

	const radioItems= {
		[StatsPeriodTypes.TODAY]: t('viewOrderSummary.modalSummary.radioToday'),
		[StatsPeriodTypes.YESTERDAY]: t('viewOrderSummary.modalSummary.radioYesterday'),
		[StatsPeriodTypes.THIS_WEEK]: t('viewOrderSummary.modalSummary.radioThisWeek'),
		[StatsPeriodTypes.LAST_WEEK]: t('viewOrderSummary.modalSummary.radioLastWeek'),
		[StatsPeriodTypes.THIS_MONTH]: t('viewOrderSummary.modalSummary.radioThisMonth'),
		[StatsPeriodTypes.LAST_MONTH]: t('viewOrderSummary.modalSummary.radioLastMonth'),
		[StatsPeriodTypes.LAST_3_MONTHS]: t('viewOrderSummary.modalSummary.radioLast3Months'),
		[StatsPeriodTypes.THIS_YEAR]: t('viewOrderSummary.modalSummary.radioThisYear'),
		[StatsPeriodTypes.LAST_YEAR]: t('viewOrderSummary.modalSummary.radioLastYear'),
		[StatsPeriodTypes.CUSTOM_DATE]: t('viewOrderSummary.modalSummary.radioSelectDate'),
	  };

	const handleStatsPeriodChange = (selectedPeriod: StatsPeriodTypes) => {
		setStatsPeriod(selectedPeriod)
		const [newStartDate, newEndDate] =
			selectedPeriod === StatsPeriodTypes.CUSTOM_DATE
				? [startDate, endDate]
				: getDateRange(selectedPeriod)
		setStartDate(newStartDate)
		setEndDate(newEndDate)
	}

	const openModal = () => {
		setShowModal(true)
	}

	const closeModal = () => {
		setShowModal(false)
	}
	const applyDateFilter = () => {

		console.log("customStartDate", customStartDate);
		console.log("customEndDate", customEndDate);
	  
		let startDateToUse: string | undefined;
		let endDateToUse: string | undefined;
	  
		// Check if the custom date range is selected
		if (statsPeriod === StatsPeriodTypes.CUSTOM_DATE) {
		  // Use custom date range states
		  startDateToUse = customStartDate;
		  endDateToUse = customEndDate;
	  
		  // Check if the custom date range is valid
		  
		  // if (startDateToUse > endDateToUse || endDateToUse > DateTime.now().toISODate()) {
		  //   // Handle invalid date range (you can show a message or take appropriate action)
		  //   return;
		  // }
		  if (startDateToUse > endDateToUse ) {
			// Handle invalid date range (you can show a message or take appropriate action)
			return;
		  }
		} else {
		  // Use the previous date range states
		  [startDateToUse, endDateToUse] = getDateRange(statsPeriod);

		}
	  
		console.log("filteredDetails", juniorCrpsDetails);
		// Filter orderDetail based on the selected date range and additional filters
		const filteredDetails =
		  juniorCrpsDetails?.filter((item: any) => {
			if (!startDateToUse || !endDateToUse) {
			  return true; // Include all items if startDate or endDate is not set
			}
	  
			const orderDate = DateTime.fromISO(item.orderDate, { zone: "utc" }).toISODate();
			console.log("orderDate >= startDateToUse && orderDate <= endDateToUse orderDate", orderDate);
			console.log("orderDate >= startDateToUse && orderDate <= endDateToUse orderDate", typeof endDateToUse);
			console.log("orderDate >= startDateToUse && orderDate <= endDateToUse orderDate", startDateToUse);
			return orderDate >= startDateToUse && orderDate <= endDateToUse;
		  }) ?? [];
	  
		console.log("filteredDetails filteredDetails", filteredDetails);

		filteredDetails.sort((a:any, b:any) => {
			const dateA:any = new Date(a.orderDate);
			const dateB:any = new Date(b.orderDate);
			return dateA - dateB;
		});
	  
		// Reverse the order to make it descending
		filteredDetails.reverse();

		// Update the filtered order details state
		setFilteredOrderDetails(filteredDetails);
	  
		// Additional filters can be applied here as needed
	  
		// Close the modal
		closeModal();
	  };

	return (
		<AppLayout showHeader title={t('edit.labels.mwraDetails')}>
			<div dir={dir} className="w-full">
				<form
					onSubmit={event => {
						onSubmit(event)
					}}
					className="mx-auto w-full pb-4 rounded-2xl space-y-4">
					<button
						onClick={() => navigate('/requisitions')}
						className="text-md  w-1/2 block mx-auto text-center rounded-md bg-green-500 font-bold px-8 py-2 text-white">
						+ {t('viewOrderSummary.add-new-requisition')}
					</button>
					<div className={clsx('flex space-x-1 items-center mb-2')}>
						<h1 className=" text-lg font-semibold inline-flex">{t(`viewOrderSummary.orderDetail`)}</h1>
						<CustomFilter
							title={radioItems[statsPeriod] ?? t('viewOrderSummary.modalSummary.radioWeek')}
							onClick={() => setShowModal(true)}
						/>
					</div>
					<p>
						{statsPeriod === StatsPeriodTypes.CUSTOM_DATE
							? `${t('viewOrderSummary.custom-date')}: ${customStartDate} ${t('viewOrderSummary.dateTo')}  ${customEndDate}`
							: `${t('viewOrderSummary.for-date-range')}: ${startDate} ${t('viewOrderSummary.dateTo')}  ${endDate}`}
					</p>
					<p>{t('viewOrderSummary.totalOrders')}: {filteredOrderDetails.length} </p>
					{filteredOrderDetails && filteredOrderDetails.length > 0 ? (
						(filteredOrderDetails ?? []).map((item: any) => (
							<div
										className="bg-white shadow p-4"
										style={{ backgroundColor: '#dfeffe', borderRadius: '10px' }}>
											<div>
												{/* <p>{t("viewOrderSummary.orderDetails.requisitionNo")}: {item.reqNo}</p> */}
												<p>{t("viewOrderSummary.orderDetails.orderNo")}: {item.crpReqNo}</p>
												<p>{t("viewOrderSummary.orderDetails.orderDate")}: {item.orderDate}</p>
												{/* <p>Status: "pending"</p> */}
												{/* <p>
													Total Quantity :{' '}
													{item.orderedProducts.reduce(
														(acc: any, product: any) => acc + product.quantity,
														0
													)}
												</p> */}
												<p>
												{t("viewOrderSummary.orderDetails.subtotalPKR")} :{' '}
													{item.orderedProducts.reduce(
														(acc: any, product: any) => acc + product.quantity * product.unitPrice,
														0
													)}
												</p>
											</div>
											{/* Inside the Disclosure.Panel for the junior CRPs' order details */}
											<div style={{ overflowX: 'auto' }}>
												<table className="w-full border-collapse border border-gray-300">
													<thead className="bg-gray-200" style={{ backgroundColor: '#8cc4f9' }}>
														<tr>
															<th className="border border-gray-300 py-1 px-2">{t("viewOrderSummary.orderDetails.productName")} </th>

															<th className="border border-gray-300 py-1 px-2">{t("viewOrderSummary.orderDetails.quantity")} </th>
															<th className="border border-gray-300 py-1 px-2">{t("viewOrderSummary.orderDetails.unitRate")} </th>
															<th className="border border-gray-300 py-1 px-2">{t("viewOrderSummary.orderDetails.amount")}</th>
														</tr>
													</thead>
													<tbody>
														{item.orderedProducts.map((product: any) => (
															<tr key={product.srNo}>
															<td className="border border-gray-300 py-2 px-4">
															  {product.productname}
															</td>
															<td className="border border-gray-300 py-2 px-4 text-right">
															  {Math.round(product.quantity)}
															</td>
															<td className="border border-gray-300 py-2 px-4 text-right">
															  {Math.round(product.unitPrice)}
															</td>
															<td className="border border-gray-300 py-2 px-4 text-right">
															  {Math.round(product.quantity * product.unitPrice)}
															</td>
														  </tr>
														  
														))}
													</tbody>
												</table>
											</div>
									</div>
						))
					) : (
						<p>
							{statsPeriod === StatsPeriodTypes.CUSTOM_DATE
								? `${t('viewOrderSummary.no-requests-found')} ${t('viewOrderSummary.for-custom-date-range')}: ${customStartDate} ${t('viewOrderSummary.to')} ${customEndDate}`
								: `${t('viewOrderSummary.no-requests-found')} ${t('viewOrderSummary.for-date-range')}: ${startDate} ${t('viewOrderSummary.dateTo')} ${endDate}`}
						</p>
					)}

					{showModal && (
						<Modal title={t('viewOrderSummary.pickDateRange')} onClose={closeModal}>
							<div dir={dir} className="w-full bg-white p-4">
								<div className="">
									<div className="space-y-2 mt-4">
										{Object.entries(radioItems).map(([id, labelText], index) => (
											<RadioInput
												key={index}
												onChange={() => {
													setParam({ 'stats-period': id }, { replace: true })
													handleStatsPeriodChange(id as StatsPeriodTypes)
												}}
												checked={statsPeriod === id}
												labelText={labelText}
											/>
										))}
										<ButtonOkay onClick={applyDateFilter} title={t('buttons.okay')} />
									</div>
									{statsPeriod === StatsPeriodTypes.CUSTOM_DATE && (
										<div dir={dir} className={clsx('flex-col space-y-2 px-2 text-sm mt-4')}>
											<Input
												labelText="Start Date"
												type="date"
												name="customStartDate"
												onChange={event => setCustomStartDate(event.target.value)}
												value={customStartDate}
											/>
											<Input
												labelText="End Date"
												type="date"
												name="customEndDate"
												onChange={event => setCustomEndDate(event.target.value)}
												value={customEndDate}
											/>
										</div>
									)}
								</div>
							</div>
						</Modal>
					)}

					{/* {isDirty && ( */}

					{/* )} */}
				</form>
			</div>
		</AppLayout>
	)
}
