import axios from 'services/axios'

const createDistribution = (distribution: Distribution) => {
	return axios.post('/stocks/distributions', {
		distribution
	})
}

const stocksService = {
	createDistribution
}

export default stocksService
