import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialDbState: DbState = {
	mwras: {},
	sales: {},
	products: {},
	targets: {},
	followups: {},
	connected: false,
	lastSyncedOn: new Date().getTime(),
	fieldVisits: {
		mwras: []
	},
	assignedCRPs: {},
	lastLocalAutoId: 0,
	requisitions:{},
	recievedRequisitions:{},
	grns:{},
	stockTransfersData:{},
	requisitionsForSenior:{},
	requisitionsRequestSeniorCrp:{},
	stockStatus:{},
	isSyncedOn:true

}

const queueSlice = createSlice({
	name: 'db',
	initialState: initialDbState,
	reducers: {
		toggleNetworkConnectivity(state, action: PayloadAction<{ state: boolean }>) {
			return {
				...state,
				connected: action.payload.state
			}
		},
		updateLastSyncOn(state, action: PayloadAction<{ time: number }>) {
			return {
				...state,
				lastSyncedOn: action.payload.time
			}
		},
		saveMwras(state, action: PayloadAction<MWRARegistrationForm[]>) {
			const mwras = action.payload.reduce<MWRAs>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})



			return {
				...state,
				mwras
			}
		},
		saveRequisitions(state, action: PayloadAction<any[]>) {
	
			const requisitions = action.payload.reduce<any>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})


			return {
				...state,
				requisitions
			}
		},
		saveRequisitionsForSeniorCrp(state, action: PayloadAction<any[]>) {
	
			const requisitionsRequestSeniorCrp = action.payload.reduce<any>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})


			return {
				...state,
				requisitionsRequestSeniorCrp
			}
		},
		saveGrns(state, action: PayloadAction<any[]>) {
	
			const grns = action.payload.reduce<any>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})


			return {
				...state,
				grns
			}
		},
		saveStockTransfers(state, action: PayloadAction<any[]>) {
	
			const stockTransfersData = action.payload.reduce<any>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})


			return {
				...state,
				stockTransfersData
			}
		},
		saveStockStatus(state, action: PayloadAction<any[]>) {
	
			const stockStatus = action.payload.reduce<any>((agg, curr) => {
	
				return { ...agg, [curr.productId]: curr }
			}, {})


			return {
				...state,
				stockStatus
			}
		},
		updateMWRA(state, action: PayloadAction<FollowupForm>) {
			const followup = action.payload

			let changes: Partial<MWRA> = {}

			if (!followup.muac) {
				changes = {
					...changes,
					muac: followup.muac
				}
			}

			if (followup.isWomanConditionChanged) {
				changes = {
					...changes,
					condition: followup.condition
				}
			}

			if (followup.condition === 'pregnant') {
				changes = {
					...changes,
					expectedDeliveryDate: followup.expectedDeliveryDate,
					isMeetingWithAnc: followup.isMeetingWithAnc
				}
			}

			if (followup.condition === 'lactating' || followup.condition === 'mwra') {
				changes = {
					...changes,
					condition: followup.condition,
					childrenInfo: followup.childrenInfo,
					totalFamilyMembers: followup.totalFamilyMembers,
					isFamilyPlanning: followup.isFamilyPlanning,
					contraceptiveMethods: followup.contraceptiveMethods
				}
			}

			return {
				...state,
				mwras: {
					...state.mwras,
					[followup.mwraId as ID]: {
						...state.mwras[followup.mwraId as ID],
						...changes
					}
				}
			}
		},

		saveProducts(state, action: PayloadAction<Product[]>) {
			const products = action.payload.reduce<Products>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})

			return { ...state, products }
		},
		saveTargets(state, action: PayloadAction<Target[]>) {
			const targets = action.payload.reduce<Targets>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})

			return { ...state, targets }
		},
		saveSales(state, action: PayloadAction<Sale[]>) {
			const sales = action.payload.reduce<Sales>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})

			return {
				...state,
				sales
			}
		},
		
		saveFollowups(state, action: PayloadAction<Followup[]>) {
			
			const followups = action.payload.reduce<Followups>((agg, curr) => {
				
				return { ...agg, [curr.id]: curr }
			}, {})
		

			return {
				...state,
				followups
			}
		},
		updateLocationInfo(state, action: PayloadAction<{ locationInfo: DbState['locationInfo'] }>) {
			return {
				...state,
				locationInfo: action.payload.locationInfo
			}
		},
		pinMWRAForFieldVisit(state, action: PayloadAction<{ id: ID }>) {
			return {
				...state,
				fieldVisits: {
					...(state.fieldVisits ?? {}),
					mwras: [...(state.fieldVisits?.mwras ?? []), action.payload.id]
				}
			}
		},
		unpinMWRAForFieldVisit(state, action: PayloadAction<{ id: ID }>) {
			const mwras = (state.fieldVisits?.mwras ?? []).filter(mwraId => mwraId !== action.payload.id)

			return {
				...state,
				fieldVisits: {
					...(state.fieldVisits ?? {}),
					mwras
				}
			}
		},
		saveAssignedCRPs(state, action: PayloadAction<CRP[]>) {
			const crps = action.payload.reduce<AssignedCRPs>((agg, curr) => {
				return { ...agg, [curr.id]: curr }
			}, {})

			return { ...state, assignedCRPs: crps }
		},
		updateLastLocalAutoId(state, action: PayloadAction<number>) {
			return {
				...state,
				lastLocalAutoId: action.payload
			}
		},
		saveSycingOn(state, action: PayloadAction<any>) {
			return {
				...state,
				isSyncedOn:action.payload
			}
		}
	},
	extraReducers: {}
})

const { reducer } = queueSlice

export const {
	saveMwras,
	saveProducts,
	saveTargets,
	saveSales,
	saveFollowups,
	updateLastSyncOn,
	toggleNetworkConnectivity,
	updateLocationInfo,
	pinMWRAForFieldVisit,
	unpinMWRAForFieldVisit,
	saveAssignedCRPs,
	updateLastLocalAutoId,
	updateMWRA,
	saveRequisitions,
	saveRequisitionsForSeniorCrp,
	saveGrns,
	saveStockTransfers,
	saveStockStatus,
	saveSycingOn
} = queueSlice.actions

export default reducer
