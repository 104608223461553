import { Disclosure } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { Modal } from 'components/modals/modal'
import { AppLayout } from 'components/app/layout'
import { Checkbox } from 'components/inputs/checkbox'
import { Input, InputNumber } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { conditions, contraceptives, incomeRange } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import registrationService from 'services/registration-service'
import { saveMwras } from 'slices/db'
import { getLocalizedName, getTKey, t } from 'utils/language'
import { getDirection, getRegistrationNumber } from 'utils/string'
import locationService from './../../services/location-service'
import { OrderDetail } from './../../components/cards/order-detail'
import { addRequisitionReceivedNote } from 'slices/queued'
const tKey = getTKey('plw')

interface CRPFormValues {
	clusterCodeId: string
	crpName: string
	unionCouncilId: string
	tehsilId: string
	districtId: string
	bastiId: string
	orderDate: string
	paymentTrackingId: string
	orderNo: string
	products: any
}

interface Product {
	quantity: number
	price: number
	// Add other properties as needed
}

export const AddGoodReceivedNote = () => {
	const reequisitionsData = useAppSelector(state => state.db.requisitions)
	const productsData = useAppSelector(state => Object.values(state.db.products))
	const [reqNos, setReqNos] = useState<any>([])
	const [requisitionNumber, setRequisitionNumber] = useState<any>('');
	const [selectedOrderId, setSelectedOrderId] = useState<string>('');
	const [paymentTrackingId, setPaymentTrackingId] = useState('')

	// const products = useAppSelector(state => state.db.products)


	const dispatch = useAppDispatch()
	const { id } = useParams()

	const [amount, setAmount] = useState<any>(null)
	const [successModal, setSuccessModal] = useState<boolean>(false)
	const [productPrices, setProductQuantities] = useState({}) // State to manage product prices
	console.log('products', productPrices)

	useEffect(() => {
		// Extract reqNo values from reequisitionsData and set in the state
		console.log('reequisitionsData', reequisitionsData)

		if (reequisitionsData) {
			const extractedReqNos: any = Object.values(reequisitionsData).map((order: any) => ({
				id: order.id,
				reqNo: order.reqNo
			}))
			setReqNos(extractedReqNos)
		}
	}, [reequisitionsData])
	useEffect(() => {
		console.log('reqNos', reqNos)
	}, [reqNos])

	const [productsDataFinal, setProductsDataFinal] = useState<any>([])

	
	console.log(productsData, 'productsData')
	const { profile: crp } = useAppSelector(state => state.auth)
	const { connected: isConnectedToInternet } = useAppSelector(state => state.db)
	const mwras = useAppSelector(state => state.db.mwras)
	const mwra = id ? mwras[parseInt(id)] : undefined
	// let productsDataFinal:any = [];
	// useEffect(() => {
	// 	locationService
	// 		.getLocation()
	// 		.then(response => setLocationData(response))

	// }, [])
	console.log('crp', crp)

	// useEffect(() => {
	// 	if (districtId) {
	// 		if (locationData) {
	// 			for (let dis of locationData?.data) {
	// 				if (dis.id == districtId) {
	// 					setTehsilData(dis?.tehsils)
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [districtId])
	// useEffect(() => {
	// 	console.log("productsDataFinal",productsDataFinal)
	// }, [productsDataFinal])
	// useEffect(() => {
	// 	if (tehsilId) {
	// 		if (tehsilData) {
	// 			for (let teh of tehsilData) {
	// 				if (teh?.id == tehsilId) {
	// 					setUnionConcilData(teh?.unionCouncils)
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [tehsilId])
	// useEffect(() => {
	// 	if (unionConcilId) {
	// 		if (unionConcilData) {
	// 			for (let uni of unionConcilData) {
	// 				if (uni?.id == unionConcilId) {
	// 					setClusterData(uni?.clusters)
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [unionConcilId])
	// useEffect(() => {
	// 	if (clusterId) {
	// 		if (clusterData) {
	// 			for (let clus of clusterData) {
	// 				if (clus?.id == clusterId) {
	// 					setBastiData(clus?.bastis)
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [clusterId])
	const schema = yup.object().shape({
		products: yup.array().min(1, 'Please add at least one product') // Assuming products is an array
	})

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isDirty },
		getValues,
		trigger,
		control
	} = useForm<CRPFormValues>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			orderDate: '',
			paymentTrackingId: '',
			orderNo: ''
		},

		mode: 'all'
	})

	const onSubmit = handleSubmit(async data => {
		try {
			const isValid = await trigger() // Trigger validation for all fields

			if (!productsDataFinal || productsDataFinal.length === 0) {
				toast.error('Please add at least one product before submitting.')
				return
			}

			console.log('data', data)

			const crpForm: CRPFormValues = {
				clusterCodeId: data.clusterCodeId,
				crpName: data.crpName,
				unionCouncilId: data.unionCouncilId,
				tehsilId: data.tehsilId,
				districtId: data.districtId,
				bastiId: data.bastiId,
				orderDate: data.orderDate,
				paymentTrackingId: data.paymentTrackingId,
				orderNo: data.orderNo,
				products: productsDataFinal
			}
			console.log(crpForm, 'crpForm')

			setSuccessModal(true)
			// Perform your API call or data submission here
			// Example: if (isConnectedToInternet) { ... }
		} catch (error) {
			console.error('Error:', error)
			toast.error('An error occurred while processing your request.')
		}
	})

	const dir = getDirection()

	const handleDecrement = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
		let quantityElement = document.getElementById(`quantity_${index}`) as HTMLInputElement | null

		if (quantityElement) {
			let updatedQuantity = parseInt(quantityElement.value, 10) - 1

			// Check if the updated quantity is not less than 0
			if (updatedQuantity < 0) {
				updatedQuantity = 0 // Set the quantity to 0 if it would be negative
			}

			quantityElement.value = updatedQuantity.toString()

			let totalAmountId = document.getElementById(`totalAmount_${index}`) as HTMLDivElement | null

			// Ensure the price remains non-negative
			const price = Math.max(productsData[index]?.price, 0)
			const totalAmount = price * updatedQuantity

			if (totalAmountId) {
				totalAmountId.innerHTML = totalAmount.toString()
			}

			// Find the index of the product in productsDataFinal based on productId
			const productIndexInFinal = productsDataFinal.findIndex(
				(product: any) => product.productId === productsData[index]?.id
			)

			if (productIndexInFinal !== -1) {
				// Update the product in productsDataFinal with new quantity or remove it if quantity becomes 0
				if (updatedQuantity === 0) {
					// Remove the product from productsDataFinal if quantity becomes 0
					productsDataFinal.splice(productIndexInFinal, 1)
				} else {
					// Update the product in productsDataFinal with new quantity
					productsDataFinal[productIndexInFinal] = {
						productId: productsData[index].id,
						quantity: updatedQuantity,
						amount: price,
						totalAmount: totalAmount
					}
				}
			}

			setProductsDataFinal([...productsDataFinal]) // Update the state with the modified array
		}
	}
	const handleChange = (e: any) => {
		const value = e.target.value
		setPaymentTrackingId(value)
		// You can perform additional actions if needed
	}

	const handleIncrement = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
		let quantityElement = document.getElementById(`quantity_${index}`) as HTMLInputElement | null

		if (quantityElement) {
			let updatedQuantity = parseInt(quantityElement.value, 10) + 1
			quantityElement.value = updatedQuantity.toString()

			// Find the index of the product in productsDataFinal based on productId
			const productIndexInFinal = productsDataFinal.findIndex(
				(product: any) => product.productId === productsData[index]?.id
			)

			let totalAmountId = document.getElementById(`totalAmount_${index}`) as HTMLDivElement | null

			// Ensure the price remains non-negative
			const price = Math.max(productsData[index]?.price, 0)
			const totalAmount = price * updatedQuantity

			if (totalAmountId) {
				totalAmountId.innerHTML = totalAmount.toString()
			}

			if (productIndexInFinal !== -1) {
				// Update the quantity of the existing product in productsDataFinal
				productsDataFinal[productIndexInFinal].quantity = updatedQuantity
				productsDataFinal[productIndexInFinal].totalAmount =
					productsData[index]?.price * updatedQuantity
			} else {
				// Add the new product to productsDataFinal
				productsDataFinal.push({
					productId: productsData[index].id,
					quantity: updatedQuantity,
					amount: productsData[index]?.price,
					totalAmount: productsData[index]?.price * updatedQuantity
				})
			}

			const newArray = Object.values(productsDataFinal).filter(Boolean)
			setProductsDataFinal(newArray)
		}
	}

	const calculateTotals = (products: any) => {
		let totalQuantity = 0
		let totalPrice = 0

		products.forEach((product: any) => {
			totalQuantity += product.quantity || product.quantity || 0 // Using the quantity or quantity property, whichever exists
			totalPrice += product.totalAmount || 0
		})

		return { totalQuantity, totalPrice }
	}

	const handleSubmitConfirm = () => {
		console.log('confirmation true')
		setSuccessModal(false)
		toast.success('your form is submitted successfully')
		
		dispatch(addRequisitionReceivedNote({
			paymentTracking: paymentTrackingId,
			requisitionId: parseInt(selectedOrderId, 10),
			products: productsDataFinal
		}))
	}

	return (
		<AppLayout showHeader title={t('edit.labels.mwraDetails')}>
			<div dir={dir} className="w-full">
				<form
					onSubmit={event => {
						onSubmit(event)
					}}
					className="mx-auto w-full pb-4 rounded-2xl space-y-4">
					<Disclosure defaultOpen={true}>
						{({ open }) => (
							<div className="bg-white shadow p-4">
								<Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left font-medium text-slate-900 focus:outline-none">
									<span>{t('edit.labels.personalDetails')}</span>
									<ChevronUpIcon
										className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-slate-500`}
									/>
								</Disclosure.Button>
								<Disclosure.Panel className="px-4 divide-y py-4 text-gray-500">
									{/* <div>
										<Input
											labelText={t(tKey('labels.clusterCode'))}
											register={register}
											error={errors}
											type="text"
											className="border-none"
											name="clusterCode"
											placeholder={t(tKey('placeholders.clusterCode'))}
										/>
									</div> */}

									{/* <div>
										<Input
											labelText={'Recieving Date'}
											register={register}
											error={errors}
											type="date"
											className="border-none w-full" // Set a common width style, e.g., w-full for full width
											name="orderDate"
											placeholder={t(tKey('placeholders.orderDate'))}
										/>
									</div> */}

									{/* Second input field */}

									<div>
										<Input
											labelText={t(tKey('labels.paymentTrackingId'))}
											register={register}
											error={errors}
											type="text"
											className="border-none w-full"
											name="paymentTrackingId"
											placeholder={t(tKey('placeholders.paymentTrackingId'))}
											value={paymentTrackingId}
											onChange={handleChange}
										/>
									</div>

									{/* Dropdown for requisition numbers */}

									<div className="pb-5">
									<Select
  name="Requisition number"
  className="border-none w-full"
  onChange={(e: any) => {
    const selectedValue = e.target.value;
    setRequisitionNumber(selectedValue); // Set the selected ID
    setSelectedOrderId(selectedValue);
  }}
  value={selectedOrderId} // Use the selected order ID
>
  <option value="">{'Requisition No'}</option>
  {reqNos.map((reqNoObj: any) => (
    <option key={reqNoObj.id} value={reqNoObj.id}>
      {reqNoObj.reqNo}
    </option>
  ))}
</Select>

								
									</div>
									<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
										<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
											<thead className="text-xs text-gray-700 uppercase  dark:text-gray-400">
												<tr>
													<th scope="col" className="px-6 py-3">
														Product
													</th>
													<th scope="col" className="px-6 py-3">
														Qty
													</th>
													<th scope="col" className="px-6 py-3">
														Price
													</th>
												</tr>
											</thead>
											<tbody>
												{productsData &&
													productsData.map((item: any, index: number) => (
														<tr className="bg-white border-b dark:border-gray-700 ">
															<td className="px-6 py-4 font-semibold text-gray-900 text-gray-700">
																{getLocalizedName(item)}
																<p>PKR {item?.price}</p>
															</td>
															<td className="px-6 py-4">
																<div className="flex items-center space-x-3">
																	<button
																		onClick={e => {
																			handleDecrement(e, index)
																		}}
																		className="inline-flex items-center justify-center p-1 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
																		type="button">
																		<span className="sr-only">Quantity button</span>
																		<svg
																			className="w-3 h-3"
																			aria-hidden="true"
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 18 2">
																			<path
																				stroke="currentColor"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				strokeWidth="2"
																				d="M1 1h16"
																			/>
																		</svg>
																	</button>
																	<div>
																		<input
																			type="number"
																			id={`quantity_` + index}
																			defaultValue={0}
																			onChange={e => {
																				setAmount(e.target.value)
																			}}
																			className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
																			placeholder="0"
																			required
																		/>
																	</div>
																	<button
																		onClick={e => {
																			handleIncrement(e, index)
																		}}
																		className="inline-flex items-center justify-center h-6 w-6 p-1 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
																		type="button">
																		<span className="sr-only">Quantity button</span>
																		<svg
																			className="w-3 h-3"
																			aria-hidden="true"
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 18 18">
																			<path
																				stroke="currentColor"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				strokeWidth="2"
																				d="M9 1v16M1 9h16"
																			/>
																		</svg>
																	</button>
																</div>
															</td>
															<td className="px-6 py-4 font-semibold text-gray-900 text-gray-700">
																<div>
																	<input
																		type="text"
																		id={`quantity_${index}`}
																		onChange={e => {
																			setProductQuantities((prevQuantities: any) => ({
																				...prevQuantities,
																				[index]: e.target.value
																			}))
																		}}
																		className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
																		placeholder="Rs"
																	/>
																</div>
																{/* {productsDataFinal.find((item: any) => item.productId === item?.id)?.totalAmount} */}
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>
					{/* {isDirty && ( */}
					<button
						type="submit"
						className="text-md w-1/2 block mx-auto text-center rounded-md bg-green-500 font-bold px-8 py-2 text-white">
						{t('buttons.submit')}
					</button>
					{/* )} */}
				</form>
				{successModal && (
					<Modal
						onClose={() => {
							setSuccessModal(false)
						}}
						showCrossIcon={true}
						className="space-y-6">
						{/* Display summary for productsDataFinal */}
						<div>
							<h3 className="text-xl font-semibold">{'Confirmation '}</h3>
							{/* Add your logic to display product summary based on productsDataFinal */}
							{/* Example: */}
							<div>
								{/* <p>{product.name}</p> */}
								<p>
									Total Quantity Of Products: {calculateTotals(productsDataFinal)?.totalQuantity}
								</p>
								{/* Add more details as needed */}
								{requisitionNumber && <p>Selected Requisition Number: {requisitionNumber}</p>}
							</div>
						</div>

						{/* Confirmation message */}

						{/* Confirm and Cancel buttons */}
						<div className="flex justify-center items-center mt-4">
							<button
								onClick={handleSubmitConfirm}
								type="button"
								className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-[#00cfae]  font-bold rounded-3xl text-md text-center mr-2 mb-2">
								Confirm
							</button>
							<button
								onClick={() => setSuccessModal(false)}
								type="button"
								className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-blue-600  font-bold rounded-3xl text-md text-center mb-2">
								No
							</button>
						</div>
					</Modal>
				)}
			</div>
		</AppLayout>
	)
}
