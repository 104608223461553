import { DateTime } from 'luxon'
import { Link, useParams } from 'react-router-dom'

import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { MWRAInfoCard } from 'components/cards/mwra-info-card'
import { useAppSelector } from 'hooks'
import { getTKey, t } from 'utils/language'
import { getDirection } from 'utils/string'

const tKey = getTKey('sales')

export const SalesHistoryList = () => {
	const { id } = useParams()
	const products = useAppSelector(state => state.db.products);
	console.log("productsproducts",products)
	const mwras = useAppSelector(state => state.db.mwras)
	const sales = useAppSelector(state => (state.db.sales));


	const cluster = useAppSelector(state => state.auth.profile.cluster)

	// TODO: if the id doesn't existing
	// or MWRA doesn't found, show and error page
	const mwra = mwras[parseInt(`${id}`)]
	console.log("mwra mwra",mwra)

	const avvvb = Object.entries(sales).sort(([saleId1, sale1], [saleId2, sale2]) => {
		console.log("saleId1",saleId1)
		console.log("saleId1 sale1",sale1)
		
		
		const datetime1 = DateTime.fromISO(sale1.saleDate)
		const datetime2 = DateTime.fromISO(sale2.saleDate)
		
		console.log("saleId1 sale1 datetime1",datetime1)
		console.log("saleId1 sale1 datetime2",datetime2)
		return datetime1.diff(datetime2).seconds
	})
	console.log("avvvb",avvvb)
	const aggregatedSales = Object.entries(sales)
		.sort(([saleId1, sale1], [saleId2, sale2]) => {
			const datetime1 = DateTime.fromISO(sale1.saleDate)
			const datetime2 = DateTime.fromISO(sale2.saleDate)
			return datetime1.diff(datetime2).seconds
		})
		.reduce(
			(agg, [saleId, sale]) => {
				if (mwra.id !== sale.mwraId) {
					return agg
				}

				const datetime = DateTime.fromISO(sale.saleDate)

				// TODO: convert to urdu here
				const monthName = t(`months.${datetime.monthShort.toLowerCase()}`)
				const key = `${monthName} - ${datetime.year}`

				return { ...agg, [key]: { ...agg[key], [saleId]: sale } }
			},
			{} as {
				[month: string]: Sales
			}
		)

		console.log("aggregatedSales",aggregatedSales)
	const dir = getDirection()

	return (
		<AppLayout showHeader>
			<div dir={dir}>
				<div className="px-2 py-2 space-y-2">
					<div className="ml-1">
						<MWRAInfoCard mwra={mwra} cluster={cluster} />
						<div className="flex items-center">
							<Speaker filename="salesHistory.mp3" />
							<h1 className="font-semibold text-lg pt-2">{t(tKey('labels.main'))}</h1>
						</div>
					</div>
					{Object.entries(aggregatedSales)
						.sort()
						.map(([month, salesInMonth]) => {
							return (
								<div key={month} className="space-y-4">
									<h1 className="text-md font-medium ml-1">{month}</h1>
									{Object.entries(salesInMonth)
										.sort(
											([, a], [, b]) =>
												DateTime.fromISO(b.saleDate).toMillis() -
												DateTime.fromISO(a.saleDate).toMillis()
										)
										.map(([saleId, sale]) => {
											const datetime = DateTime.fromISO(sale.saleDate)

											// TODO: Create reusable localized time utility
											const date = datetime.toFormat('dd/MM/yyyy')
											const time = datetime
												.toFormat('hh:mm a')
												.replace('AM', t('time.am'))
												.replace('PM', t('time.pm'))

											// const productsLeft = sale.saleItems.length - 4

											const productIndexes = sale.saleItems
												.map((saleItem, index) => index)
												.filter(index => index < 4)

											const [totalSoldIProducts, totalAmount] = sale.saleItems.reduce(
												(agg, curr) => [agg[0] + curr.quantity, agg[1] + curr.amount],
												[0, 0]
											)

											return (
												<Link
													key={saleId}
													className={`grid grid-cols-2 grid-rows-2 gap-2 items-center shadow-md border border-gray-200 bg-white rounded-lg p-4 `}
													to={`/mwras/${id}/sales/${saleId}`}>
													<div className="space-y-1">
														<h1 className="font-semibold">{date}</h1>
														<h1 className="font-normal text-sm">{time}</h1>
													</div>
													<div className="flex flex-row">
														{productIndexes.map(index => {
															const saleItem = sale.saleItems[index]

															return (
																<div
																	key={`${saleItem.productId} + ${saleId}`}
																	className="flex justify-center border border-gray-200 bg-white -ml-6 rounded-full h-14 w-14 p-2 ">
																	<img
																		src={products[saleItem.productId ?? 0]?.attachments?.[0].url}
																		className="h-full object-contain"
																	/>
																</div>
															)
														})}
														{/* {productsLeft > 0 && (
													<div
														key={'additional'}
														className="border border-gray-200 bg-white -ml-6 aspect-square h-14 rounded-full p-4">
														+{productsLeft}
													</div>
												)} */}
													</div>
													<div className="space-y-1">
														<h1 className="font-semibold">{t('products.label.totalItems')}</h1>
														<h1 className="font-normal text-sm">{totalSoldIProducts}</h1>
													</div>
													<div className="space-y-1">
														<h1 className="font-semibold">{t('products.label.totalAmount')}</h1>
														<h1 className="font-normal text-sm">
															{totalAmount.toLocaleString()}
															<span> {t('products.label.rupees')}</span>
														</h1>
													</div>
												</Link>
											)
										})}
								</div>
							)
						})}
				</div>
			</div>
		</AppLayout>
	)
}
