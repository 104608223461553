import { useCallback, useRef, useState } from 'react'

interface Options {
	shouldPreventDefault?: boolean
	delay?: number
}

export const useLongPress = (
	onLongPress: (event: any) => void,
	onClick: (event: any) => void,
	options: Options = {}
) => {
	const { shouldPreventDefault = true, delay = 300 } = options
	const [longPressTriggered, setLongPressTriggered] = useState(false)
	const timeout = useRef<NodeJS.Timeout | null>(null)
	const target = useRef<EventTarget | null>(null)

	const start = useCallback(
		(event: any) => {
			if (shouldPreventDefault && event.target) {
				event.target.addEventListener('touchend', preventDefault, {
					passive: false
				})
				target.current = event.target
			}
			timeout.current = setTimeout(() => {
				onLongPress(event)
				setLongPressTriggered(true)
			}, delay)
		},
		[onLongPress, delay, shouldPreventDefault]
	)

	const clear = useCallback(
		(event: any, shouldTriggerClick = true) => {
			timeout.current && clearTimeout(timeout.current)
			shouldTriggerClick && !longPressTriggered && onClick(event)
			setLongPressTriggered(false)
			if (shouldPreventDefault && target.current) {
				target.current.removeEventListener('touchend', preventDefault)
			}
		},
		[shouldPreventDefault, onClick, longPressTriggered]
	)

	return {
		onMouseDown: (e: any) => start(e),
		onTouchStart: (e: any) => start(e),
		onMouseUp: (e: any) => clear(e),
		onMouseLeave: (e: any) => clear(e, false),
		onTouchEnd: (e: any) => clear(e)
	}
}

const isTouchEvent = (event: any) => {
	return 'touches' in event
}

const preventDefault = (event: any) => {
	if (!isTouchEvent(event)) return

	if (event.touches.length < 2 && event.preventDefault) {
		event.preventDefault()
	}
}
