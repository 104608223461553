import axios from 'services/axios'

const getUserProfile = () => {
	return axios.get('/users/profile')
}

const getCRPsBySeniorCRP = (id: ID) => {
	return axios
		.get('/users/crps', {
			params: {
				senior_crp_id: id
			}
		})
		.then(response => response.data)
}

const userService = {
	getUserProfile,
	getCRPsBySeniorCRP
}

export default userService
