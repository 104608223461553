import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'

import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { MWRAInfoCard } from 'components/cards/mwra-info-card'
import { useAppSelector } from 'hooks'
import clsx from 'utils/clsx'
import { getLocalizedName, getTKey, t } from 'utils/language'
import { getDirection } from 'utils/string'

const tKey = getTKey('sales')

export const SaleDetails = () => {
	const { id, saleId } = useParams()
	const products = useAppSelector(state => state.db.products)
	const mwras = useAppSelector(state => state.db.mwras)
	const sales = useAppSelector(state => state.db.sales)
	const cluster = useAppSelector(state => state.auth.profile.cluster)

	// TODO: if id or sale id don't exist or
	// mwra or sale not found, show an error page
	const mwra = mwras[parseInt(`${id}`)]
	const sale = sales[parseInt(`${saleId}`)]

	const datetime = DateTime.fromISO(sale.saleDate)
	const date = datetime.toFormat('dd/MM/yyyy')
	// TODO: Create reusable localized time utility
	const time = datetime.toFormat('hh:mm a').replace('AM', t('time.am')).replace('PM', t('time.pm'))

	const totalMargin = sale.saleItems.reduce((agg, curr) => {
		return agg + products[curr.productId].margin * curr.quantity
	}, 0)

	const [totalSoldProducts, totalAmount] = sale.saleItems.reduce(
		(agg, curr) => [agg[0] + curr.quantity, agg[1] + curr.amount],
		[0, 0]
	)

	const dir = getDirection()
	return (
		<AppLayout showHeader>
			<div dir={dir} className="space-y-4">
				<MWRAInfoCard mwra={mwra} cluster={cluster} />
				<div className="flex items-center">
					<Speaker filename="salesDetails.mp3" />
					<h1 className="font-semibold text-lg pt-2">{t(tKey('labels.title'))}</h1>
				</div>
				{sale.saleItems.map(saleItem => {
					const imageCounts = [...Array(saleItem.quantity).keys()].filter(index => index < 4)
					// const productsLeft = (saleItem.quantity ?? 0) - 4

					const product = products[saleItem.productId]
					return (
						<div
							key={saleItem.id}
							className={`flex items-center shadow-sm border p-4 border-gray-200 bg-white rounded-lg space-x-4`}>
							<div className="space-y-1 w-full">
								<div className="flex">
									<div
										className={clsx(
											'flex justify-center border border-gray-200 bg-white rounded-full h-14 w-14 p-2'
										)}>
										<img src={product?.attachments[0]?.url} className="h-full object-contain" />
									</div>
									{/* {productsLeft > 0 && (
										<div
											key={'additional'}
											className="border border-gray-200 bg-white -ml-6 aspect-square h-14 rounded-full p-4">
											+{productsLeft}
										</div>
									)} */}
								</div>
								<p className="font-semibold">
									<span>{saleItem.quantity}</span> {getLocalizedName(product ?? {})}
								</p>
								<p className="font-semibold">
									{saleItem.amount.toLocaleString()} {t('products.label.rupees')}
								</p>
							</div>
						</div>
					)
				})}
				<div className="space-y-2">
					<div className="flex items-center">
						<Speaker filename="details.mp3" />
						<h1 className="font-semibold text-lg pt-2">{t(tKey('labels.detail'))}</h1>
					</div>
					<div className="grid grid-cols-2 gap-2 shadow-sm border p-4 text-lg text-slate-600 border-gray-200 bg-white rounded-lg">
						<h1>{t(tKey('labels.time'))}</h1>
						<h1>{time}</h1>
						<h1>{t(tKey('labels.date'))}</h1>
						<h1>{date}</h1>
						<h1>{t('products.label.totalProducts')}</h1>
						<h1>{totalSoldProducts}</h1>
						<h1>{t('products.label.totalPrice')}</h1>
						<h1>
							{totalAmount.toLocaleString()}
							<span> {t('products.label.rupees')}</span>
						</h1>
						<h1>{t('products.label.profit')}</h1>
						<h1>
							{totalMargin.toLocaleString()}
							<span> {t('products.label.rupees')}</span>
						</h1>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}
