import { Language } from 'constants/constants'
import { DateTime } from 'luxon'
import { getAppLang } from './language'

/**
 * Convert a string to title-case e.g. taimur -> Taimur,
 * taimur shah -> Taimur Shah
 *
 * @param text String to be title-cased
 * @param splitBy This is to split and join the string if it's concatenated with any string literal e.g. space, hyphen etc
 */
export const toTitleCase = (text: string, splitBy = ' ') => {
	if (!text) {
		return text
	}

	return text
		.trim()
		.toLowerCase()
		.split(splitBy)
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(splitBy)
}

export const getDirection = () => {
	const lang = getAppLang()
	return lang === Language.EN ? 'ltr' : 'rtl'
}

export const containsOnlyDigits = (text: string) => {
	return text.match(/^[0-9]+$/)
}
export const getYearsPassed = (birthMonth: string, birthYear: string) => {
	if (!birthMonth || !birthYear) {
		return 0
	}
	const now = DateTime.now()
	const birthdateObj = DateTime.fromObject({
		year: Number(birthYear),
		month: Number(birthMonth)
	})
	const age = now.diff(birthdateObj, 'years').years

	return Math.ceil(age)
}

export const isMalnourishedMWRA = (muac: number) => muac <= 23
	//Product Wellma -> (Status P L AND MUAC => 23) OR (Status P L M AND MUAC < 23)


export const getRegistrationNumber = (clusterCode: string, localAutoId: ID) => {
	const clusterId = clusterCode ? clusterCode.split('-')[1] : ''
	return `${clusterId}-${localAutoId ? localAutoId.toString()?.padStart(3, '0') : 'nil'}`
}
