import { CheckIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppLayout } from 'components/app/layout'
import { CounterInput } from 'components/inputs/counter'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormResetField,
	UseFormSetValue,
	useForm
} from 'react-hook-form'
import { toast } from 'react-hot-toast'
import * as yup from 'yup'

import { Speaker } from 'components/audio-player'
import { InputFieldError } from 'components/error/field-error'
import { Select } from 'components/inputs/select'
import { Modal } from 'components/modals/modal'
import { Language } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import { addMeeting } from 'slices/queued'
import { getAppLang, getLocalizedName, getTKey, t } from 'utils/language'
import { getDirection } from 'utils/string'

enum FormSteps {
	DATE = 1,
	ATTENDEES,
	COMPLETE
}

type StateType = {
	step: FormSteps
	displayExitModal: boolean
}

const tKey = getTKey('meeting')

export const CreateMeeting = () => {
	const dispatch = useAppDispatch()
	const [state, setState] = useState<StateType>({
		step: FormSteps.DATE,
		displayExitModal: false
	})

	const auth = useAppSelector(state => state.auth)

	const schema = yup.object({
		meetingDate: yup.string().required(t('errors.required', { field: t(tKey('labels.date')) })),
		totalInLawsParticipants: yup.number().when('$step', {
			is: FormSteps.ATTENDEES,
			then: schema =>
				schema
					.typeError(t('errors.invalid', { field: t(tKey('labels.countMothers')) }))
					.required(t('errors.required', { field: t(tKey('labels.countMothers')) }))
		}),
		totalParticipants: yup.number().when('$step', {
			is: FormSteps.ATTENDEES,
			then: schema =>
				schema
					.typeError(t('errors.invalid', { field: t(tKey('labels.countMWRA')) }))
					.required(t('errors.required', { field: t(tKey('labels.countMWRA')) }))
		}),
		bastiId: yup.number().when('$step', {
			is: FormSteps.ATTENDEES,
			then: schema =>
				schema
					.required(t('errors.required', { field: t(tKey('labels.basti')) }))
					.typeError(t('errors.invalid', { field: t(tKey('labels.basti')) }))
		})
	})

	const {
		register,
		handleSubmit,
		formState: { errors },
		control
	} = useForm<MeetingForm>({
		resolver: yupResolver(schema as any),
		context: { step: state.step },
		defaultValues: {
			crpId: auth.profile.id,
			totalParticipants: undefined,
			totalInLawsParticipants: undefined,
			organizer: 'crp',
			title: '',
			clusterId: auth.profile.cluster.id
		},
		mode: 'all'
	})

	const renderComponent = cond([
		[
			state.step === FormSteps.DATE,
			() => <DateForm errors={errors} register={register} control={control} />
		],
		[
			state.step === FormSteps.ATTENDEES,
			() => (
				<AttendeesForm
					bastis={auth.profile.cluster.bastis}
					errors={errors}
					register={register}
					control={control}
				/>
			)
		]
	])

	const nextStep = handleSubmit(data => {
		setState(prevState => ({ ...prevState, step: prevState.step + 1 }))
	})

	const previousStep = () => {
		if (state.step === 1) {
			return
		}

		setState(prevState => ({
			...prevState,
			step: prevState.step - 1
		}))
	}

	const onSubmit = handleSubmit(data => {
		dispatch(addMeeting(data))
		setState({ ...state, step: FormSteps.COMPLETE })
	})
	const dir = getDirection()
	return (
		<AppLayout
			showHeader
			showCrossIcon
			onBackArrowPress={() => {
				/* eslint-disable no-restricted-globals */
				if (state.step !== FormSteps.COMPLETE && state.step !== FormSteps.DATE) {
				  previousStep();
				} else {
				  history.back();
				}
				/* eslint-enable no-restricted-globals */
			  }}
			  
			onCrossPress={() => setState({ ...state, displayExitModal: true })}>
			<div dir={dir}>
				<form
					onSubmit={event => {
						state.step == FormSteps.ATTENDEES ? onSubmit(event) : nextStep(event)
					}}
					className="space-y-2 w-full font-bold">
					<>{renderComponent}</>
					{state.step < FormSteps.COMPLETE && (
						<div className="py-4">
							<button
								type="submit"
								className="p-4 max-w-max mx-auto  flex flex-row justify-between bg-green-500 text-white rounded-full ">
								<CheckIcon className="h-10" />
							</button>
							<h1 className="text-center mt-4">{t('buttons.next')}</h1>
						</div>
					)}
					{state.step === FormSteps.COMPLETE && (
						<>
							<div className="py-4">
								<div className="flex items-center justify-center mb-4">
									<Speaker filename="meetingFormHasBeenSubmitted.mp3" />
									<h1 className="text-center">{t('meeting.labels.success')}</h1>
								</div>
								<button
								
									onClick={event => {
										event.preventDefault()
											/* eslint-disable no-restricted-globals */
										history.back()
											/* eslint-disable no-restricted-globals */
									}}
									className="p-4 max-w-max mx-auto  flex flex-row justify-between bg-green-500 text-white rounded-full ">
									<CheckIcon className="h-10" />
								</button>
							</div>
						</>
					)}
				</form>
			</div>
			{state.displayExitModal && (
				<Modal>
					<div className="bg-white px-6 space-y-4 py-4">
						<h1 className="text-center font-semibold">{t('common.labels.backWithoutSaving')}</h1>
						<div className="flex flex-row justify-center items-center">
							<button
								onClick={event => {
										/* eslint-disable no-restricted-globals */
									event.preventDefault()
									history.back()
										/* eslint-disable no-restricted-globals */
								}}
								type="button"
								className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-red-500 font-bold px-8 py-2 text-white">
								{t('buttons.yes')}
							</button>
							<button
								onClick={event => {
									event.preventDefault()
									setState({ ...state, displayExitModal: false })
								}}
								type="button"
								className="text-md mb-2 w-1/3 block mx-auto text-center rounded-md bg-green-500 font-bold px-8 py-2 text-white">
								{t('buttons.no')}
							</button>
						</div>
					</div>
				</Modal>
			)}
		</AppLayout>
	)
}

interface FormProps {
	register?: UseFormRegister<MeetingForm>
	errors?: FieldErrors<MeetingForm>
	control?: Control<MeetingForm, any>
	setValue?: UseFormSetValue<MeetingForm>
	resetField?: UseFormResetField<MeetingForm>
	bastis?: Basti[]
}

const DateForm = ({ errors, control }: FormProps) => {
	const dateError = errors?.['meetingDate']?.message as string

	return (
		<div className="space-y-6">
			<div className="flex items-center">
				<Speaker filename="selectMeetingDate.mp3" />
				<h2 className="pt-2">{t(tKey('labels.date'))}</h2>
			</div>
			<Controller
				control={control}
				name={'meetingDate'}
				render={({ field: { onChange, value } }) => {
					const date = value ? DateTime.fromISO(value).toJSDate() : undefined
					return (
						<div>
							<Calendar
								locale={getAppLang() == Language.SD ? Language.UR : getAppLang()}
								className="rounded-lg p-2 mx-auto w-full"
								onChange={(event: any) => {
									const datetime = DateTime.fromJSDate(event)
									const differenceInDays = datetime.diffNow('day').days

									if (differenceInDays > 0) {
										return toast.error(t('meeting.errors.futureDate'))
									}

									if (differenceInDays < -35) {
										return toast.error(t('meeting.errors.pastDate'))
									}

									onChange(datetime.toISO())
								}}
								value={date}
							/>
							<InputFieldError message={dateError} className="my-2" />
						</div>
					)
				}}
			/>
		</div>
	)
}

const AttendeesForm = ({ errors, control, register, bastis }: FormProps) => {
	console.log("Dasdasd",bastis)
	return (
		<div className="space-y-4">
			<div className="flex items-center">
				<Speaker filename="selectABasti.mp3" />
				<h1 className="pt-2">{t('meeting.labels.basti')}</h1>
			</div>
			<div>
				<Select
					id="bastiId"
					name="bastiId"
					error={errors}
					className="mt-2 block w-full outline-none rounded-md py-4 text-gray-900 border border-gray-300 bg-white appearance-none"
					register={register}>
					<option value={''}>{t(tKey('labels.basti'))}</option>
					{(bastis ?? []).map(basti => {
						return (
							<option key={basti.id} value={basti.id}>
								{getLocalizedName(basti)}
							</option>
						)
					})}
				</Select>
			</div>

			<div className="flex items-center">
				<Speaker filename="numberOfMWRAs.mp3" />
				<h2 className="pt-2">{t(tKey('labels.countMWRA'))}</h2>
			</div>
			<Controller
				control={control}
				name={'totalParticipants'}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<div>
						<CounterInput
							onChange={event => {
								onChange(Number(event.target.value))
							}}
							controllerError={error}
							onNumberChange={number => onChange(number)}
							value={value}
							maxLength={3}
						/>
					</div>
				)}
			/>
			<div className="flex items-center">
				<Speaker filename="countOfFamilyMembers.mp3" />
				<h2 className="pt-2">{t(tKey('labels.countMothers'))}</h2>
			</div>
			<Controller
				control={control}
				name={'totalInLawsParticipants'}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<div>
						<CounterInput
							onChange={event => {
								onChange(Number(event.target.value))
							}}
							controllerError={error}
							onNumberChange={number => {
								onChange(number)
							}}
							value={value}
							maxLength={3}
						/>
					</div>
				)}
			/>
		</div>
	)
}
