import { BookmarkIcon, BookmarkSlashIcon } from '@heroicons/react/24/solid'
import { useAppDispatch, useAppSelector } from 'hooks'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MenuDropdown } from 'components/app/dropdown'
import { AppLayout } from 'components/app/layout'
import { MultiSelector } from 'components/app/multi-selector'
import { SearchInput } from 'components/app/search-input'
import { Speaker } from 'components/audio-player'
import { LinkCard } from 'components/cards/link-cards'
import { WomanConditionTypes } from 'constants/constants'
import { pinMWRAForFieldVisit, saveMwras, unpinMWRAForFieldVisit } from 'slices/db'
import clsx from 'utils/clsx'
import { getLocalizedName, t } from 'utils/language'
import { getDirection, getRegistrationNumber, isMalnourishedMWRA, toTitleCase } from 'utils/string'

import registrationService from 'services/registration-service'

enum BastiOrderView {
	ALL_BASTIS = 'All Bastis'
}

enum MWRAConditions {
	ALL = 'All MWRAs',
	PREGNANT = 'pregnant',
	LACTATING = 'lactating',
	MWRA = 'mwra',
	MALNOURISHED = 'malnourished'
}
interface SearchState {
	searchText: string
}

export const MwraList = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const crp = useAppSelector(state => state.auth.profile)
	const { mwras, followups, fieldVisits, sales, products } = useAppSelector(state => state.db)


	useEffect(() => {
		registrationService.getMWRAsByCRP(crp.id).then(response => dispatch(saveMwras(response.data)))
	}, [crp])

	let wellmaSales = []
	let mwrasWhoBoughtWellma = new Set<ID>()

	const startDate = DateTime.now().startOf('month')
	const endDate = DateTime.now().endOf('month')

	const mappedMWRAs = Object.values(mwras).reduce((curr, mwra) => {
		curr[mwra.id] = mwra
		return curr
	}, {} as { [id: ID]: MWRA })

	for (const sale of Object.values(sales)) {
		const date = DateTime.fromISO(sale.saleDate)
		if (!(date >= startDate && date <= endDate)) {
			continue
		}

		const mwra = mappedMWRAs[sale.mwraId]
		for (const saleItem of sale.saleItems) {
			if (
				products[saleItem.productId]

			) {

				wellmaSales.push(sale)
				mwrasWhoBoughtWellma.add(saleItem?.quantity)
			}
			// if (
			// 	products[saleItem.productId]?.isWellma &&
			// 	['pregnant', 'lactating'].includes(mwra?.condition as string)
			// ) {
			// 	wellmaSales.push(sale)
			// 	mwrasWhoBoughtWellma.add(sale.mwraId)
			// }
		}
	}

	const mwraTypeRadioItems = {
		[MWRAConditions.ALL]: t('plw.options.allMwras'),
		[MWRAConditions.PREGNANT]: t('plw.options.pregnant'),
		[MWRAConditions.LACTATING]: t('plw.options.lactating'),
		[MWRAConditions.MWRA]: t('plw.options.mwra'),
		[MWRAConditions.MALNOURISHED]: t('plw.options.malnourished')
	}

	const bastiRadioItem = {
		[BastiOrderView.ALL_BASTIS]: t('plw.options.allBastis')
	}

	const [search, setSearch] = useState<SearchState>({
		searchText: ''
	})

	const [isMalnourished, setIsMalnourished] = useState(false)

	const [selectedWomanConditionTypes, setWomanConditionTypes] = useState<string[]>([
		mwraTypeRadioItems[MWRAConditions.ALL]
	])

	const [selectedBastis, setBastis] = useState<string[]>([
		bastiRadioItem[BastiOrderView.ALL_BASTIS]
	])

	const mappedBastis = (crp.cluster.bastis ?? []).reduce((agg, curr) => {
		return { ...agg, [curr.id]: curr }
	}, {} as Bastis)

	const getFilteredMWRAS = () => {
		const filteredMWRAs = Object.values(mwras ?? {})

		const searchMwras = (mwra: MWRA) => {
			const searchText = search.searchText.toLowerCase()

			return (
				!searchText ||
				mwra.localAutoId === parseInt(searchText) ||
				toTitleCase(mwra.name).includes(toTitleCase(searchText))
			)
		}

		let filteredMwras = filteredMWRAs.filter(searchMwras)

		if (isMalnourished) {
			filteredMwras = filteredMwras.filter(mwra => isMalnourishedMWRA(mwra.muac))
		}

		if (
			selectedWomanConditionTypes.includes(mwraTypeRadioItems[MWRAConditions.ALL]) &&
			selectedBastis.includes(bastiRadioItem[BastiOrderView.ALL_BASTIS])
		) {
			return filteredMwras
		}

		filteredMwras = filteredMwras.filter(mwra => {
			const basti = Object.values(mappedBastis).find(basti => basti.id === mwra.bastiId)
			const isMalnourished = isMalnourishedMWRA(mwra.muac)

			return (
				(selectedBastis.includes(getLocalizedName(basti)) ||
					selectedBastis.includes(bastiRadioItem[BastiOrderView.ALL_BASTIS])) &&
				(selectedWomanConditionTypes.includes(
					mwraTypeRadioItems[mwra.condition as MWRAConditions]
				) ||
					(isMalnourished &&
						selectedWomanConditionTypes.includes(
							mwraTypeRadioItems[MWRAConditions.MALNOURISHED]
						)) ||
					selectedWomanConditionTypes.includes(mwraTypeRadioItems[MWRAConditions.ALL]))
			)
		})

		return filteredMwras
	}

	const pinMWRA = (id: ID) => {
		dispatch(pinMWRAForFieldVisit({ id }))
	}

	const unpinMWRA = (id: ID) => {
		dispatch(unpinMWRAForFieldVisit({ id }))
	}

	const countOfMWRAsToBeVisited = fieldVisits?.mwras?.length

	const conditionOptions = Object.values(mwraTypeRadioItems).filter(
		condition =>
			condition !== mwraTypeRadioItems[MWRAConditions.ALL] &&
			condition !== mwraTypeRadioItems[MWRAConditions.MALNOURISHED]
	)

	const bastiOptions = Object.values(mappedBastis).map(basti => getLocalizedName(basti))

	const dir = getDirection()

	return (
		<AppLayout showHeader onBackArrowPress={() => navigate('/')}>
			<div dir={dir} className="space-y-4 w-full">
				<div className="flex items-center">
					<Speaker filename="listOfMWRA.mp3" />
					<h1 className="font-semibold text-lg">{t('followUp.labels.listOfMWRA')}</h1>
				</div>

				<div className="flex w-full items-center">
					<SearchInput
						placeholder={t('followUp.labels.mwraList.searchByRegNumber')}
						className={clsx('md:w-full', dir === 'rtl' ? 'pr-10' : 'pl-10')}
						type="text"
						onChange={event =>
							setSearch({
								searchText: event.target.value
							})
						}
					/>
					<div className="w-10 px-2">
						<MenuDropdown
							options={[t('followUp.labels.malnourished')]}
							labelText={t('followUp.labels.malnourished')}
							selected={isMalnourished ? t('followUp.labels.malnourished') : ''}
							setActive={setIsMalnourished}
						/>
					</div>
				</div>

				<div className={clsx('flex flex-row space-x-2 mb-2')}>
					<MultiSelector
						onClick={options =>
							setWomanConditionTypes(
								options.length > 0 ? options : [mwraTypeRadioItems[MWRAConditions.ALL]]
							)
						}
						options={conditionOptions}
						defaultOption={mwraTypeRadioItems[MWRAConditions.ALL]}
					/>

					<MultiSelector
						onClick={options =>
							setBastis(options.length > 0 ? options : [bastiRadioItem[BastiOrderView.ALL_BASTIS]])
						}
						options={bastiOptions}
						defaultOption={bastiRadioItem[BastiOrderView.ALL_BASTIS]}
					/>
				</div>

				<StatsCards women={getFilteredMWRAS()} totalWellmaSold={[...mwrasWhoBoughtWellma].reduce((sum, value) => sum + value, 0)} />

				{countOfMWRAsToBeVisited > 0 && (
					<div>
						<p className="mb-4">
							{t('followUp.labels.listOfMWRAsForFieldVisit', {
								countOfMWRAsToBeVisited: countOfMWRAsToBeVisited
							})}
						</p>
						<div className="space-y-2">
							{Object.values(getFilteredMWRAS() ?? {})
								.filter(m => fieldVisits?.mwras?.includes(m.id))
								.map(mwra => (
									<RenderItem
										key={mwra.id}
										mwra={mwra}
										followups={followups}
										sales={sales}
										itemType="pinned"
										bastis={mappedBastis}
										callback={() => unpinMWRA(mwra.id)}
										dir={dir}
										cluster={crp.cluster}
									/>
								))}
						</div>
						<div className="border-b-4 my-8 rounded-lg border-blue-400" />
					</div>
				)}

				<div className="space-y-2">
					{Object.values(getFilteredMWRAS() ?? {})
						.filter(m => !fieldVisits?.mwras?.includes(m.id))
						.map(mwra => (
							<RenderItem
								key={mwra.id}
								mwra={mwra}
								sales={sales}
								followups={followups}
								itemType="unpinned"
								bastis={mappedBastis}
								callback={() => pinMWRA(mwra.id)}
								dir={dir}
								cluster={crp.cluster}
							/>
						))}
				</div>
			</div>
		</AppLayout>
	)
}

type StatsCards = {
	women: MWRA[]
	totalWellmaSold: number
}

const StatsCards = ({ women, totalWellmaSold }: StatsCards) => {
	const dir = getDirection()
	const totalPLWs = women.filter(
		mwra =>
			mwra.condition === MWRAConditions.PREGNANT || mwra.condition === MWRAConditions.LACTATING
	).length
	
	let totalFpUsers = 0;
	women.forEach(mwra => {
		console.log("women1",mwra.isFamilyPlanning);
		if (String(mwra.isFamilyPlanning) === "t") {
			totalFpUsers++;
		}
	});

	return (
		<div dir={dir} className="grid grid-cols-2 text-center gap-2">
			<CardItem
				label={`${t('stats.labels.totalMWRA')}: ${women.length}`}
				className="bg-[#F4DBC2] "
			/>
			<CardItem label={`${t('stats.labels.totalPLW')}: ${totalPLWs}`} className="bg-[#E3F18C]" />
			<CardItem
				label={`${t('stats.labels.totalWellmaSold')}: ${totalWellmaSold}`}
				className="bg-[#9ed1fc]"
			/>
			<CardItem label={`${t('stats.labels.totalFpUsers')}: ${totalFpUsers}`} className="bg-[#F3F28C]" />

		</div>
	)
}

type CardItemProps = {
	label: string
	className?: string
}

const CardItem = ({ label, className }: CardItemProps) => {
	return (
		<div className={clsx('py-3 px-2 rounded md-rounded w-auto', className)}>
			<p className="font-medium text-sm">{label}</p>
		</div>
	)
}

type RenderItemProps = {
	followups: Followups
	sales: Sales
	mwra: MWRA
	itemType: 'pinned' | 'unpinned'
	callback: () => void
	dir: 'rtl' | 'ltr'
	bastis: Bastis
	cluster: Cluster
}

const RenderItem = ({
	followups,
	mwra,
	itemType,
	callback,
	dir,
	bastis,
	sales,
	cluster
}: RenderItemProps) => {
	// const latestFollowup = Object.values(followups ?? {})
	// 	.filter(followup => followup.mwraId === mwra.id)
	// 	.sort((followup1, followup2) => {
	// 		const dt1 = DateTime.fromISO(followup1.followupDate)
	// 		const dt2 = DateTime.fromISO(followup2.followupDate)
	// 		return dt1.toMillis() - dt2.toMillis()
	// 	})?.[0]

	// const latestSale = Object.values(sales ?? {})
	// 	.filter(sale => sale.mwraId === mwra.id)
	// 	.sort((a, b) => {
	// 		const dt1 = DateTime.fromISO(a.saleDate)
	// 		const dt2 = DateTime.fromISO(b.saleDate)
	// 		return dt1.toMillis() - dt2.toMillis()
	// 	})?.[0]

	const latestSale = Object.values(sales ?? {})
		.filter(sale => sale.mwraId === mwra.id)
		.sort((a, b) => {
			const dt1 = DateTime.fromISO(b.saleDate); // Sort in descending order based on saleDate
			const dt2 = DateTime.fromISO(a.saleDate);
			return dt1.toMillis() - dt2.toMillis();
		})?.[0];

	// As per client requirements, sales and followups both would be
	// considered - so we need to find the followup date either from followups or sales
	//
	// if both exists, we will pick the latest e.g. followupDate > saleDate

	// const followupDate = latestFollowup?.followupDate
	// 	? DateTime.fromISO(latestFollowup.followupDate)
	// 	: undefined

	const saleDate = latestSale?.saleDate ? DateTime.fromISO(latestSale.saleDate) : undefined

	let latestFollowupDate = saleDate

	if (saleDate) {
		latestFollowupDate = saleDate
	}

	// multiply by -1 to get a positive number for easy conditions



	var defaultBarColor = '';

	if (latestFollowupDate) {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth();
		const currentYear = currentDate.getFullYear();
		const dayOfMonth = currentDate.getDate();

		const diffInDays = latestFollowupDate ? latestFollowupDate.diffNow('days')?.days * -1 : 0

		const visitDate = latestFollowupDate.toJSDate();
		const visitMonth = visitDate.getMonth();
		const visitYear = visitDate.getFullYear();

		if (visitMonth === currentMonth && visitYear === currentYear) {
			if(diffInDays < 11) {
				defaultBarColor = 'green';
			}
			else if (diffInDays > 10 && diffInDays < 16) {
				defaultBarColor = 'orange';
			}
			else {
				defaultBarColor = 'red';
			}
		}
		else {
			defaultBarColor = 'red';
		}


	}
	else {
		defaultBarColor = 'red';
	}


	const subtitle = `${getLocalizedName(bastis?.[mwra?.bastiId])}  ${t(`${mwra?.condition}`)}`

	return (
		<div key={mwra.id} className="flex items-center flex-row">
			<LinkCard
				to={mwra.condition === WomanConditionTypes.DECEASED ? '#' : `/mwras/${mwra.id}`}
				mwraDetails={mwra}
				title={mwra.name}
				strapline={getRegistrationNumber(cluster.code, mwra.localAutoId)}
				subtitle={subtitle}
				className={clsx(
					'bg-white w-full',
					// for handling direction of strapline
					defaultBarColor === ''
						? ''
						: defaultBarColor === 'green'
							? dir === 'ltr'
								? 'border-r-8 border-r-green-500'
								: 'border-l-8 border-l-green-500'
							: defaultBarColor === 'orange'
								? dir === 'ltr'
									? 'border-r-8 border-r-orange-500'
									: 'border-l-8 border-l-orange-500'
								: dir === 'ltr'
									? 'border-r-8 border-r-red-500'
									: 'border-l-8 border-l-red-500'
				)}
			/>
			<div className={clsx(dir === 'ltr' ? 'ml-2' : 'mr-2')}>
				{itemType === 'pinned' ? (
					<BookmarkIcon onClick={callback} className="w-6 h-6 cursor-pointer text-blue-500" />
				) : (
					<BookmarkSlashIcon onClick={callback} className="w-6 h-6 cursor-pointer text-gray-400" />
				)}
			</div>
		</div>
	)
}
