import clsx from 'clsx'
import { ReactElement } from 'react'

type TableParams = {
	headers: string[]
	isReport: boolean
	renderComponent: () => ReactElement<any, any>
}

export const Table = ({ headers, renderComponent, isReport }: TableParams) => {
	return (
		<table className="min-w-full table-fixed divide-y divide-gray-300 border border-gray-200 rounded-lg ">
			<thead>
				<tr className={isReport ? 'text-left' : ''}>
					{headers.map((header, index) => (
						 <th
						 key={header + index}
						 scope="col"
						 style={{ fontWeight: 'bold' }}
						 className={clsx(
						   `text-sm font-bold p-2`, // Added padding class
						   index === 0 && 'border-none min-w-[30px]'
						 )}
					   >
							{header}
						</th>
					))}
				</tr>
			</thead>
			{renderComponent()}
		</table>
	)
}
