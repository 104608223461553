import clsx from 'utils/clsx'
import { getDirection } from 'utils/string'

interface ButtonProps {
	title?: string
	onClick?: () => void
	className?: string
}

export const ButtonOkay = ({ title, onClick, className }: ButtonProps) => {
	const dir = getDirection()

	return (
		<div dir={dir} className={clsx('flex items-center justify-center space-x-2', className)}>
			<button
				onClick={onClick}
				className="bg-[#04C882] text-center outline-none  w-20 px-2 py-3 rounded-md font-semibold text-white">
				{title}
			</button>
		</div>
	)
}
