import { CheckIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { FieldErrors, FieldValues, useForm, UseFormRegister } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { unwrapResult } from '@reduxjs/toolkit'
import { FingerprintIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { Input } from 'components/inputs/input'
import { useAppDispatch } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { login } from 'slices/auth'
import clsx from 'utils/clsx'
import { getTKey, t } from 'utils/language'

const tKey = getTKey('login')

type LoginForm = {
	username: string
	password: string
}

enum FormSteps {
	PERSONAL = 1,
	MORE_INFO,
	PHONE,
	ADDRESS,
	COMPLETE
}

export const RecurringLogin = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const schema = yup.object({
		username: yup.string().required(t('errors.required', { field: 'Username' })),
		password: yup.string().required(t('errors.required', { field: 'Password' }))
	})
	const [state, setState] = useState({
		displayForm: false,
		fingerprintErrorCount: 0
	})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<LoginForm>({
		resolver: yupResolver(schema as any),
		defaultValues: { username: '', password: '' },
		mode: 'all'
	})

	const loginHeading =
		state.fingerprintErrorCount > 3
			? t(tKey('errors.fingerprintNotRecognized'))
			: t(tKey('labels.login'))

	const onSubmit = handleSubmit(data => {
		dispatch(login({ username: data.username, password: data.password }))
			.then(unwrapResult)
			.catch(error => {
				toast.error('Login Failed')
			})
	})

	const { i18n } = useTranslation()
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)

		// reload to make sure all resource gets
		// loaded in localized way
		// window.location.reload()
	}

	return (
		<AppLayout>
			<div
				className={clsx('p-4 h-screen flex flex-col', {
					'items-center justify-center': !state.displayForm
				})}>
				{state.displayForm && (
					<>
						<div className="flex w-full justify-end items-center">
							<select
								name="language"
								className="block rounded-md border-0 text-xs items-center text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-500"
								defaultValue={i18n.language || window.localStorage.i18nextLng}
								onChange={event => changeLanguage(event.target.value)}>
								<option value="en">English</option>
								<option value="ur">Urdu</option>
								<option value="sd">Sindhi</option>
							</select>
						</div>
						<form onSubmit={onSubmit} className="space-y-2 w-full font-bold">
							<h1 className="text-lg font-bold">
								<Speaker
									filename={
										state.fingerprintErrorCount > 3
											? 'failedToRecognizeFingerprinnt.mp3'
											: 'loginUsingTheUsername.mp3'
									}
								/>
								{loginHeading}
							</h1>
							<UsernameAndPasswordForm errors={errors} register={register} />
							<div className="flex items-center flex-col justify-center">
								<button
									type="submit"
									className="text-md mb-2 max-w-max items-center p-2 justify-center rounded-full bg-green-500 font-bold  text-white">
									<CheckIcon className="h-12 w-12" />
								</button>
								<p>
									<Speaker filename="login.mp3" />
									{t(tKey('labels.main'))}
								</p>
							</div>
						</form>
					</>
				)}
				{!state.displayForm && (
					<div
						className={clsx('space-y-2 flex flex-col justify-center items-center', {
							'bg-[#F5DFD0] rounded-xl p-2': state.displayForm
						})}>
						<div
							onClick={() => {
								navigate('/home', { replace: true })
							}}
							className={clsx(
								'p-6 cursor-pointer border-slate-300 w-max shadow-sm rounded-full border',
								{
									'border-0': state.displayForm
								}
							)}>
							<img className="h-20 aspect-square" src={FingerprintIcon} />
						</div>
						<p className="font-medium"> {t(tKey('options.fingerprint'))}</p>
						<div className="flex flex-row justify-center items-center">
							<Speaker filename="loginWithTheFingerprint.mp3" />
						</div>
					</div>
				)}
				{/* 
				{!state.displayForm && (
					<p
						onClick={() => setState({ ...state, displayForm: true })}
						className="text-slate-500 underline cursor-pointer font-medium absolute bottom-8">
						{t(tKey('options.username'))}
					</p>
				)} */}
			</div>
		</AppLayout>
	)
}

interface FormProps {
	register?: UseFormRegister<LoginForm>
	errors: FieldErrors<FieldValues>
}

const UsernameAndPasswordForm = ({ register, errors }: FormProps) => {
	return (
		<>
			<Input
				register={register}
				error={errors}
				type="text"
				name="username"
				id="username"
				placeholder={t(tKey('placeholders.username'))}
			/>
			<Input
				register={register}
				error={errors}
				type="password"
				name="password"
				id="password"
				placeholder={t(tKey('placeholders.password'))}
			/>
		</>
	)
}
