import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'

import clsx from 'utils/clsx'
import { getDirection } from 'utils/string'

interface MenuItemProps {
	options: string[]
	labelText: string
	setActive: (value: boolean) => void
	selected: string
}

export const MenuDropdown = ({ options, labelText, setActive, selected }: MenuItemProps) => {
	const dir = getDirection()

	return (
		<Menu as="div" className="relative inline-block text-left">
			{({ open }) => (
				<>
					<Menu.Button className="inline-flex w-full justify-center text-sm font-medium text-black focus:outline-none">
						<EllipsisVerticalIcon className="h-7 w-7 stroke-2" aria-hidden="true" />
					</Menu.Button>
					{open && (
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Menu.Items
								className={clsx(
									'absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
									dir === 'rtl' ? 'left-0' : 'right-0'
								)}
								static>
								<div className="px-1 py-1">
									{options.map(option => (
										<Menu.Item key={option}>
											<button
												className={`${
													selected === option ? 'bg-[#E9ECFF] text-gray-900' : 'text-gray-900'
												} group flex w-full items-center rounded-md px-2  py-2 text-sm`}
												onClick={event => {
													if (selected === option) {
														setActive(false)
													} else {
														setActive(option === labelText)
													}

													event?.preventDefault()
												}}>
												<>
													{option}
													{selected === option && (
														<CheckIcon
															className={clsx(
																'h-6 w-6 text-sky-500',
																dir === 'rtl' ? 'mr-auto' : 'ml-auto'
															)}
															aria-hidden="true"
														/>
													)}
												</>
											</button>
										</Menu.Item>
									))}
								</div>
							</Menu.Items>
						</Transition>
					)}
				</>
			)}
		</Menu>
	)
}
