import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { Language } from 'constants/constants'
import enTranslations from 'locales/en/translate.json'
import sdTranslations from 'locales/sd/translate.json'
import urTranslations from 'locales/ur/translate.json'

i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		fallbackLng: code =>
			code
				? code.includes(Language.UR)
					? Language.UR
					: code.includes(Language.SD)
					? Language.SD
					: Language.EN
				: Language.EN,
		ns: ['translation'],
		resources: {
			en: enTranslations,
			sd: sdTranslations,
			ur: urTranslations
		},
		keySeparator: '.',
		interpolation: {
			escapeValue: false
		},
		react: {
			nsMode: 'default',
			useSuspense: false
		},
		returnObjects: true,
		debug: process.env.NODE_ENV === 'development'
	})

export default i18next
