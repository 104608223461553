import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'

import { FormPlusIcon, PlwPlusIcon, SalesIcon, StockIcon, VisitPlwIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { LinkCard } from 'components/cards/link-cards'
import { useAppSelector } from 'hooks'
import { useGeoLocation } from 'hooks/useGeoLocation'
import { useLoadResources } from 'hooks/useLoadResources'
import { useLongPress } from 'hooks/useLongPress'
import { useQueuedItemsCounter } from 'hooks/useQueuedChanges'
import clsx from 'utils/clsx'
import { t } from 'utils/language'
import { getDirection } from 'utils/string'
import { useState } from 'react'

export const Home = () => {
	// Track user location
	useGeoLocation()

	// Try loading resources again
	useLoadResources()

	const isSeniorCRP = useAppSelector(state => state.auth.profile.isSenior);
	const [isSenior,setIsSenior]= useState<any>(isSeniorCRP)
	const canSubmitPaperForm = useAppSelector(
		state => state.auth.profile.settings?.canSubmitPaperForm
	)
	const queuedItems = useQueuedItemsCounter()

	let routes = [	
		{
			slug: 'mwras',
			title: t('meeting.options.register'),
			icon: PlwPlusIcon,
			to: canSubmitPaperForm ? '/mwras' : '/add',
			speakerFileName: 'registerNewMWRA.mp3'
		},
		{
			title: t('followUp.labels.main'),
			icon: VisitPlwIcon,
			to: '/mwras/list',
			slug: 'mwras-list',
			speakerFileName: 'followUpWithMWRA.mp3'
		},
	
		{
			title: t('meeting.options.setupMeeting'),
			icon: FormPlusIcon,
			to: '/meetings',
			slug: 'meetings',
			speakerFileName: 'submitMeetingForm.mp3'
		},
		
		{
			title: t('home.label.Inventory_management'), // New tab for Sample Form One
			icon: FormPlusIcon, // Replace with your icon
			to: '/inventory-management', // Add the route path for Sample Form One
			slug: 'inventory-management',
			speakerFileName: 'sampleFormOne.mp3' // Replace with your audio file
		},{
			title: t('meeting.options.salesPerformance'),
			icon: SalesIcon,
			to: '/sales-performance',
			slug: 'sales-performance',
			speakerFileName: 'salesPerformance.mp3'
		},{
			slug: 'dashboard',
			title: t('meeting.options.dashboard'),
			icon: SalesIcon,
			to: '/dashboard/index',
			speakerFileName: 'registerNewMWRA.mp3'
			}
	
		
	]


	const dir = getDirection()
	const navigate = useNavigate()
	const onLongPress = () => {
		navigate('/biometric', { replace: true })
	}

	const onClick = () => {
		return
	}

	const defaultLongPressOptions = {
		shouldPreventDefault: true,
		delay: 2000
	}

	const longPressEvent = useLongPress(onLongPress, onClick, defaultLongPressOptions)

	const pendingForms =
		queuedItems.distributions +
		queuedItems.followups +
		queuedItems.meetings +
		queuedItems.mwras +
		queuedItems.sales +
		queuedItems?.requisitions +
		queuedItems?.requisitionsRequestSeniorCrp


	return (
		<AppLayout showHeader>
			<div dir={dir}>
				<div className="space-y-4 w-full">
					<div className="flex items-center">
						<Speaker filename="whatWouldYouLikeToDo.mp3" />
						<h1 className="font-semibold text-lg pt-2">{t('meeting.labels.main')}</h1>
					</div>
					{routes.map(route => {
						return (
							<LinkCard
								key={route.title}
								imageSrc={route.icon}
								to={route.to}
								title={route.title}
								className={clsx({
									'bg-[#FFDBD2]': route.slug === 'mwras-list',
									'bg-[#91F2DD]': route.slug === 'mwras',
									'bg-[#d3dbff]': route.slug === 'dashboard',
									'bg-[#E3F18C]': route.slug === 'meetings',
									'bg-[#9ed1fc]': route.slug === 'sales-performance',
									'bg-[#e97bf3]': route.slug === 'stocks-crps',
									'bg-[#b0d6fd]': route.slug === 'sample-form-one',
									'bg-[#b0d7fd]': route.slug === 'sample-form-two',
									'bg-[#b0d9fd]': route.slug === 'sample-form-two',
									'bg-[#b0a8fd]': route.slug === 'inventory-management',
									'bg-[#b0c6fd]': route.slug === 'view-received-note',
									'bg-[#b0c7fd]': route.slug === 'view-current-stock',
								})}
								speakerFileName={route.speakerFileName}
							/>
						)
					
					})}
				</div>

	
			</div>
		</AppLayout>
	)
}
