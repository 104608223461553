import { FillFormMobile, FormPlusIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { Speaker } from 'components/audio-player'
import { LinkCard } from 'components/cards/link-cards'
import { useAppSelector } from 'hooks'
import clsx from 'utils/clsx'
import { t } from 'utils/language'
import { getDirection } from 'utils/string'

export const AddMwraMenu = () => {
	const { profile: user } = useAppSelector(state => state.auth)

	let routes = [
		{
			titleKey: 'plw.options.mobile',
			icon: FillFormMobile,
			to: '/mwras/add',
			slug: 'mwras-add',
			speakerFileName: 'RegisterUsingMobileForm.mp3'
		}
	]

	if (user.settings?.canSubmitPaperForm) {
		routes = [
			...routes,
			{
				titleKey: 'plw.options.scan',
				icon: FormPlusIcon,
				to: '/mwras/upload-form',
				slug: 'mwras-upload-form',
				speakerFileName: 'formUpload.mp3'
			}
		]
	}
	const dir = getDirection()

	return (
		<AppLayout showHeader>
			<div dir={dir} className="p-4 space-y-2 w-full">
				<div className="flex items-center">
					<Speaker filename="howDoYouWantToRegisterMWRA.mp3" />
					<h1 className="font-semibold text-lg pt-2">{t('meeting.labels.main')}</h1>
				</div>
				{routes.map(route => {
					return (
						<LinkCard
							key={route.titleKey}
							imageSrc={route.icon}
							to={route.to}
							className={clsx({
								'bg-[#F4E3A9]': route.slug === 'mwras-upload-form',
								'bg-[#D6DDF6]': route.slug === 'mwras-add'
							})}
							title={t(route.titleKey)}
							speakerFileName={route.speakerFileName}
						/>
					)
				})}
			</div>
		</AppLayout>
	)
}
