import { InputFieldError } from 'components/error/field-error'
import { InputHTMLAttributes, useState } from 'react'
import { FieldError, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import clsx from 'utils/clsx'

import { containsOnlyDigits } from 'utils/string'
import { isValidDecimal } from 'utils/validate'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText?: string
	name: string
	index?: string
	placeholder?: string
	register?: UseFormRegister<any>
	error?: FieldErrors<FieldValues>
	controllerError?: FieldError | undefined
	renderLabel?: boolean
	maxLength?: number
	allowDecimal?: boolean
	type?: string
	rounded?: boolean
	className?: string
}

export const Input = ({
	labelText,
	name,
	index,
	placeholder,
	maxLength,
	value,
	register,
	onChange,
	error,
	controllerError = undefined,
	allowDecimal = false,
	renderLabel = true,
	rounded = false,
	className,
	...props
}: InputProps) => {
	const errorText = (error?.[name]?.message as string) ?? (controllerError?.message as string)

	return (
		<div>
			{renderLabel && (
				<label htmlFor={name} className="mt-4 flex text-md font-medium text-black justify-start">
					{labelText}
				</label>
			)}
			<input
				{...props}
				{...(register?.(name) ?? {})}
				id={index}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				className={clsx(
					'block w-full appearance-none py-3 text-gray-800 outline-none placeholder-gray-400 focus:outline-none border-gray-500 sm:text-sm',
					className,
					{
						'rounded-lg': rounded
					}
				)}
			/>
			<InputFieldError message={errorText} className="my-2" />
		</div>
	)
}

export const InputNumber = ({
	labelText,
	name,
	index,
	placeholder,
	maxLength,
	value,
	onChange,
	register,
	type,
	controllerError = undefined,
	error,
	allowDecimal = false,
	renderLabel = true,
	rounded = false,
	className,
	...props
}: InputProps) => {
	const errorText = (error?.[name]?.message as string) ?? (controllerError?.message as string)

	const [input, setInput] = useState<string>(value?.toString() ?? '')

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target

		let decimalValue = value.split('.')[0]
		if (!maxLength || decimalValue.length <= maxLength) {
			if (containsOnlyDigits(value)) {
				setInput(value)
				onChange?.(event)
			}
			if (value == '') {
				setInput(value)
			}
			if (allowDecimal) {
				const muacDecimal = isValidDecimal(value)
				setInput(muacDecimal.toString())
			}
		}
	}

	return (
		<div>
			{renderLabel && (
				<label htmlFor={name} className="mt-4 flex text-md font-medium text-black justify-start">
					{labelText}
				</label>
			)}
			<div>
				<input
					{...props}
					{...(register?.(name) ?? {})}
					onChange={handleInputChange}
					value={input}
					inputMode="numeric"
					type={type}
					id={index}
					className={clsx(
						'block w-full appearance-none py-3 text-gray-800 outline-none placeholder-gray-400 focus:outline-none sm:text-sm',
						className,
						{
							'p-4': name === 'phone',
							'rounded-lg': rounded
						}
					)}
				/>
			</div>
			<InputFieldError message={errorText} className="my-2" />
		</div>
	)
}
