import { useAppDispatch } from 'hooks'
import { useEffect } from 'react'
import { toggleNetworkConnectivity } from 'slices/db'

enum NetworkEvents {
	LOAD = 'load',
	ONLINE = 'online',
	OFFLINE = 'offline'
}

export const useDetectNetwork = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(toggleNetworkConnectivity({ state: navigator.onLine }))

		window.addEventListener(NetworkEvents.ONLINE, () => {
			// dispatch toggleNetworkConnectivity to online when they change to online.
			dispatch(toggleNetworkConnectivity({ state: true }))
		})

		window.addEventListener(NetworkEvents.OFFLINE, () => {
			// dispatch toggleNetworkConnectivity to offline when they change to offline.
			dispatch(toggleNetworkConnectivity({ state: false }))
		})
	}, [])
}
