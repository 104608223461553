import React from 'react'

import { useDocumentTitle } from 'hooks/useDocumentTitle'
import clsx from 'utils/clsx'
import { getDirection } from 'utils/string'
import { AppHeader } from './header'
import { useQueuedItemsCounter } from 'hooks/useQueuedChanges'
import { useNavigate, useLocation } from 'react-router-dom';
import { useLongPress } from 'hooks/useLongPress'
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline'
import { t } from 'utils/language'


interface AppLayoutProps {
	children: React.ReactNode
	title?: string
	docTitle?: boolean
	showHeader?: boolean
	onBackArrowPress?: () => void
	showCrossIcon?: boolean
	onCrossPress?: () => void
	className?: string
	isLoggedIn?:boolean
}

export const AppLayout: React.FC<AppLayoutProps> = ({
	isLoggedIn=false,
	children,
	title,
	showHeader = false,
	showCrossIcon = false,
	onCrossPress,
	onBackArrowPress,
	className
}) => {
	const docTitleText = title ? `${title} | CRP App` : 'CRP - Community Representative Person'
	const location = useLocation();
	const isLoginPage =  location.pathname === '/';
	



	useDocumentTitle(docTitleText)
	const queuedItems = useQueuedItemsCounter()


	const dir = getDirection()
	const navigate = useNavigate()
	const onLongPress = () => {
		navigate('/biometric', { replace: true })
	}

	const onClick = () => {
		return
	}

	const defaultLongPressOptions = {
		shouldPreventDefault: true,
		delay: 2000
	}

	const longPressEvent = useLongPress(onLongPress, onClick, defaultLongPressOptions)

	const pendingForms =
		queuedItems.distributions +
		queuedItems.followups +
		queuedItems.meetings +
		queuedItems.mwras +
		queuedItems.sales +
		queuedItems?.requisitions +
		queuedItems?.requisitionsRequestSeniorCrp



	return (
		<div
			className={clsx('relative mx-auto min-h-screen max-w-lg bg-neutral-100', {
				'font-urdu': dir === 'rtl'
			})}>
			{showHeader && (
				<AppHeader
					showCrossIcon={showCrossIcon}
					onBackArrowPress={onBackArrowPress}
					onCrossPress={onCrossPress}
				/>
			)}
			<div className={clsx('w-full py-6 px-4 text-slate-700', className)}>{<><div>{children}</div>	<div
	style={{width:"330px"}}
	className={clsx(
		'fixed bottom-2 left-1/2 transform -translate-x-1/2 flex items-center space-x-2',
	  )}>
				{!isLoggedIn &&	<>
						{pendingForms === 0 ? (
							<>
								<div
									style={{
										opacity: 0.7,
										width: '29em',
										display: 'flex',
										justifyContent: 'flex-start',
										flexDirection: 'row-reverse',
										flexWrap: 'wrap',
										alignContent: 'stretch',
										alignItems: 'center'
									}}>
									<FaceSmileIcon {...longPressEvent} className="h-8 w-8 text-green-500" />
									{/* TODO: place formsSent under notifications key */}
									<p>{t('meeting.labels.formsSent')}</p>
								</div>
							</>
						) : (
							<>
								<FaceFrownIcon {...longPressEvent} className="h-8 w-8 text-red-500" />
								<p>
									{/* TODO: place formsSent under notifications key */}
									<span
										dangerouslySetInnerHTML={{
											__html: t('meeting.labels.pending', {
												numberOfRemainingForms: `<span class="font-bold">${pendingForms}</span>`
											})
										}}
									/>
								</p>
							</>
						)}
					</>}
				</div></>}</div>
		</div>
	)
}
