import { Disclosure } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { addRequisition } from "slices/queued";

import { useParams } from "react-router-dom";
import * as yup from "yup";
import { Modal } from "components/modals/modal";
import { AppLayout } from "components/app/layout";
import { Checkbox } from "components/inputs/checkbox";
import { Input, InputNumber } from "components/inputs/input";
import { Select } from "components/inputs/select";
import { conditions, contraceptives, incomeRange } from "constants/constants";
import { useAppDispatch, useAppSelector } from "hooks";
import registrationService from "services/registration-service";
import { saveMwras } from "slices/db";
import { getLocalizedName, getTKey, t } from "utils/language";
import { getDirection, getRegistrationNumber } from "utils/string";
import locationService from "./../../services/location-service";
import { OrderDetail } from "./../../components/cards/order-detail";
import { Table } from "components/app/table";
import { useNavigate } from "react-router-dom";
const tKey = getTKey("plw");

interface CRPFormValues {
  clusterCodeId: string;
  crpName: string;
  unionCouncilId: string;
  tehsilId: string;
  districtId: string;
  bastiId: string;
  orderDate: string;
  paymentTrackingId: string;
  orderNo: string;
  products: any;
}

interface Product {
  quantity: number;
  price: number;
  // Add other properties as needed
}

export const CRPForm = () => {
  
  const randomKey = generateRandomKey();
  const productsData = useAppSelector((state) => Object.values(state.db.products));

	const productsDataArray = Object.values(productsData);
	const filteredProducts = productsDataArray.filter(p => p.id!=31);

  const navigate = useNavigate();

  const [orderNumber, setOrderNumber] = useState<string>(randomKey);

  const { profile: crp } = useAppSelector((state) => state.auth);
  const [dataToSubmit, setDataToSubmit] = useState<any>(null);

  const [disctrictName, setDisctrictName] = useState<any>(null);
  const [tehsilName, setTehsilName] = useState<any>(null);
  const [unionCouncilsName, setUnionCouncilsName] = useState<any>(null);
  const [clusterName, setClusterName] = useState<any>(crp?.cluster?.nameEn);
  const [clusterCode, setClusterCode] = useState<any>(crp?.cluster?.id);
  const [orderDate, setOrderDate] = useState<any>(getTodayDate);

  const [bastiName, setBastiName] = useState<any>(crp?.cluster?.bastis[0]?.nameEn);

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [amount, setAmount] = useState<any>(null);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const [totalPrice,setTotalPrice]=useState<any>("");
  const [productsDataFinal, setProductsDataFinal] = useState<any>([]);

  const { connected: isConnectedToInternet } = useAppSelector((state) => state.db);

	const [width, setWidth] = useState(window.innerWidth);

  

  const mwras = useAppSelector((state) => state.db.mwras);
  const reequisitionsData = useAppSelector((state) => {
    const requisitions = state?.db?.requisitions;
    return requisitions ? Object.values(requisitions) : [];
  });

  const mwra = id ? mwras[parseInt(id)] : undefined;

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // let productsDataFinal:any = [];
  // useEffect(() => {
  // 	locationService
  // 		.getLocation()
  // 		.then(response => setLocationData(response))

  // }, [])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const schema = yup.object().shape({
    products: yup.array().min(1, "Please add at least one product"), // Assuming products is an array
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    trigger,
    control,
  } = useForm<CRPFormValues>({
    resolver: yupResolver(schema as any),
    defaultValues: {
      orderDate: getTodayDate(),
      paymentTrackingId: "",
      orderNo: "",
    },

    mode: "all",
  });

  function generateRandomKey() {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 1000000); // Adjust the range as needed
    const uniqueKey = `${timestamp}-${randomNum}`;

    return uniqueKey;
  }

  // Example usage

  const onSubmit = handleSubmit(async (data) => {
    try {
      const isValid = await trigger(); // Trigger validation for all fields

      if (!productsDataFinal || productsDataFinal.length === 0) {
        toast.error("Please add at least one product before submitting.");
        return;
      }

      setDataToSubmit(data);

      const crpForm: CRPFormValues = {
        clusterCodeId: data.clusterCodeId,
        crpName: data.crpName,
        unionCouncilId: data.unionCouncilId,
        tehsilId: data.tehsilId,
        districtId: data.districtId,
        bastiId: data.bastiId,
        orderDate: data.orderDate,
        paymentTrackingId: data.paymentTrackingId,
        orderNo: data.orderNo,
        products: productsDataFinal,
      };

      setSuccessModal(true);
      // Perform your API call or data submission here
      // Example: if (isConnectedToInternet) { ... }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while processing your request.");
    }
  });

  const dir = getDirection();

  const handleDecrement = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    let quantityElement = document.getElementById(`quantity_${index}`) as HTMLInputElement | null;

    if (quantityElement) {
      let updatedQuantity = parseInt(quantityElement.value, 10) - 1;

      // Check if the updated quantity is not less than 0
      if (updatedQuantity < 0) {
        updatedQuantity = 0; // Set the quantity to 0 if it would be negative
      }

      quantityElement.value = updatedQuantity.toString();

      let totalAmountId = document.getElementById(`totalAmount_${index}`) as HTMLDivElement | null;

      // Ensure the price remains non-negative
      const price = Math.max(filteredProducts[index]?.tradePrice, 0);
      const totalAmount = price * updatedQuantity;

      if (totalAmountId) {
        totalAmountId.innerHTML = "PKR " + totalAmount.toString();
      }

      // Find the index of the product in productsDataFinal based on productId
      const productIndexInFinal = productsDataFinal.findIndex((product: any) => product.productId === filteredProducts[index]?.id);

      if (productIndexInFinal !== -1) {
        // Update the product in productsDataFinal with new quantity or remove it if quantity becomes 0
        if (updatedQuantity === 0) {
          // Remove the product from productsDataFinal if quantity becomes 0
          productsDataFinal.splice(productIndexInFinal, 1);
        } else {
          // Update the product in productsDataFinal with new quantity
          productsDataFinal[productIndexInFinal] = {
            productId: filteredProducts[index].id,
            quantity: updatedQuantity,
            amount: price,
            totalAmount: totalAmount,
          };
        }
      }

      setProductsDataFinal([...productsDataFinal]); // Update the state with the modified array
    }
  };

  const handleIncrement = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    let quantityElement = document.getElementById(`quantity_${index}`) as HTMLInputElement | null;

    if (quantityElement) {
      let updatedQuantity = parseInt(quantityElement.value, 10) + 1;
      quantityElement.value = updatedQuantity.toString();

      // Find the index of the product in productsDataFinal based on productId
      const productIndexInFinal = productsDataFinal.findIndex((product: any) => product.productId === filteredProducts[index]?.id);

      let totalAmountId = document.getElementById(`totalAmount_${index}`) as HTMLDivElement | null;

      // Ensure the price remains non-negative
      const price = Math.max(filteredProducts[index]?.tradePrice, 0);
      const totalAmount = price * updatedQuantity;

      if (totalAmountId) {
        totalAmountId.innerHTML = "PKR " + totalAmount.toString();
      }

      if (productIndexInFinal !== -1) {
        // Update the quantity of the existing product in productsDataFinal
        productsDataFinal[productIndexInFinal].quantity = updatedQuantity;
        productsDataFinal[productIndexInFinal].totalAmount = filteredProducts[index]?.tradePrice * updatedQuantity;
      } else {
        // Add the new product to productsDataFinal
        productsDataFinal.push({
          productId: filteredProducts[index].id,
          quantity: updatedQuantity,
          amount: filteredProducts[index]?.tradePrice,
          totalAmount: filteredProducts[index]?.tradePrice * updatedQuantity,
          name: filteredProducts[index]?.nameEn,
        });
      }

      const newArray = Object.values(productsDataFinal).filter(Boolean);
      setProductsDataFinal(newArray);
    }
  };

  const calculateTotals = (products: any) => {
    let totalQuantity = 0;
    let totalPrice = 0;

    products.forEach((product: any) => {
      totalQuantity += product.quantity || product.quantity || 0; // Using the quantity or quantity property, whichever exists
      totalPrice += product.totalAmount || 0;
    });

    return { totalQuantity, totalPrice };
  };

  const handleSubmitConfirm = () => {

    setSuccessModal(false);
    toast.success("your form is submitted successfully");
    const data = {
      paymentTracking: dataToSubmit?.paymentTrackingId,
      orderNumber: orderNumber,
      orderDate: dataToSubmit?.orderDate,
      products: productsDataFinal,
    };
    dispatch(
      addRequisition({
        paymentTracking: dataToSubmit?.paymentTrackingId,
        orderNumber: orderNumber,
        orderDate: dataToSubmit?.orderDate,
        products: productsDataFinal,
      })
    );
    navigate("/");
  };

  const renderTBody = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        {productsDataFinal.map((p: any, index: number) => (
          <tr key={index} className="text-left">
            <td className="text-left">
              <span className="text-sm">{p.productId}</span>
            </td>
            <td className="text-left">
              <span className="text-sm">{p.name}</span>
            </td>
            <td className="text-left">
              <span className="text-sm">{p.quantity}</span>
            </td>
            <td className="text-left">
              <span className="text-sm">{p.totalAmount}</span>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <AppLayout showHeader title={t("edit.labels.mwraDetails")}>
      <div dir={dir} className="w-full">
        <form
          onSubmit={(event) => {
            onSubmit(event);
          }}
          className="mx-auto w-full pb-4 rounded-2xl space-y-4"
        >
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <div style={{paddingBottom:"70px"}} className="bg-white shadow p-4">
                <Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left font-medium text-slate-900 focus:outline-none">
                  <span>{t("edit.labels.personalDetails")}</span>
                  <ChevronUpIcon className={`${open ? "rotate-180 transform" : ""} h-5 w-5 text-slate-500`} />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 divide-y py-4 text-gray-500">
                  {/* <div>
										<Input
											labelText={t(tKey('labels.clusterCode'))}
											register={register}
											error={errors}
											type="text"
											className="border-none"
											name="clusterCode"
											placeholder={t(tKey('placeholders.clusterCode'))}
										/>
									</div> */}

                  {/* <div>
										<Input
											labelText={t(tKey('labels.district'))}
											register={register}
											error={errors}
											disabled={true}
											value={getLocalizedName(disctrictName)}
											type="text"
											className="border-none"
											name="districtId"
											placeholder={t(tKey('placeholders.districtName'))}
										/>
									</div>
									<div>
										<Input
											labelText={t(tKey('labels.tehsil'))}
											register={register}
											error={errors}
											disabled={true}
											value={getLocalizedName(tehsilName)}
											type="text"
											className="border-none"
											name="tehsilId"
											placeholder={t(tKey('placeholders.tehsilName'))}
										/>
									</div> */}
                  {/* <div>
										<Input
											labelText={t(tKey('labels.unionCouncil'))}
											register={register}
											error={errors}
											disabled={true}
											value={getLocalizedName(unionCouncilsName)}
											type="text"
											className="border-none"
											name="unionCouncilId"
											placeholder={t(tKey('placeholders.unionCouncilName'))}
										/>
									</div> */}

                  <div>
                    <Input
                      labelText={t(tKey("labels.clusterCode"))}
                      register={register}
                      error={errors}
                      disabled={true}
                      value={clusterCode}
                      type="text"
                      className="border-none"
                      name="clusterCodeId"
                      placeholder={t(tKey("placeholders.clusterCodeName"))}
                    />
                  </div>
                  {/* <div>
										<Input
											labelText={t(tKey('labels.basti'))}
											register={register}
											error={errors}
											disabled={true}
											value={bastiName}
											type="text"
											className="border-none"
											name="bastiId"
											placeholder={t(tKey('placeholders.bastiName'))}
										/>
									</div> */}
                  <div>
                    <Input labelText={t(tKey("labels.orderDate"))} disabled={true} register={register} error={errors} type="date" value={orderDate} className="border-none" name="orderDate" placeholder={t(tKey("placeholders.orderDate"))} />
                  </div>
                  {/* <div>
										<Input
											labelText={t(tKey('labels.paymentTrackingId'))}
											register={register}
											error={errors}
											type="text"
											className="border-none"
											name="paymentTrackingId"
											placeholder={t(tKey('placeholders.paymentTrackingId'))}
										/>
									</div> */}
                  <div>{/* <Input labelText={t(tKey("labels.orderNo"))} register={register} value={orderNumber} disabled={true} type="text" className="border-none" name="orderNo" placeholder={t(tKey("placeholders.orderNo"))} /> */}</div>

                  <div>
                    <div className="relative overflow-x-hidden shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left text-gray-400 dark:text-gray-300 table-auto max-w-[405px]">
                        <thead className="text-xs text-gray-400 uppercase  dark:text-gray-300">
                          <tr>
                            <th scope="col" className="px-1 py-3">
                              Product
                            </th>
                            <th scope="col" className="px-1 py-3">
                              Qty
                            </th>
                            <th scope="col" className="px-1 py-3">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredProducts &&
                            filteredProducts.map((item: any, index: number) => (
                              <tr className="bg-white border-b dark:border-gray-500 ">
                                <td className="px-1 py-4 font-bold text-gray-700 text-black-600">
                                    <img style={{height:"100px"}} height="100px" src={item.attachments?.[0].url ?? ""} />
                                 <h3>{getLocalizedName(item)}</h3> 
                 
                    
                                  <h3>PKR {item?.tradePrice}</h3>
                                </td>
                               
                                <td className="px-1 py-4">
                                  <div className="flex items-center space-x-3">
                                    <button 
                                      onClick={(e) => {
                                        handleDecrement(e, index);
                                      }}
                                      className="inline-flex items-center justify-center p-1 text-lg font-bold h-5 w-5 text-white bg-blue-500 border border-blue-500 rounded-full focus:outline-none hover:bg-blue-600 focus:ring-4 focus:ring-blue-200"
                                      type="button"
                                    >
                                      <span className="sr-only">Decrement button</span>
                                      <span>-</span>
                                    </button>
                                    <div className="relative">
                                      <div className="absolute inset-0 bg-white rounded-full"></div>
                                      <input
                                        type="number"
                                        id={`quantity_` + index}
                                        disabled={true}
                                        defaultValue={0}
                                        onChange={(e) => {
                                          setAmount(e.target.value);
                                        }}
                                        className="relative z-10 bg-white w-10 border border-blue-400 text-blue-400 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block px-2 py-1"
                                        placeholder="0"
                                        required
                                      />
                                    </div>
                                    <button
                                      onClick={(e) => {
                                        handleIncrement(e, index);
                                      }}
                                      className="inline-flex items-center justify-center h-5 w-5 p-1 text-lg font-bold text-white bg-blue-500 border border-blue-500 rounded-full focus:outline-none hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 "
                                      type="button"
                                    >
                                      <span className="sr-only">Increment button</span>
                                      <span>+</span>
                                    </button>
                                  </div>
                                </td>

                                <td className="px-1 py-4 font-semibold text-gray-900 text-gray-700">
                                  <div id={"totalAmount_" + index}></div>
                                  {/* {productsDataFinal.find((item: any) => item.productId === item?.id)?.totalAmount} */}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
          
          {/* {isDirty && ( */}
         {!successModal && <div  style={{
						position: "fixed",
						bottom: 0,
						width: width > 600 ? "511px" : "100%",

            zIndex:"90000"
					  }}  className="absolute w-full mx-auto flex flex-row items-center justify-center border border-gray-200 bg-white bottom-4 left-0 p-3 right-0">
          

          <button type="submit"className="px-8 py-6 flex flex-row justify-between items-center w-full bg-blue-800 text-white text-lg rounded-lg font-semibold">
							<p>{t('viewOrderSummary.buttons.viewCart')}</p>
            <p>
								{calculateTotals(productsDataFinal)?.totalPrice.toFixed(2)} <span>{t('products.label.rupees')}</span>
							</p>
          </button>
          </div>}
          {/* )} */}
        </form>
        {successModal && (
          <Modal
            onClose={() => {
              setSuccessModal(false);
            }}
            showCrossIcon={true}
            className="space-y-6"
          >
            {/* Display summary for productsDataFinal */}
            <div>
              <h3 className="text-xl font-semibold">{t("viewOrderSummary.summary.productSummary")}</h3>
              {/* Add your logic to display product summary based on productsDataFinal */}
              {/* Example: */}
              <div>
                {/* <p>{product.name}</p> */}
                {/* <p>
									Total Quantity Of Products: {calculateTotals(productsDataFinal)?.totalQuantity}
								</p> */}
                <p>
                  {t("products.label.totalPrice")}: {calculateTotals(productsDataFinal)?.totalPrice}
                </p>
                {/* Add more details as needed */}
              </div>
              <div  className="flex-col border border-gsray-200 divide-y divide-gray-200 bg-white rounded-md w-full px-2 py-3s space-y-1 mt-2">
                <h1 className=" text-md font-semibold">{t("products.label.summary-ordered-products")}</h1>
                <div style={{maxHeight:"460px",overflow:"scroll"}}>
                <Table headers={[t("viewOrderSummary.tableHeaders.id"), t("viewOrderSummary.tableHeaders.name"), t("viewOrderSummary.tableHeaders.quantity"), t("viewOrderSummary.tableHeaders.amount")]} renderComponent={renderTBody} isReport={true} />

                </div>
              </div>

              <div style={{ height: "30px" }}></div>
            </div>

            {/* Confirmation message */}

            {/* Confirm and Cancel buttons */}
            <div className="flex justify-center items-center mt-4">
              <button onClick={handleSubmitConfirm} type="button" className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-[#00cfae]  font-bold rounded-3xl text-md text-center mr-2 mb-2">
                Confirm
              </button>
              <button onClick={() => setSuccessModal(false)} type="button" className="w-48 px-2 py-3 inline-flex items-center justify-center text-white bg-blue-600  font-bold rounded-3xl text-md text-center mb-2">
                No
              </button>
            </div>
          </Modal>
        )}
      </div>
    </AppLayout>
  );
};
