import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import clsx from 'utils/clsx'
import { getDirection } from 'utils/string'

interface Props {
	title?: string
	onClick?: () => void
}

export const BackButton = ({ title, onClick }: Props) => {
	const dir = getDirection()

	return (
		<div dir={dir} className={clsx('flex items-center justify-start space-x-2')}>
			<ArrowLeftIcon
				className={clsx('h-6 w-6 cursor-pointer text-black', {
					'mr-2': dir === 'ltr',
					'ml-2 rotate-180': dir === 'rtl'
				})}
				onClick={onClick}
			/>
		</div>
	)
}
