import { InputFieldError } from 'components/error/field-error'
import { InputHTMLAttributes } from 'react'
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import clsx from 'utils/clsx'

import { t } from 'utils/language'
import { containsOnlyDigits, getDirection } from 'utils/string'
import { isValidDecimal } from 'utils/validate'

// Can only be used with Controller if using for Hook Form
type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	onNumberChange: (num: number) => void
	value?: number
	step?: number
	minimumValue?: number
	maxLength?: number
	name?: string
	allowDecimal?: boolean
	error?: FieldErrors<FieldValues>
	controllerError?: FieldError | undefined
	maxValue?: number
	isMuacAge?: boolean
}

export const CounterInput = ({
	onNumberChange,
	onChange,
	step = 1,
	minimumValue = 0,
	maxLength,
	maxValue,
	name,
	controllerError,
	value,
	isMuacAge,
	...props
}: InputProps) => {
	console.log("value",(value))
	console.log("value maxValue",typeof(value))
	console.log("value maxValue ss",(maxValue))

	const dir = getDirection()
	const errorText = controllerError?.message as string

	const input = value?.toString() ?? ''

	
	if(isMuacAge && value!== undefined){
		
		onNumberChange(value);
		
	}
	// const [input, setInput] = useState<string>(value?.toString() ?? '')

	// const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { value } = event.target

	// 	// Check if input value contains only digits
	// 	if (
	// 		value === '' ||
	// 		(containsOnlyDigits(value) &&
	// 			(maxLength ? value.length <= maxLength && Number(value) >= minimumValue : true))
	// 	) {
	// 		onChange?.(event)
	// 	}

	// 	if ((maxValue || maxValue == 0) && maxValue < parseInt(value)) {
	// 		onNumberChange(maxValue)
	// 	}
	// }
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
	
		// Check if input value contains only digits
		if (
			value === '' ||
			(containsOnlyDigits(value) &&
				(maxLength ? value.length <= maxLength && Number(value) >= minimumValue : true))
		) {
			onChange?.(event);
		} else {
			throw new Error('Input value is not valid.');
		}
	
		// Check if entered value exceeds the maximum allowed value
		if ((maxValue || maxValue === 0) && parseInt(value) > maxValue) {
			onNumberChange(maxValue);
			return toast.error(t('errors.maxThan', { value: maxValue }))
		}
	
		if ((maxValue || maxValue === 0) && maxValue < parseInt(value)) {
			onNumberChange(maxValue);
		}
	};
	
	return (
		<>
			<div className="flex justify-center w-full">
				<div
					dir={'ltr'}
					className={clsx(
						'relative py-2 px-4 shadow-md flex justify-center border border-gray-200 flex-row rounded-lg bg-white space-x-2',
						{
							'font-urdu': dir === 'rtl'
						}
					)}>
					<div className="inline-flex items-center justify-center h-full rounded-l px-2">
						<button
							type="button"
							onClick={event => {
								event.preventDefault()
								const stepValue = Number(((value ?? 0) - step).toFixed(1))
								const minVal = Number(minimumValue)
								const maxVal = Number(maxValue)

								if (stepValue < minVal) {
									return toast.error(t('errors.lessThan', { value: minVal }))
								}
							
								onNumberChange(stepValue)
							}}
							className="inline-flex justify-center items-center cursor-pointer h-8 w-8 rounded-full text-white bg-blue-600 outline-none hover:text-gray-700">
							<span className="inline-block text-2xl font-serif font-medium">−</span>
						</button>
					</div>
					<input
						type="number"
						value={input}
						inputMode="numeric"
						name={name}
						onChange={handleInputChange}
						className="text-md md:text-base cursor-default text-black flex w-20 items-center rounded-lg text-center font-semibold outline-none bg-white hover:text-black focus:text-black focus:outline-none appearance-none"
						{...props}
					/>
					<div className="inline-flex items-center justify-center h-full rounded-r px-2">
						<button
							type="button"
							onClick={event => {
								event.preventDefault()
								if (maxValue || maxValue == 0) {
						

									if ((value ?? 0) < maxValue) {
										onNumberChange(Number(((Number(value) ?? 0) + step).toFixed(1)));
									}else{
										return toast.error(t('errors.maxThan', { value: maxValue }))

									}
								} else {
									onNumberChange(Number(((Number(value) || 0) + step).toFixed(1)))
								}
							}}
							className="flex justify-center items-center cursor-pointer h-8 w-8 rounded-full text-white bg-blue-600 outline-none hover:text-gray-700">
							<span className="inline-block text-2xl font-serif font-medium">+</span>
						</button>
					</div>
				</div>
			</div>
			<InputFieldError message={errorText} className="my-2" />
		</>
	)
}

export const CounterDecimalInput = ({
	onNumberChange,
	onChange,
	maxValue = 0,
	step = 0.1,
	minimumValue = 0,
	maxLength,
	controllerError,
	value,
	...props
}: InputProps) => {
	const dir = getDirection()
	const errorText = controllerError?.message as string

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target

		let decimalValue = value.split('.')[0]

		if (value === '' || (maxLength ? decimalValue.length <= maxLength : true)) {
			const muacDecimal = isValidDecimal(value)
			onNumberChange(Number(muacDecimal.toFixed(1)))
		}
		if (maxValue) {
			if (maxValue < parseFloat(value)) {
				onNumberChange(maxValue)
			}
		}
	}

	return (
		<>
			<div className="flex justify-center w-full">
				<div
					dir={'ltr'}
					className={clsx(
						'relative py-2 px-4 shadow-md flex justify-center border border-gray-200 flex-row rounded-lg bg-white space-x-2',
						{
							'font-urdu': dir === 'rtl'
						}
					)}>
					<div className="inline-flex items-center justify-center h-full rounded-l px-2">
						<button
							type="button"
							onClick={event => {
								event.preventDefault()
								if (value === minimumValue || !value) {
									return toast.error(t('errors.lessThan', { value: minimumValue }))
								} else {
									onNumberChange(Number(((value ?? 0) - step).toFixed(1)))
								}
							}}
							className="inline-flex justify-center items-center cursor-pointer h-8 w-8 rounded-full text-white bg-blue-600 outline-none hover:text-gray-700">
							<span className="inline-block text-2xl font-serif font-medium">−</span>
						</button>
					</div>
					<input
						type="number"
						value={value}
						inputMode="numeric"
						onChange={handleInputChange}
						className="text-md md:text-base cursor-default text-black flex w-20 items-center rounded-lg text-center font-semibold outline-none bg-white hover:text-black focus:text-black focus:outline-none appearance-none"
						{...props}
						step="any"
					/>
					<div className="inline-flex items-center justify-center h-full rounded-r px-2">
						<button
							type="button"
							onClick={event => {
								event.preventDefault()
								if (maxValue) {
									if ((value || 0) < maxValue) {
										onNumberChange(Number(((value || 0) + step).toFixed(1)))
									}
								} else {
									onNumberChange(Number(((value || 0) + step).toFixed(1)))
								}
							}}
							className="flex justify-center items-center cursor-pointer h-8 w-8 rounded-full text-white bg-blue-600 outline-none hover:text-gray-700">
							<span className="inline-block text-2xl font-serif font-medium">+</span>
						</button>
					</div>
				</div>
			</div>
			<InputFieldError message={errorText} className="my-2" />
		</>
	)
}
