import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import { AppLayout } from 'components/app/layout'
import { Checkbox } from 'components/inputs/checkbox'
import { Input, InputNumber } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { conditions, contraceptives, incomeRange } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import registrationService from 'services/registration-service'
import { saveMwras } from 'slices/db'
import { getLocalizedName, getTKey, t } from 'utils/language'
import { getDirection, getRegistrationNumber } from 'utils/string'

const tKey = getTKey('plw')

export const EditMwra = () => {
	const dispatch = useAppDispatch()
	const { id } = useParams()
	const { profile: crp } = useAppSelector(state => state.auth)
	const { connected: isConnectedToInternet } = useAppSelector(state => state.db)

	const mwras = useAppSelector(state => state.db.mwras)
	const mwra = id ? mwras[parseInt(id)] : undefined

	const schema = yup.object({
		name: yup
			.string()
			.required(t('errors.required', { field: t(tKey('labels.name')) }))
			.matches(/^[^\d\p{P}\p{S}]*$/u, t('errors.invalid', { field: t(tKey('labels.name')) })),
		age: yup
			.number()
			.required(t('errors.required', { field: t(tKey('labels.age')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.age')) }))
			.min(13, t('errors.ageLessThan', { age: '13' }))
			.max(49, t('errors.ageMoreThan', { age: '49' })),
		condition: yup
			.string()
			.required(t('errors.required', { field: t(tKey('labels.condition')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.condition')) })),
		husbandName: yup
			.string()
			.required(t('errors.required', { field: t(tKey('labels.husband')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.husband')) })),
		phone: yup
			.string()
			.required(t('errors.required', { field: t(tKey('labels.contactNumber')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.contactNumber')) }))
			.length(11, t('errors.phoneLength', { value: '11' })),
		bastiId: yup
			.string()
			.required(t('errors.required', { field: t(tKey('labels.tehsilId')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.tehsilId')) })),
		totalFamilyMembers: yup
			.number()
			.required(t('errors.required', { field: t(tKey('labels.familyMembers')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.familyMembers')) }))
			.min(2, t('errors.leastFamilyLength', { value: '2' }))
			.max(10, t('errors.familyMoreThan', { value: '10' })),
		familyIncome: yup
			.string()
			.required(t('errors.required', { field: t(tKey('labels.familyIncome')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.familyIncome')) })),
		isBispMember: yup
			.boolean()
			.required(t('errors.required', { field: t(tKey('labels.BISP')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.BISP')) })),
		isBispNashonuma: yup.boolean().when('isBispMember', {
			is: true,
			then: schema =>
				schema.required(t('errors.required', { field: t(tKey('labels.BISPNashonuma')) }))
		}),

		expectedDeliveryDate: yup.string().when('condition', {
			is: (val: any) => val === 'pregnant',
			then: schema => schema.required('Expected delivery date is required.'),
			otherwise: schema => schema.notRequired()
		}),

		muac: yup
			.number()
			.required(t('errors.required', { field: 'MUAC' }))
			.typeError(t('errors.invalid', { field: 'Value' }))
			.min(15, t('errors.lessThan', { value: '15' }))
			.max(50, t('errors.moreThan', { value: '50' })),
		totalChildren: yup
			.number()
			.required(t('errors.required', { field: t(tKey('labels.children')) }))
			.typeError(t('errors.invalid', { field: t(tKey('labels.children')) }))
			.max(6, t('errors.totalChildren', { value: '6' })),
		malesUnderTwo: yup.number().when(['totalChildren'], ([totalChildren], schema) => {
			return schema
				.required(t('errors.required', { field: t(tKey('labels.maleChildrenUnderTwo')) }))
				.typeError(t('errors.invalid', { field: t(tKey('labels.maleChildrenUnderTwo')) }))
				.max(totalChildren, t('errors.exceedsSum'))
		}),
		femalesUnderTwo: yup
			.number()
			.when(['totalChildren', 'malesUnderTwo'], ([totalChildren, malesUnderTwo], schema) => {
				return schema
					.required(t('errors.required', { field: t(tKey('labels.femaleChildrenUnderTwo')) }))
					.typeError(t('errors.invalid', { field: t(tKey('labels.femaleChildrenUnderTwo')) }))
					.max(totalChildren - (malesUnderTwo ?? 0), t('errors.exceedsSum'))
			}),

		isFamilyPlanning: yup
			.boolean()
			.required(t('errors.required', { field: t(tKey('labels.familyPlanning')) })),

		contraceptiveMethods: yup.array().when('isFamilyPlanning', {
			is: true,
			then: schema =>
				schema
					.required(t('errors.required', { field: t(tKey('labels.familyPlanningMethods')) }))
					.min(1, t('errors.lessThan', { field: '1' }))
		})
	})

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		getValues,
		control
	} = useForm<MWRARegistrationForm>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			...mwra,
			malesUnderTwo: mwra?.childrenInfo?.malesUnderTwo,
			femalesUnderTwo: mwra?.childrenInfo?.femalesUnderTwo,
			muacChildren: mwra?.childrenInfo?.muacChildren,
			breastfeedingChildAge: mwra?.childrenInfo?.breastfeedingChildAge,
			totalChildren: mwra?.childrenInfo?.totalChildren
		},
		mode: 'all'
	})

	const onSubmit = handleSubmit(data => {
		const mwraForm: MWRARegistrationForm = {
			...data,
			childrenInfo: {
				totalChildren: data.totalChildren,
				malesUnderTwo: data.malesUnderTwo,
				muacChildren: data.muacChildren,
				breastfeedingChildAge: data.breastfeedingChildAge,
				femalesUnderTwo: data.femalesUnderTwo
			}
		}

		if (isConnectedToInternet) {
			return registrationService
				.updateMWRAById(Number(id), mwraForm)
				.then(response => {
					if (response.status === 'ok') {
						registrationService.getMWRAsByCRP(crp.id).then(response => {
							dispatch(saveMwras(response.data))
							toast.success(t('edit.notifications.successfulUpdate'))
							setTimeout(() => {
									/* eslint-disable no-restricted-globals */
								history.back()
									/* eslint-disable no-restricted-globals */
							}, 1000)
						})
					}
				})
				.catch(response => toast.error(t('edit.notifications.errors.failedToUpdate')))
		}

		toast.error(t('edit.notifications.errors.noInternet'))
	})
	const dir = getDirection()

	return (
		<AppLayout showHeader title={t('edit.labels.mwraDetails')}>
			<div dir={dir} className="w-full">
				<form
					onSubmit={event => {
						onSubmit(event)
					}}
					className="mx-auto w-full pb-4 rounded-2xl space-y-4">
					<Disclosure defaultOpen={true}>
						{({ open }) => (
							<div className="bg-white shadow p-4">
								<Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left  font-medium text-slate-900  focus:outline-none ">
									<span>{t('edit.labels.personalDetails')}</span>
									<ChevronUpIcon
										className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-slate-500`}
									/>
								</Disclosure.Button>
								<Disclosure.Panel className="px-4 divide-y py-4 text-gray-500">
									<div>
										<Input
											labelText={t(tKey('labels.name'))}
											register={register}
											error={errors}
											type="text"
											className="border-none"
											name="name"
											placeholder={t(tKey('placeholders.editName'))}
										/>
									</div>
									<div>
										<Input
											labelText={t(tKey('labels.registrationNumber'))}
											disabled
											readOnly
											name="registrationNumbner"
											value={getRegistrationNumber(crp.cluster.code, getValues().localAutoId)}
											type="text"
											className="border-none"
										/>
									</div>
									<div>
										<Controller
											name={'age'}
											control={control as any}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<InputNumber
													labelText={t(tKey('labels.age'))}
													name={'age'}
													maxLength={2}
													value={value}
													controllerError={error}
													type="text"
													onChange={onChange}
													className="border-none"
												/>
											)}
										/>
									</div>
									<div>
										<Controller
											name={'phone'}
											control={control as any}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<InputNumber
													labelText={t(tKey('labels.contactNumber'))}
													name={'phone'}
													maxLength={11}
													value={value}
													controllerError={error}
													type="text"
													onChange={onChange}
													className="border-none"
												/>
											)}
										/>
									</div>
									<div>
										<Select
											name={'bastiId'}
											label={t(tKey('labels.basti'))}
											error={errors}
											className="rounded-md border-none text-black w-full"
											register={register}>
											<option value={''}>Choose Basti</option>
											{(crp.cluster.bastis ?? []).map(basti => {
												return (
													<option key={basti.id} value={basti.id}>
														{getLocalizedName(basti)}
													</option>
												)
											})}
										</Select>
									</div>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>

					<Disclosure>
						{({ open }) => (
							<div className="bg-white shadow p-4">
								<Disclosure.Button className="flex w-full justify-between px-2 py-2 text-left  font-medium text-slate-900  focus:outline-none ">
									<span>{t('edit.labels.womanStatus')}</span>
									<ChevronUpIcon
										className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-slate-500`}
									/>
								</Disclosure.Button>

								<Disclosure.Panel className="px-4 divide-y  text-gray-500">
									<div>
										<Select
											name={'condition'}
											label={t(tKey('labels.condition'))}
											error={errors}
											className="py-2 mb-2 w-full bg-white text-slate-900 border-none rounded-lg focus:outline-none"
											register={register}>
											<option value={''}> {t(tKey('labels.womanCondition'))}</option>
											{conditions.map(condition => {
												return (
													<option key={condition} value={condition}>
														{t(tKey(`options.${condition}`))}
													</option>
												)
											})}
										</Select>
									</div>

									<div>
										<Input
											labelText={t(tKey('labels.expectedMonth'))}
											disabled
											name="id"
											value={getValues().expectedDeliveryDate ?? ''}
											type="text"
											className="border-none"
										/>
									</div>

									<div>
										<Input
											labelText={t(tKey('labels.ANC'))}
											disabled
											name="id"
											value={getValues().isMeetingWithAnc ? t('buttons.yes') : t('buttons.no')}
											type="text"
											className="border-none"
										/>
									</div>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>

					<Disclosure>
						{({ open }) => (
							<div className="bg-white shadow p-4">
								<Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left  font-medium text-slate-900  focus:outline-none ">
									<span>{t('edit.labels.familyDetails')}</span>
									<ChevronUpIcon
										className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-slate-500`}
									/>
								</Disclosure.Button>

								<Disclosure.Panel className="px-4 divide-y text-gray-500">
									<div>
										<Input
											labelText={t(tKey('labels.husband'))}
											register={register}
											error={errors}
											type="text"
											className="border-none"
											name="husbandName"
										/>
									</div>

									<div>
										<Controller
											name={'totalFamilyMembers'}
											control={control as any}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<InputNumber
													labelText={t(tKey('labels.familyMembers'))}
													name={'totalFamilyMembers'}
													maxLength={2}
													value={value}
													controllerError={error}
													type="text"
													onChange={onChange}
													className="border-none"
												/>
											)}
										/>
									</div>
									<div>
										<Select
											name={'familyIncome'}
											label={t(tKey('labels.familyIncome'))}
											error={errors}
											className="py-2 mb-2 w-full bg-white text-slate-900 rounded-lg border-none focus:outline-none"
											register={register}>
											{incomeRange.map(({ title: range }) => {
												return (
													<option key={range} value={range}>
														{t(tKey(`options.${range}`))}
													</option>
												)
											})}
										</Select>
									</div>

									<div>
										<Input
											labelText={t(tKey('labels.BISPNashonuma'))}
											disabled
											type="text"
											className="border-none"
											name="isBispNashonuma"
											value={getValues().isBispNashonuma ? t('buttons.yes') : t('buttons.no')}
										/>
									</div>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>

					<Disclosure>
						{({ open }) => (
							<div className="bg-white shadow p-4">
								<Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left  font-medium text-slate-900  focus:outline-none ">
									<span>{t('edit.labels.childrenDetails')}</span>
									<ChevronUpIcon
										className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-slate-500`}
									/>
								</Disclosure.Button>
								<Disclosure.Panel className="px-4 divide-y  text-gray-500">
									<div>
										<Controller
											name={'totalChildren'}
											control={control as any}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<InputNumber
													name={'totalChildren'}
													labelText={t(tKey('labels.children'))}
													maxLength={1}
													value={value}
													controllerError={error}
													type="text"
													onChange={onChange}
													className="border-none"
												/>
											)}
										/>
									</div>
									<div>
										<Controller
											name="malesUnderTwo"
											control={control}
											rules={{
												required:
													getValues('totalChildren') > 0
														? t('errors.required', { field: 'Males under two' })
														: false
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<InputNumber
													labelText={t(tKey('labels.maleChildrenUnderTwo'))}
													name="malesUnderTwo"
													maxLength={1}
													value={value}
													type="text"
													onChange={onChange}
													controllerError={error}
													className="border-none"
												/>
											)}
										/>
									</div>

									<div>
										<Controller
											name="femalesUnderTwo"
											control={control}
											rules={{
												required:
													getValues('totalChildren') > 0
														? t('errors.required', { field: 'Females under two' })
														: false
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<InputNumber
													labelText={t(tKey('labels.femaleChildrenUnderTwo'))}
													name="femalesUnderTwo"
													maxLength={1}
													value={value}
													type="text"
													onChange={onChange}
													controllerError={error}
													className="border-none"
												/>
											)}
										/>
									</div>

									<div>
										<h1 className="mt-4 text-slate-900">{t(tKey('labels.lowMUACChildren'))}</h1>
										<div className="space-y-2 text-slate-600">
											{getValues().muacChildren?.map(child => {
												return <p key={child.name}>{`${child.name} : ${child.muac}`}</p>
											})}
										</div>
									</div>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>

					<Disclosure>
						{({ open }) => (
							<div className="bg-white shadow p-4">
								<Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left  font-medium text-slate-900  focus:outline-none ">
									<span>{t('edit.labels.familyPlanningDetails')}</span>
									<ChevronUpIcon
										className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-slate-500`}
									/>
								</Disclosure.Button>
								<Disclosure.Panel className="px-4 divide-y text-gray-500">
									<div>
										<Input
											labelText={t(tKey('labels.familyPlanning'))}
											disabled
											type="text"
											className="border-none"
											name="isFamilyPlanning"
											value={getValues().isFamilyPlanning ? t('buttons.yes') : t('buttons.no')}
										/>
									</div>
									<h1 className="py-4 text-slate-900">
										{' '}
										{t(tKey('labels.familyPlanningMethods'))}
									</h1>
									<div>
										<Controller
											control={control}
											name={'contraceptiveMethods'}
											render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
												<div className="space-y-2">
													{contraceptives.map(({ title: contraceptiveMethod, image }) => {
														return (
															<label key={contraceptiveMethod}>
																<div
																	className={clsx(
																		'flex w-full flex-row items-center space-x-2 my-2 rounded-xl border border-gray-400 px-2 py-3',
																		{
																			'bg-[#E9ECFF] border-[#E9ECFF] ':
																				value?.includes(contraceptiveMethod)
																		}
																	)}>
																	<Checkbox
																		value={contraceptiveMethod}
																		error={errors}
																		name={'contraceptiveMethods'}
																		type="checkbox"
																		onBlur={onBlur} // notify when input is touched
																		onChange={event => {
																			if (value?.includes(event.target.value)) {
																				const newValue = value.filter(
																					contraceptive => contraceptive !== contraceptiveMethod
																				)
																				return onChange(newValue)
																			}
																			onChange([...(value ?? []), contraceptiveMethod])
																		}} // send value to hook form
																		checked={value?.includes(contraceptiveMethod)}
																	/>
																	<p>{t(tKey(`options.${contraceptiveMethod}`))}</p>
																</div>
															</label>
														)
													})}
												</div>
											)}
										/>
									</div>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>

					{/* {isDirty && (
						<button
							type="submit"
							className="text-md  w-1/2 block mx-auto text-center rounded-md bg-green-500 font-bold px-8 py-2 text-white">
							{t('buttons.submit')}
						</button>
					)} */}
				</form>
			</div>
		</AppLayout>
	)
}
