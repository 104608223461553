
let fallbackHost: string | undefined = 'api-test.micare.pk/services'
let host = window.apiUrl ?? fallbackHost
//https://access.rspn.org.pk:8075/micare/
let schemeForHttp = 'https://'
//http://access.rspn.org.pk:8075/micare/
//https://api-test.micare.pk/
//https://api-test.micare.pk/
//https://api-test.micare.pk/services
if (host === 'api-test.micare.pk/services') {
	schemeForHttp = 'https://'
}

export const apiHost = schemeForHttp + host
