import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks'
import { saveAssignedCRPs, saveFollowups, saveGrns, saveMwras, saveProducts, saveRequisitions, saveRequisitionsForSeniorCrp, saveSales, saveStockStatus, saveStockTransfers, saveTargets, updateLastSyncOn } from 'slices/db'

import followupService from 'services/followup-service'
import productService from 'services/product-service'
import registrationService from 'services/registration-service'
import saleService from 'services/sales-service'
import userService from 'services/user-service'
import requisitionService from 'services/save-requisition'

export const useLoadResources = () => {
	const dispatch = useAppDispatch()
	const auth = useAppSelector(state => state.auth)

	const connected = useAppSelector(state => state.db.connected)
	const lastSyncedOn = useAppSelector(state => state.db.lastSyncedOn)
	const isSyncedOn = useAppSelector(state => state.db.isSyncedOn)
	
	useEffect(() => {
		if (!!auth.jwt && connected && isSyncedOn) {
			dispatch(updateLastSyncOn({ time: new Date().getTime() }))
			const prefetchAttachmentsAndStoreProducts = (data: Product[]) => {
		
				// TODO: For now make a fetch request to cache the product images
				// need to get rid of this logic at some point
				for (const p of data) {
					// we only enable uploading one attachment
					// so index 0 will work fine
					const attachment = p?.attachments[0]
					
					fetch(attachment?.url, { method: 'GET', mode: 'no-cors' }).then(response => {
						// console.log('caching ' + attachment.url)
					})
				}
				dispatch(saveProducts(data))
			}
			const prefetchTargets = (data: Target[]) => {
		
				dispatch(saveTargets(data))
			}
			setTimeout(() => {
				// TODO: We need to figure out a fast way
				// to find out crp district
				// Cluster is made up of district prefix and
				// cluster id (custom) e.g. JCD-001
				const clusterCode = auth.profile.cluster.code.split('-')

				if (clusterCode.length == 2) {
					productService.getProductByDistrictPrefix(clusterCode[0]).then(response => {
						prefetchAttachmentsAndStoreProducts(response.data)
					})
					productService.getTargetsByDistricts(clusterCode[0]).then(response => {
						prefetchTargets(response.data)
					})
				} else {
					productService.getProducts().then((response:any) => {
						prefetchAttachmentsAndStoreProducts(response.data)
					})
				}
			}, 500)
		}
	}, [connected, auth, isSyncedOn])

	useEffect(() => {
		const loadResources = () => {
			const { profile: user } = auth
			requisitionService.getRequisition().then(response => dispatch(saveRequisitions(response.data?.orderDetail)))
			requisitionService.getRequisitionForSeniorCrp().then(response => dispatch(saveRequisitionsForSeniorCrp(response.data?.orderDetail)))
		
			requisitionService.getGrn().then(response => dispatch(saveGrns(response.data?.orderDetail)))
			requisitionService.getStockTransfers().then(response => dispatch(saveStockTransfers(response.data?.orderDetail)));
			// productService.getStockStatus().then(response => dispatch(saveStockStatus(response)));
			productService.getStockStatus().then(response => {
				dispatch(saveStockStatus(response));
			  });
			  



			registrationService
				.getMWRAsByCRP(user.id)
				.then(response => dispatch(saveMwras(response.data)))

				// requisitionService
				// .getRequisition()
				// .then(response => dispatch(saveRequisitions(response.data)))

			saleService.getSalesByCRP(user.id).then(response => dispatch(saveSales(response)))

			// saleService.getSalesByCRP(user.id)
			// .then(response => {
			//   console.log("Response from saleService:", response);
			//   dispatch(saveSales(response.data));
			// })
			// .catch(error => {
			//   console.error("Error fetching sales:", error);
			//   // Handle the error as needed
			// });
		  
			followupService
				.getFollowupsByCRP(user.id)
				.then(response => dispatch(saveFollowups(response.data)))

			if (user.isSenior) {
			
				userService
					.getCRPsBySeniorCRP(user.id)
					.then(response => dispatch(saveAssignedCRPs(response.data)))
			}
		}

		if (!!auth.jwt && connected && isSyncedOn) {
			setTimeout(() => {
				loadResources()
			}, 500)
		}
	}, [connected, auth, lastSyncedOn,isSyncedOn])
}
