import { t } from 'utils/language'
import { getRegistrationNumber, isMalnourishedMWRA } from 'utils/string'

interface MWRAInfoProps {
	mwra: MWRA
	cluster: Cluster
}

export const MWRAInfoCard = ({ mwra, cluster }: MWRAInfoProps) => {
	const isMalnourished = isMalnourishedMWRA(mwra?.muac)

	return (
		<div className="space-y-px">
			<h1 className="font-semibold text-lg">
				{`${getRegistrationNumber(cluster.code, mwra.localAutoId)}  ${mwra.name}`}
			</h1>
			<div className="flex flex-row">
				<p>{t(`plw.options.${mwra.condition}`)}</p>
				{isMalnourished && <p className="text-red-600"> - {t('followUp.labels.malnourished')}</p>}
			</div>
		</div>
	)
}
