import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { WomenIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { LinkCard } from 'components/cards/link-cards'
import { QrScanner } from 'components/qr-scanner'
import { useAppSelector } from 'hooks'
import useSound from 'use-sound'
import clsx from 'utils/clsx'
import { t } from 'utils/language'

export const FollowUpMenu = () => {
	const [play] = useSound('/audios/sd/address.mp3')
	const { mwras } = useAppSelector(state => state.db)
	const navigate = useNavigate()
	const routes = [
		{
			titleKey: 'followUp.labels.listMWRA',
			icon: WomenIcon,
			to: '/mwras/list',
			slug: 'mwras-list',
			speakerFileName: 'viewListOfRegisteredMWRA.mp3'
		}
	]

	const isBarcodeAvailable = 'BarcodeDetector' in window

	const navigateToMwra = (mwraId: string) => {
		const mwra = mwras[Number(mwraId)]
		if (!mwra) {
			return toast.error(t('followUp.errors.mwraNotFound'))
		}
		navigate(`/mwras/${mwraId}`)
	}

	return (
		<AppLayout showHeader onBackArrowPress={() => navigate('/')}>
			<div>
				{/* {isBarcodeAvailable && <QrScanner onScan={mwraId => navigateToMwra(mwraId)} />} */}

				{routes.map(route => {
					return (
						<LinkCard
							key={route.titleKey}
							imageSrc={route.icon}
							to={route.to}
							title={t(route.titleKey)}
							className={clsx({
								'bg-[#D6DDF6]': route.slug === 'mwras-list'
							})}
							speakerFileName={route.speakerFileName}
						/>
					)
				})}
			</div>
		</AppLayout>
	)
}
