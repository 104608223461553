import clsx from 'utils/clsx'

interface InputFieldErrorProps {
	message: string
	className?: string
}

export const InputFieldError = ({ message, className }: InputFieldErrorProps) => {
	if (!message) {
		return null
	}

	return <p className={clsx('text-xs text-red-600', className)}>{message}</p>
}
