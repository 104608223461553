import { getLocalizedName, getTKey, t } from "utils/language";
import { Disclosure } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { DateTime } from "luxon";
import axios from "services/axios";

import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Modal } from "components/modals/modal";
import { AppLayout } from "components/app/layout";
import { Checkbox } from "components/inputs/checkbox";
import { Input, InputNumber } from "components/inputs/input";
import { Select } from "components/inputs/select";
import { conditions, contraceptives, incomeRange } from "constants/constants";
import { useAppDispatch, useAppSelector } from "hooks";
import registrationService from "services/registration-service";
import { saveMwras } from "slices/db";
import { getDirection, getRegistrationNumber } from "utils/string";
import locationService from "./../../services/location-service";
import { OrderDetail } from "./../../components/cards/order-detail";
import { Table } from "components/app/table";
import { StatsPeriodTypes } from "constants/constants";
import { CustomFilter } from "components/app/filter";
import { ButtonOkay } from "components/buttons/button-ok";
import { RadioInput } from "components/app/radio";

const tKey = getTKey("plw");

interface CRPFormValues {
  clusterCodeId: string;
  crpName: string;
  unionCouncilId: string;
  tehsilId: string;
  districtId: string;
  bastiId: string;
  orderDate: string;
  paymentTrackingId: string;
  orderNo: string;
  products: any;
}
interface ProductData {
  "Product Picture": string;
  productId: string;
  productName: string;
  threshold: number;
  availableQuantity: number;
  stockIn: number;
  stockOut: number;
}

interface Product {
  quantity: number;
  price: number;
  // Add other properties as needed
}
const getDateRange = (view: StatsPeriodTypes) => {
  const currentDate = DateTime.now().toISODate();

  if (view === StatsPeriodTypes.TODAY) {
    return [currentDate, currentDate];
  }

  if (view === StatsPeriodTypes.YESTERDAY) {
    const yesterday = DateTime.now().minus({ days: 1 }).toISODate();
    return [yesterday, yesterday];
  }

  if (view === StatsPeriodTypes.THIS_WEEK) {
    const startOfWeek = DateTime.now().startOf("week").toISODate();
    return [startOfWeek, currentDate];
  }

  if (view === StatsPeriodTypes.LAST_WEEK) {
    const startOfLastWeek = DateTime.now().minus({ weeks: 1 }).startOf("week").toISODate();
    const endOfLastWeek = DateTime.now().minus({ weeks: 1 }).endOf("week").toISODate();
    return [startOfLastWeek, endOfLastWeek];
  }

  if (view === StatsPeriodTypes.THIS_MONTH) {
    const startOfMonth = DateTime.now().startOf("month").toISODate();
    return [startOfMonth, currentDate];
  }

  if (view === StatsPeriodTypes.LAST_MONTH) {
    const startOfLastMonth = DateTime.now().minus({ months: 1 }).startOf("month").toISODate();
    const endOfLastMonth = DateTime.now().minus({ months: 1 }).endOf("month").toISODate();
    return [startOfLastMonth, endOfLastMonth];
  }

  if (view === StatsPeriodTypes.LAST_3_MONTHS) {
    const startOfLast3Months = DateTime.now().minus({ months: 3 }).startOf("month").toISODate();
    return [startOfLast3Months, currentDate];
  }

  if (view === StatsPeriodTypes.THIS_YEAR) {
    const startOfYear = DateTime.now().startOf("year").toISODate();
    return [startOfYear, currentDate];
  }

  if (view === StatsPeriodTypes.LAST_YEAR) {
    const startOfLastYear = DateTime.now().minus({ years: 1 }).startOf("year").toISODate();
    const endOfLastYear = DateTime.now().minus({ years: 1 }).endOf("year").toISODate();
    return [startOfLastYear, endOfLastYear];
  }

  // For CUSTOM_DATE and any other unspecified type, default to the whole year.
  const startOfYear = DateTime.now().startOf("year").toISODate();
  return [startOfYear, currentDate];
};


export const ViewPendingStock = () => {
	const stockStatus = useAppSelector(state => state.db.stockStatus)
	const pendingSales = useAppSelector(state => state.queued.sales)
console.log("pendingSales",pendingSales)
console.log("stockStatus",stockStatus)
  const { profile: user } = useAppSelector((state) => state.auth);
  console.log("user", user);
  const [loader, setLoader] = useState<any>(false);

  const [params, setParam] = useSearchParams();

  const [showModal, setShowModal] = useState(false);

  const [stockData, setStockData] = useState<ProductData[]>([]);
  const dir = getDirection();
  const [statsPeriod, setStatsPeriod] = useState<StatsPeriodTypes>(
    (params.get("stats-period") as StatsPeriodTypes) ?? StatsPeriodTypes.THIS_WEEK
  );
  
  const [customStartDate, setCustomStartDate] = useState(DateTime.now().toISODate());
  const [customEndDate, setCustomEndDate] = useState(DateTime.now().toISODate());
  
  // Initialize startDate and endDate based on the current date
  const [startDate, setStartDate] = useState(() => {
    return statsPeriod === StatsPeriodTypes.CUSTOM_DATE ? customStartDate : getDateRange(statsPeriod)[0];
  });
  
  const [endDate, setEndDate] = useState(() => {
    return statsPeriod === StatsPeriodTypes.CUSTOM_DATE ? customEndDate : getDateRange(statsPeriod)[1];
  });

  const [productsDataFinal, setProductsDataFinal] = useState<any>([]);
  
  useEffect(() => {
    applyDateFilter();
  }, []);
  // const handleStatsPeriodChange = (selectedPeriod: StatsPeriodTypes) => {
  //   setStatsPeriod(selectedPeriod);
  //   const [newStartDate, newEndDate] = selectedPeriod === StatsPeriodTypes.CUSTOM_DATE ? [startDate, endDate] : getDateRange(selectedPeriod);
  //   setStartDate(newStartDate);
  //   setEndDate(newEndDate);
  // };


  const handleStatsPeriodChange = (selectedPeriod: StatsPeriodTypes) => {
    setStatsPeriod(selectedPeriod);
  
    if (selectedPeriod === StatsPeriodTypes.CUSTOM_DATE) {
      // For custom date, set start and end date to current custom values
      setStartDate(customStartDate);
      setEndDate(customEndDate);
    } else {
      // For other periods, get the date range and set start and end date accordingly
      const [newStartDate, newEndDate] = getDateRange(selectedPeriod);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };


  const radioItems= {
    [StatsPeriodTypes.TODAY]: t('viewOrderSummary.modalSummary.radioToday'),
    [StatsPeriodTypes.YESTERDAY]: t('viewOrderSummary.modalSummary.radioYesterday'),
    [StatsPeriodTypes.THIS_WEEK]: t('viewOrderSummary.modalSummary.radioThisWeek'),
    [StatsPeriodTypes.LAST_WEEK]: t('viewOrderSummary.modalSummary.radioLastWeek'),
    [StatsPeriodTypes.THIS_MONTH]: t('viewOrderSummary.modalSummary.radioThisMonth'),
    [StatsPeriodTypes.LAST_MONTH]: t('viewOrderSummary.modalSummary.radioLastMonth'),
    [StatsPeriodTypes.LAST_3_MONTHS]: t('viewOrderSummary.modalSummary.radioLast3Months'),
    [StatsPeriodTypes.THIS_YEAR]: t('viewOrderSummary.modalSummary.radioThisYear'),
    [StatsPeriodTypes.LAST_YEAR]: t('viewOrderSummary.modalSummary.radioLastYear'),
    [StatsPeriodTypes.CUSTOM_DATE]: t('viewOrderSummary.modalSummary.radioSelectDate'),
  };
  
  
  //	headers={['Sr.', 'Product Name', 'Stock In','Stock Out', 'Quantity']}
  const renderTBody = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        {stockData.map((p: any, index: number) => (
          <tr key={index} className="text-left">
		  <td className="text-left">
        
              <span className="text-sm">{p?.orderNo}</span>
            </td>
            <td className="text-left">
              <div style={{display:"flex",flexDirection:"column"}}>
               
                <img
                  className="w-20 h-20 object-contain rounded"
                  src={p["productImage"] ?? ""}
                  alt={p.productName} // Add alt attribute for accessibility
                />
                <span className="text-sm ml-2">{p.productName}</span>
              </div>
            </td>
		  <td className="text-center">
              <span className="text-sm">{p.quantity}</span>
            </td>
		 
            <td className="text-center">
              <span className="text-sm">{p.amount}</span>
            </td>
          
          
          </tr>
        ))}
      </tbody>
    );
  };

  const fetchData = async (startDate: string, endDate: string) => {
	setLoader(true)
	//https://api-test.micare.pk/services/getPendingOrders.php?fromDate=2022-01-01&toDate=2024-10-10
    try {
      const response = await axios.get(`https://api-test.micare.pk/services/getPendingOrders.php`, {
        params: {
          fromDate: startDate,
          toDate: endDate,
        },
      });

      setStockData(response.data.data);

	  setLoader(false)
	  
	  // Assuming the API response structure is similar to your hardcoded data
    } catch (error) {
		setLoader(false)
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const applyDateFilter = () => {
  
    let startDateToUse: string | undefined;
    let endDateToUse: string | undefined;
  
    // Check if the custom date range is selected
    if (statsPeriod === StatsPeriodTypes.CUSTOM_DATE) {
      // Use custom date range states
      startDateToUse = customStartDate;
      endDateToUse = customEndDate;
  
      // Check if the custom date range is valid
      if (startDateToUse > endDateToUse || endDateToUse > DateTime.now().toISODate()) {
        // Handle invalid date range (you can show a message or take appropriate action)
        return;
      }
    } else {
      // Use the previous date range states
      [startDateToUse, endDateToUse] = getDateRange(statsPeriod);
    }
  
    // Filter orderDetail based on the selected date range
    fetchData(startDateToUse, endDateToUse);
    // Update the filtered order details state
  
    // Close the modal
    closeModal();
  };


  // const applyDateFilter = () => {
  //   console.log("customStartDate", customStartDate);
  //   console.log("customEndDate", customEndDate);

  //   let startDateToUse: string | undefined;
  //   let endDateToUse: string | undefined;

  //   // Check if the custom date range is selected
  //   if (statsPeriod === StatsPeriodTypes.CUSTOM_DATE) {
  //     // Use custom date range states
  //     startDateToUse = customStartDate;
  //     endDateToUse = customEndDate;

  //     // Check if the custom date range is valid
  //     if (startDateToUse > endDateToUse || endDateToUse > currentDate) {
  //       // Handle invalid date range (you can show a message or take appropriate action)
  //       return;
  //     }
  //   } else {
  //     // Use the previous date range states
  //     startDateToUse = startDate;
  //     endDateToUse = endDate;
  //   }
  //   console.log("filteredDetails", stockData);
  //   // Filter orderDetail based on the selected date range
  //   fetchData(startDateToUse, endDateToUse);
  //   // Update the filtered order details state
  //   console.log("orderDate >= startDateToUse && orderDate <= endDateToUse orderDate", endDateToUse);
  //   console.log("orderDate >= startDateToUse && orderDate <= endDateToUse orderDate", startDateToUse);

  //   // Close the modal
  //   closeModal();
  // };

  const { profile: crp } = useAppSelector((state) => state.auth);
  const { connected: connected } = useAppSelector((state) => state.db);
  const mwras = useAppSelector((state) => state.db.mwras);

  return (
    <AppLayout showHeader title={t("edit.labels.mwraDetails")}>
      <div className={clsx("flex space-x-1 items-center mb-2")}>
        <CustomFilter title={radioItems[statsPeriod] ?? t("viewOrderSummary.modalSummary.radioWeek")} onClick={() => setShowModal(true)} />
      </div>
	 
    { stockData.length > 0 && (<div dir={dir} className="w-full">
    <h1 className=" text-md font-semibold">{t("viewOrderSummary.orderDetails.stockOrder")}</h1>
        <div className="flex-col border border-gray-200 divide-y divide-gray-200 bg-white rounded-md w-full px-2 py-3s space-y-1 mt-2">
          {!loader ? <Table  headers={[t("viewOrderSummary.orderDetails.requisitionNo"), t("products.label.product"),t("viewOrderSummary.tableHeaders.quantity"),t("viewOrderSummary.orderDetails.amount")]}  renderComponent={renderTBody} isReport={true} /> : (null)
}
        </div>
      </div>)}
      {showModal && (
            <Modal title={t("viewOrderSummary.pickDateRange")} onClose={closeModal}>
            <div dir={dir} className="w-full bg-white p-4">
            <div className="">
              <div className="space-y-2 mt-4">
                {Object.entries(radioItems).map(([id, labelText], index) => (
                  <RadioInput
                    key={index}
                    onChange={() => {
                      setParam({ "stats-period": id }, { replace: true });
                      handleStatsPeriodChange(id as StatsPeriodTypes);
                    }}
                    checked={statsPeriod === id}
                    labelText={labelText}
                  />
                ))}
                <ButtonOkay onClick={applyDateFilter} title={t("buttons.okay")} />
              </div>
              {statsPeriod === StatsPeriodTypes.CUSTOM_DATE && (
                <div dir={dir} className={clsx("flex-col space-y-2 px-2 text-sm mt-4")}>
                  <Input labelText="Start Date" type="date" name="customStartDate" onChange={(event) => setCustomStartDate(event.target.value)} value={customStartDate} />
                  <Input labelText="End Date" type="date" name="customEndDate" onChange={(event) => setCustomEndDate(event.target.value)} value={customEndDate} />
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </AppLayout>
  );
};
