import { InputHTMLAttributes } from 'react'
import clsx from 'utils/clsx'

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText: string
}
export const RadioInput = ({
	value,
	checked,
	name,
	labelText,
	onChange,
	...restProps
}: RadioProps) => {
	const { className } = restProps
	return (
		<div className={clsx('flex w-full flex-row items-center space-x-2')}>
			<input
				className={clsx(className, 'w-4 h-4')}
				type="radio"
				onChange={onChange}
				checked={checked}
				tabIndex={-1}
				value={value}
			/>
			<p>{labelText}</p>
		</div>
	)
}
