import { Link } from 'react-router-dom'

import { Speaker } from 'components/audio-player'
import { WomanConditionTypes } from 'constants/constants'
import clsx from 'utils/clsx'
import { t } from 'utils/language'
import { getDirection } from 'utils/string'

type LinkCardProps = {
	to: string
	imageSrc?: string
	title: string
	strapline?: string
	subtitle?: string
	className?: string
	speakerFileName?: string
	mwraDetails?: any
}

export const LinkCard = ({
	to,
	imageSrc,
	title,
	strapline,
	mwraDetails,
	subtitle,
	className,
	speakerFileName
}: LinkCardProps) => {
	const dir = getDirection()

	const isMalnourished = mwraDetails?.muac && parseInt(mwraDetails.muac, 10) <= 23

	const status = isMalnourished ? t('followUp.labels.malnourished') : undefined

	return (
		<div
			className={clsx(
				`flex flex-row items-center shadow-sm border border-gray-200 rounded-lg p-4 space-x-4`,
				{
					'font-urdu': dir === 'rtl',
					'bg-gray-200': mwraDetails?.condition === WomanConditionTypes.DECEASED
				},
				className
			)}>
			{speakerFileName && dir === 'rtl' && <Speaker filename={speakerFileName} />}
			<Link
				dir={dir}
				className={clsx(`flex w-full h-full flex-row items-center space-x-4`, {
					'font-urdu': dir === 'rtl'
				})}
				to={to}>
				{imageSrc && <img className="h-16 w-16" src={imageSrc} />}
				<div className="space-y-1">
					<div className="flex items-center space-x-2">
						{strapline && <p className="text-sm font-semibold text-gray-500">{strapline}.</p>}
						<p className="text-md font-medium">{title}</p>
					</div>
					{subtitle && <p className="text-sm font-medium text-gray-500 capitalize">{subtitle}</p>}
					{mwraDetails?.husbandName && (
						<p className="text-sm font-medium text-gray-500">{mwraDetails?.husbandName}</p>
					)}
					{status && <p className="text-sm font-semibold text-red-500">{status}</p>}
				</div>
			</Link>
		</div>
	)
}
