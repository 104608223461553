import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'

import clsx from 'utils/clsx'
import { getDirection } from 'utils/string'

interface MultiSelectorProps {
	options: string[]
	defaultOption?: string
	onClick?: (values: string[]) => void
}

export const MultiSelector = ({ options, defaultOption, onClick }: MultiSelectorProps) => {
	const [selected, setSelected] = useState<String[]>([])

	const handleChange = (values: string[]) => {
		setSelected(values)
		onClick?.(values)
	}

	const dir = getDirection()

	return (
		<div className="w-full">
			<Listbox value={selected} onChange={handleChange} multiple>
				<div className="relative mt-1">
					<Listbox.Button
						className={clsx(
							'relative h-10 overflow-hidden capitalize w-full cursor-default bg-white rounded-lg py-1 shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
							dir === 'rtl' ? 'text-right pr-3 text-xs' : 'text-left pl-3'
						)}>
						{selected.length > 0 ? selected.join(', ') : defaultOption}
						<span
							className={clsx(
								'pointer-events-none absolute inset-y-0 flex items-center pr-2',
								dir === 'rtl' ? 'left-0' : 'right-0'
							)}>
							<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
						</span>
					</Listbox.Button>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{Object.values(options).map((option, index) => (
								<Listbox.Option
									key={index}
									dir={dir}
									className={({ active }) =>
										`relative cursor-default select-none py-2 pl-3 pr-4 capitalize ${
											active ? 'bg-[#E9ECFF] text-black' : 'text-gray-900'
										}`
									}
									value={option}>
									{({ selected }) => (
										<>
											<span
												className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
												{option}
											</span>
											{selected ? (
												<span
													className={clsx(
														'absolute inset-y-0 flex items-center text-blue-500',
														dir === 'rtl' ? 'left-0 pl-3' : 'right-0 pr-3'
													)}>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
		</div>
	)
}
