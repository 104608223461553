import axios from 'services/axios'
//getCrpSale.php
const getSalesByCRP = (id: ID) => {
	// debugger;
	return axios.get(`/getCrpSale.php`).then(response => {
		return response.data
	})
}
//https://api-test.micare.pk/services/saveSale.php For Followups

const createSale = (sale: Sale) => {
	return axios.post('/saveSale.php', {
		sale
	})
}

const saleService = {
	createSale,
	getSalesByCRP
}

export default saleService
